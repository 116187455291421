var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.inputs, function (input, index) {
      return _c(
        "div",
        { key: index },
        [
          _vm._t("before"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "input-group mb-1" },
            [
              _c(
                "div",
                { staticClass: "input-group-prepend" },
                [
                  _vm.prepend[0] === true
                    ? _c("span", {
                        staticClass: "input-group-text",
                        domProps: { innerHTML: _vm._s(_vm.prepend[index + 1]) },
                      })
                    : _vm._l(_vm.prepend, function (text, key, index) {
                        return _c("span", {
                          key: index,
                          staticClass: "input-group-text",
                          domProps: { innerHTML: _vm._s(text) },
                        })
                      }),
                ],
                2
              ),
              _vm._v(" "),
              _c("ResizeAuto", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ resize }) {
                        return [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: input.value,
                                expression: "input.value",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              name: input.name,
                              placeholder: _vm.placeholder,
                              rows: "1",
                            },
                            domProps: { value: input.value },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(input, "value", $event.target.value)
                                },
                                resize,
                              ],
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "input-group-append append-input-suffix",
                  class: { "not-last": index < _vm.inputs.length - 1 },
                },
                [
                  _vm.append[0] === true
                    ? _c("span", {
                        staticClass: "input-group-text",
                        domProps: { innerHTML: _vm._s(_vm.append[index + 1]) },
                      })
                    : _vm._l(_vm.append, function (text, key, index) {
                        return _c("span", {
                          key: index,
                          staticClass: "input-group-text",
                          domProps: { innerHTML: _vm._s(text) },
                        })
                      }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "input-group-text pointer",
                      on: {
                        click: function ($event) {
                          return _vm.addRow()
                        },
                      },
                    },
                    [_vm._v("+")]
                  ),
                  _vm._v(" "),
                  index > (_vm.min || 1) - 1
                    ? _c(
                        "span",
                        {
                          staticClass: "input-group-text pointer",
                          on: {
                            click: function ($event) {
                              return _vm.deleteRow(index)
                            },
                          },
                        },
                        [_vm._v("-")]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm._t("after"),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }