<script>
export default {

    name: "PasswordStrengthIndicator",
    props: {
        value: {
            type: String,
            default: '',
        },
        input_name: {
            type: String,
            default: 'password',
        },
        classes: {
            type: String,
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        }
    },
    data: function(){
        return {
            message      : '',
            has_number   : false,
            has_lowercase: false,
            has_uppercase: false,
            has_special  : false,
            show_all_messages : false
        }
    },
    methods: {
        password_check: function () {
            this.has_number    = /\d/.test(this.message);
            this.has_lowercase = /[a-z]/.test(this.message);
            this.has_uppercase = /[A-Z]/.test(this.message);
            this.has_special   = /[!@#\$%\^\&*\)\(+=._-]/.test(this.message);
        },
        show_messages: function() {
            this.show_all_messages = true;
        }
    }

}
</script>

<template>
<div class="frmValidation--wrap">
    <input :name="input_name" class="frmField" :class="classes" type="password" @input="password_check" @focus="show_messages" v-model="message" required="required" />
    <transition name="fade">
    <div class="frmValidation--messages" v-if="show_all_messages">
        <p class="frmValidation--intro">Please ensure your password...</p>
        <p class="frmValidation" :class="{'frmValidation--passed' : message.length > 7}"><i class="frmIcon" :class="message.length > 7 ? 'fas fa-check' : 'fal fa-times'"></i> Is longer than 7 characters</p>
        <p class="frmValidation" :class="{'frmValidation--passed' :has_uppercase }"><i class="frmIcon" :class="has_uppercase ? 'fas fa-check' : 'fal fa-times'"></i> Has a capital letter</p>
        <p class="frmValidation" :class="{'frmValidation--passed' :has_lowercase }"><i class="frmIcon" :class="has_lowercase ? 'fas fa-check' : 'fal fa-times'"></i> Has a lowercase letter</p>
        <p class="frmValidation" :class="{'frmValidation--passed' : has_number }"><i class="frmIcon" :class="has_number ? 'fas fa-check' : 'fal fa-times'"></i> Has a number</p>
        <p class="frmValidation" :class="{'frmValidation--passed' : has_special }"><i class="frmIcon" :class="has_special ? 'fas fa-check' : 'fal fa-times'"></i> Has a special character</p>
    </div>
    </transition>
</div>
</template>

<style scoped lang="scss">
.frmValidation--wrap {
    p {
        margin: 0;
        padding: 0;
        &.frmValidation--intro {
            margin: 8px 0;
            font-size: 13px;
        }
    }
}

.frmValidation{font-size: 13px; }
.frmValidation--passed{color:#717b85;}
.frmIcon{color: #da5b73;}
.frmValidation--passed .frmIcon{color: var(--eqm-green);}
/*.frmValidation--messages {
    height: 0;
    overflow: hidden;
    transition: all 1600ms;
    &.visible {
        height: auto;
    }
}*/
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
