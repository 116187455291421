module.exports = {
    get: function(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },

    getAll: function(withValues = true) {
        var names = [];
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            cb = c.split('=');

            if (withValues === true) {
                names.push({
                    'name': cb[0],
                    'value': cb[1]
                });
            } else {
                if (typeof withValues == 'string' && withValues.toLowerCase() == 'name') {
                    names.push(cb[0]);
                } else if (typeof withValues == 'string' && withValues.toLowerCase() == 'value') {
                    names.push(cb[1]);
                }
            }
        }
        return names;
    },

    set: function(cname, cvalue, exdays) {
        exdays = typeof exdays == 'undefined' ? 10 : exdays;

        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },

    delete: function(cname) {
        setCookie(cname, '', new Date('01/01/1970'));
    },

    deleteAll: function() {
        var ca = getAllCookies('name');
        for (var i = 0; i < ca.length; i++) {
            deleteCookie(ca[i]);
        }
    }
}