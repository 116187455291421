export default {
    attachSubmissionTest: function(vue) {
       let $form = $(vue.$el).find("#questionnaire");

       $form.on('submit', function(e) {

           //e.preventDefault();

           let errorsFound = [], errorSections = [];

          // Check for any meter name errors or type errors. Does not apply to the coal input.
           let meterUsageInputs = $("[name*='[kwh_used]']").not("[name^='coal']");
           meterUsageInputs.each(function(index,input) {
               let $input = $(input),
               val = $input.val(),
               nameInput;

               if(val) {
                   // We have entered a value, so check for a name
                   nameInput = $input.closest(".card-meter").find("[name*='[name]']");
                   if(!nameInput.val()) {
                       errorsFound.push(nameInput);
                       errorSections.push('Energy Audit - meter names');
                   }
               }

           });

           /**
            * Now we need to check for "types" - be it sub types of fuels or vehicles, which is a bit trickier.
            *
            * Energy, water and generation section:
            * fuel_type_id required if kwh_used is not empty
            * In transport:
            * vehicletype_id required if miles not empty
            */
           let vehicleMileageInputs = $("[name*='[miles]']");

           vehicleMileageInputs.each(function(index,input) {
              let $input = $(input),vehicle,vehicle_type_input, input_val = $input.val();

              if(!input_val || input_val < 1) {
                  return true;
              }

              vehicle = $input.closest("[data-transport-unit]");

              // We can't check for a value if we don't have an input
              if(!vehicle.length) {
                  return false;
              }
              vehicle_type_input = vehicle.find("[name*='[vehicletype_id]']");
              if(vehicle_type_input.length && !vehicle_type_input.val()) {
                  errorsFound.push(vehicle_type_input);
                  errorSections.push('Transport Audit - Vehicle types');
              }
           });

           meterUsageInputs.each(function(index,input) {
               let $input = $(input), meter, fuel_type_input, input_val = $input.val();

               if(!input_val || input_val < 1) {
                   return true;
               }

               meter = $(input).closest(".card-meter");

               // We can't check for a value if we don't have an input
               if(!meter.length) {
                   return false;
               }

               fuel_type_input = meter.find("[name*='[fuel_type_id]']");
               if(fuel_type_input.length && !fuel_type_input.val()) {
                   errorsFound.push(fuel_type_input);
                   errorSections.push('Fuel type or sub-types');
               }
           });

           // Something is wrong. Can we highlight the errors?

           let sectionString = '', message;
           $.each(errorSections,function(index,section) {
                   sectionString += `<li>${section}</li>`;
           });

           message = `<p><strong>It looks like you might have missed some details in the following sections:</strong></p>
            <ul>${sectionString}</ul>
            <p>Please take another look at the information you have entered and make sure you have given as much detail as you can.</p><p>&nbsp;</p>`;

           if(errorsFound.length) {
               let $toastValidation = $('#validationToast');
               $toastValidation.remove();
               let theToast = `<div aria-live="polite" aria-atomic="true" class="" style="z-index: 1300; position: fixed; top: 60px; width: 100%;">

    <div class="d-flex justify-content-center align-items-center flex-column"><div class="toast" id="validationToast" role="alert" aria-live="assertive" aria-atomic="true">
                   <div class="toast-header bg-red">

                       <strong class="mr-auto">Hold on a sec...</strong>

                       <button type="button" class="ml-auto mb-1 close hide-toast" data-dismiss="toast"
                               aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                       </button>

                   </div>
                   <div class="toast-body">
                       ${message}
                   </div>
               </div></div></div>`;
               $("body").append(theToast);
               $toastValidation.toast({
                   autohide: false,
               });
               $toastValidation.toast('show');

               return false;
           }
           return true;
       });

    },
}

