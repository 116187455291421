var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "container-fluid py-4", class: _vm.outer_class },
      [
        _vm.isLoading ? _c("loading", { class: "mt-3" }) : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _vm._t("topcontent", function () {
                  return [_c("div", { attrs: { id: "overviewchart" } })]
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mt-4" }, [
            _c(
              "div",
              { staticClass: "col-12 col-lg-10" },
              [
                _vm._t("bottomcontent", function () {
                  return [_c("div", { attrs: { id: "overviewchart" } })]
                }),
              ],
              2
            ),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }