<template>
    <div class="action-plans" :class="{ 'action-plans_hascompleted' :  has_completed }">
        <h2 class="h5" v-if="(title || '').length > 0 && showtoggles">
            {{ title }}

        </h2>

        <span v-show="isAutoSaving" class="autosaving-icon"><i class="fad fa-spinner fa-spin"></i></span>


        <button v-if="(isEmpty(rows) || !has_actions) && !visible" type="button" class="btn btn-success toggle action-plan-toggle" @click="showActionPlan()">
            <i :class="`fas fa-plus`"></i> Add action
        </button>

        <div v-if="visible" class="action-plan-actions">
            <div class="action-plan" v-for="(row, index) in rows" :key="row.id" :class="{ 'correction': row.isCAR, 'hovered': row.hovered || row_hover_states[index].hovered, 'is-loading': isLoading, 'my-3': index > 0, 'mb-3 mt-0': index == 0 }">


                <input type="hidden" v-bind:name="name + '_actions['+(row.existing_id ? row.existing_id : index)+'][existing_id]'" v-model="row.existing_id" v-bind:disabled="!editable" />

                <div class="card action-plan-actions-input-wrap" ref="inputwrapper" :class="{ 'empty-action-row fade collapse' : row.is_empty_action_row, 'show' : !row.hide_empty_by_default }">
                    <div class="row no-gutters">
                        <div class="col-lg-9">
                            <div class="card-body action-plan-body">
                                <div v-bind:class="{'my-3': index > 1, 'mb-3 mt-0': index == 1}" v-if="row.isCAR">
                                    <h3 class="h5">Corrective Action Request</h3>
                                    <h4 class="h6">This action has been assigned to you by an administrator.</h4>
                                </div>

                                <span
                                    class="action-plan-remove-action-icon"
                                    v-bind:class="{
                            disabled: rows.length <= min,
                            pointer: rows.length > min
                        }"
                                    v-bind:disabled="rows.length <= min"
                                    v-if="(index > (min || 1) - 1) && row.status !== 1"
                                    @mouseenter="deleteOnEnter(index)"
                                    @mouseleave="deleteOnLeave(index)"
                                    @click="deleteRow(index)"
                                >
                        <i class="fad fa-times-circle text-danger" title="Remove this action"></i>
                    </span>

                                <p class="m-0 text-large font-weight-bold action-plan-action-title">{{ row.status == 1 ? 'What needed doing' : 'What needs doing:' }}</p>


                                <ResizeAuto>
                                    <template v-slot:default="{resize}">
                                <textarea
                                    :rows="textarearows"
                                    class="form-control action-plan-action-input textarea"
                                    :class="{ disabled: row.status==1 || !editable }"
                                    @input="resize"
                                    v-bind:name="name + '_actions['+(row.existing_id ? row.existing_id : index)+'][action]'"
                                    v-model="row.value"
                                ></textarea>
                                    </template>
                                </ResizeAuto>

                                <p class="m-1 mt-2 small action-add-comments-trigger" v-if="editable || row.showcomments || (!editable && row.comments && row.comments.length)" @click="toggleComments(index)"><i v-show="!row.showcomments" class="fal fa-plus"></i> {{ row.showcomments || !editable ? 'Comments' : 'Add comment' }}</p>

                                <div v-show="row.showcomments">
                                    <ResizeAuto>
                                        <template v-slot:default="{resize}">
                                <textarea

                                    class="form-control action-plan-comments-input"
                                    @input="resize"
                                    v-bind:name="name + '_actions['+(row.existing_id ? row.existing_id : index)+'][comments]'"
                                    v-model="row.comments"
                                    :class="{ disabled: row.status==1 || !editable }"
                                ></textarea>
                                        </template>
                                    </ResizeAuto>
                                </div>

                            </div><!-- .card-body -->
                        </div>
                        <div class="col-lg-3">
                            <div class="card-footer action-plan-footer pt-2 pb-3">
                                <div class="row">
                                    <div class="col-md-4 col-lg-11">
                                        <label class="small font-weight-bold mb-0">Due date</label>
                                        <input type="date" v-bind:name="name + '_actions['+(row.existing_id ? row.existing_id : index)+'][due_date]'" v-model="row.due_date" v-bind:disabled="!editable" class="form-control form-control-sm" />
                                    </div><!-- .col -->

                                    <div class="col-md-4 col-lg-11">
                                        <label class="small font-weight-bold mb-0">Action status</label>
                                        <select v-bind:name="name + '_actions['+(row.existing_id ? row.existing_id : index)+'][status]'" v-bind:disabled="!editable" v-model="row.status" class="form-control form-control-sm">
                                            <option v-for="(status, key) in statuses" :value="key" :key="key">{{ status }}</option>
                                        </select>
                                    </div><!-- .col -->

                                    <div class="col-md-4 col-lg-11" v-show="row.status == 1">
                                        <label class="small font-weight-bold mb-0">Completed date</label>
                                        <input type="date" v-bind:name="name + '_actions['+(row.existing_id ? row.existing_id : index)+'][completed_at]'" v-bind:disabled="!editable" v-model="row.completed_at" class="form-control form-control-sm" />
                                    </div><!-- .col -->
                                </div><!-- .row -->
                            </div>
                        </div>
                    </div>




                </div>
                <div class="controls mt-3 ">

                    <span
                        v-if=add_control
                        v-bind:class="{
                            'btn':true,
                            'btn-eqm-green':true,
                            disabled: rows.length >= max,
                            pointer: rows.length < max,
                            'd-none': index != rows.length - 1
                        }"
                        v-bind:disabled="rows.length >= max"
                        v-bind:visible="index == rows.length - 1"
                        @click="addRow(index)"
                    >
                        <i class="fas fa-plus"></i> Add another action
                    </span>
                </div><!-- .controls -->

            </div><!-- .action-plan -->
        </div>

        <div class="completed-actions" v-if="completed_rows.length">

            <h5 class="h5 completed-actions-title">{{ completed_rows.length }} Completed action{{completed_rows.length > 1 ? 's' : ''}} <button type="button" class="btn btn-link completed-actions-toggle" @click="toggleCompletedActions()">{{ show_completed ? 'Hide list' : 'Show' }}&nbsp;&nbsp;<i class="fas" :class="{'fa-chevron-up':show_completed,'fa-chevron-right':!show_completed}"></i></button></h5>


            <div class="completed-action-plan d-flex" v-if="show_completed" v-for="(row, index) in completed_rows" :key="row.id" :class="{ 'is-loading': isLoading, 'my-3': index > 0, 'mb-3 mt-0': index == 0 }">
                <div class="completed-action-date">
                    {{ row.completed_at}}
                </div>
                <div class="completed-action-plan-action">
                    {{ row.value }}
                </div>


            </div>
        </div>

    </div><!-- .question -->



</template>

<script>
    import '../other/object';
    import ResizeAuto from "../components/ResizeAuto";
    import { addClass, removeClass } from '../general';
    import _ from 'lodash';
    export default {
        name: 'ActionPlan',
        components: {
          ResizeAuto,
        },
        props: {
            'question': {},
            'statuses': {},
            'actions': {},
            'completed_actions': {},
            'textarearows': { 'type': [Number], 'default': 2 },
            'min': { 'type': [Number, String], 'default': 1, },
            'max': { 'type': [Number, String], 'default': 3, },
            'business_id': { 'type': [Number], 'default': 0, },
            'application_id': { 'type': [Number], 'default': 0, },
            'name': { 'type': String, },
            'show': { 'type': Boolean, default: false, },
            'toggles': { 'type': Boolean, default: true, },
            'title': { 'type': String, },
            'empty_action_row' : { 'type': Boolean, default: true, },
            'autosave' : { 'type': Boolean, default: false, },
            'editable' : { 'type': Boolean, default: true, },
            'add_control' : { 'type': Boolean, default: true, },
            'has_completed': { 'type': Boolean, default: false, },
            'has_actions': { 'type': Boolean, default: false, },
            'is_empty_action_row': { 'type': Boolean, default: false, },
            'hide_empty_by_default': { 'type': Boolean, default: false, },
        },
        data: function() {
            return {
                isLoading: false,
                isAutoSaving: false,
                'visible': false,
                'showtoggles': true,
                'chevron_default': 'fa-chevron-down',
                'rows': [],
                'row_hover_states': [],
                'showcomments': false,
                updatingFromAutosave: false,
                completed_rows: [],
                thewatcher: null,
                show_completed: false
            };
        },

        created: function () {
            // _.debounce is a function provided by lodash to limit how
            // often a particularly expensive operation can be run.
            // In this case, we want to limit how often we access
            // autosave api, waiting until the user has completely
            // finished typing before making the ajax request. To learn
            // more about the _.debounce function (and its cousin
            // _.throttle), visit: https://lodash.com/docs#debounce
            this.debouncedAutoSave = _.debounce(this.autoSave, 800);

        },
        beforeMount() {

            // Create any rows based on real data
            this.loadActions(this.actions);
            this.loadCompletedActions(this.completed_actions);

            if(this.empty_action_row) {
                this.pushRow(true);
            }

            // Determine how many elements we are going to have
            /*let i = this.rows.length || 0;
            do {
                // The only time we don't want anymore rows is if we are going
                // to end up going over the limit.
                if (this.max !== undefined && this.rows.length == ~~this.max) {
                    continue;
                }

                // Set this to -1 index
                this.pushRow(true);

                i++;
            } while (i < ~~this.min);*/
        },
        mounted() {
            this.visible = this.show;
            this.showtoggles = this.toggles;
            this.chevron = this.visible ? 'up' : 'down';

            /**
             * We don't use the watch { rows: handler() } approach here, rather, we opt for the inline
             * attachment of the watcher. This allows us to switch it on and off later when performing updates to the
             * data that shouldn't trigger a watch "event"
             */
            this.addWatcher();

        },
        computed: {
            chevron: {

                get() {
                    return this.visible ? 'fa-chevron-up' : (this.isEmpty(this.rows) || !this.has_actions ? 'fa-plus' : this.chevron_default) ;
                },
                set(chevron){
                    this.chevron_default = chevron;
                }


            }
        },
        methods: {
            addWatcher() {
                this.unwatch = this.$watch('rows', this.debouncedAutoSave,{deep:true})
            },
            removeWatcher() {
                this.unwatch();
            },
            loadActions(actionlist) {
                for (let action in actionlist) {

                    if ((actionlist[action] || {}).action !== '') {
                        // Convert the database string to just a date by removing
                        // the timestamp.
                        let due_date = actionlist[action].due_date || '';
                        if (due_date.length === 0) due_date = '';
                        // Timestamp provided.
                        if(typeof due_date === 'string') {
                            if (!!~due_date.indexOf(' ')) {
                                due_date = due_date.substring(0, due_date.indexOf(' '));
                            }
                        }

                        let completed_at = actionlist[action].completed_at || '';
                        if (typeof completed_at === 'string' && completed_at.length === 0) completed_at = '';
                        // Timestamp provided.
                        if(typeof completed_at === 'string') {
                            if ( !!~completed_at.indexOf(' ') ) {
                                completed_at = completed_at.substring(0, completed_at.indexOf(' '));
                            }
                        }

                        this.rows.push({
                            existing_id: actionlist[action].existing_id,
                            value: actionlist[action].action,
                            comments: actionlist[action].comments,
                            due_date: due_date,
                            status: actionlist[action].status,
                            completed_at: completed_at,
                            isCAR: !!actionlist[action].isCAR,
                            hovered: false,
                            showcomments: actionlist[action] && actionlist[action].comments && actionlist[action].comments.length
                        });
                        this.row_hover_states.push({
                            hovered: false,
                        });
                    }
                }
            },

            loadCompletedActions(actionlist) {
                for (let action in actionlist) {

                    if ((actionlist[action] || {}).action !== '') {
                        // Convert the database string to just a date by removing
                        // the timestamp.
                        let due_date = actionlist[action].due_date || '';
                        if (due_date.length === 0) due_date = '';
                        // Timestamp provided.
                        if(typeof due_date === 'string') {
                            if (!!~due_date.indexOf(' ')) {
                                due_date = due_date.substring(0, due_date.indexOf(' '));
                            }
                        }

                        let completed_at = actionlist[action].completed_at || '';
                        if (typeof completed_at === 'string' && completed_at.length === 0) completed_at = '';
                        // Timestamp provided.
                        if(typeof completed_at === 'string') {
                            if ( !!~completed_at.indexOf(' ') ) {
                                completed_at = completed_at.substring(0, completed_at.indexOf(' '));
                            }
                        }

                        this.completed_rows.push({
                            existing_id: actionlist[action].existing_id,
                            value: actionlist[action].action,
                            comments: actionlist[action].comments,
                            due_date: due_date,
                            status: actionlist[action].status,
                            completed_at: completed_at,
                            isCAR: !!actionlist[action].isCAR,
                            hovered: false,
                            showcomments: actionlist[action] && actionlist[action].comments && actionlist[action].comments.length
                        });
                    }
                }
            },

            pushRow(isEmpty) {

		            this.rows.push({
                        existing_id : '',
			            value       : '',
			            due_date    : '',
			            comments    : '',
			            status      : 0,
			            completed_at: '',
			            isCAR       : false,
			            hovered     : false,
			            is_empty_action_row: isEmpty,
			            hide_empty_by_default: this.hide_empty_by_default,
                        showcomments: this.showcomments
		            });
                    this.row_hover_states.push({
                        hovered: false,
                    });

            },

            addRow(index) {
                if (typeof this.max === 'undefined' || this.rows.length < this.max) {
		            this.pushRow(false);
                }
            },
            deleteRow(index) {

                // Stop autosaving whilst we delete
                this.removeWatcher();

                //If it is a previously saved action then we will delete it directly, otherwise we can just remove it from the vue model
                this.$bvModal.msgBoxConfirm('Are you sure you want to delete this action?', {
                    contentClass: 'shadow rounded border-0',
                    centered: true,
                    modalClass: 'modal-transparent-backdrop',
                    okVariant: 'danger',
                    okTitle: 'Yes, delete it',
                    cancelTitle: 'Forget I said anything',
                }).then(value => {

                        if(value) {
                            this.isLoading = true;
                            if (this.rows[index].existing_id) {
                                axios.put(`/profile/businesses/${this.business_id}/actions/${this.application_id}/deleteaction`, {

                                        action: this.rows[index]

                                    })
                                    .then((response) => {
                                        if (response.data.success) {
                                            this.rows.splice(index, 1);
                                            this.row_hover_states.splice(index, 1);
                                        }
                                        this.isLoading = false;
                                        this.addWatcher();
                                    });
                            } else {
                                this.rows.splice(index, 1);
                                this.row_hover_states.splice(index, 1);
                                this.addWatcher();
                                this.isLoading = false;
                            }
                        }
                        else {
                            this.isLoading = false;
                            this.addWatcher();
                        }

                    })
                    .catch(err => {
                        // An error occurred
                        this.isLoading = false;
                        this.addWatcher();
                    });

            },
            deleteOnEnter(index) {
                this.row_hover_states[index].hovered = true;
            },
            deleteOnLeave(index) {
                this.row_hover_states[index].hovered = false;
            },
            showActionPlan() {
              this.visible = true;
            },
            toggleActionPlan() {
                this.visible = !this.visible;
                //this.chevron = this.visible ? 'fa-chevron-up' : (this.isEmpty(this.rows) || !this.has_actions ? 'fa-plus' : 'fa-chevron-down') ;
            },
            toggleCompletedActions() {
                this.show_completed = !this.show_completed;
            },
            isEmpty(rows) {
                // Iterate over every row, check if it contains only empty
                // values. If it does contain only empty values, then we will
                // assume it is empty.
                return rows.map(row => Object.values(row).filter(a => !!a).length !== 0).filter(a => !!a).length === 0;
            },
            toggleComments(index) {
                this.rows[index].showcomments = !this.rows[index].showcomments;
            },

            autoSave() {
                let data = this.rows, qname = this.name;

                // Autosaving can be disabled via the autosave prop (e.g. within the questionnaire)
                // and we also don't want to do it if we haven't got any actions
                if(!this.autosave || !data.length) {
                    return;
                }

                this.isAutoSaving = true;

                axios.put(`/profile/businesses/${this.business_id}/actions/${this.application_id}/autosave`, {

                    questionactions: data,
                    questionkey: qname

                })
                    .then((response) => {

                        try {
                            /**
                             * The third clause here (comparing the action count and the rows length) is required to
                             * enable us to add an action to the interface and for it not to be removed immediately by the
                             * autosave triggering. It *will* trigger, but the controller will only send back non-empty actions
                             * so the current rows array will be longer (it includes the new empty action)
                             */
                            if (response.data
                                && response.data.success
                                && response.data.action_count >= this.rows.length
                            ) {

                                    // We need to remove the watcher on this.rows so that we can update it without triggering the autosave function again
                                    this.removeWatcher();

                                    this.rows = [];
                                    this.loadActions(response.data.actions);

                                    // Renable the watcher to start autosaving again
                                    this.addWatcher();

                            }
                        }
                        catch(e) {

                        }
                        this.isAutoSaving = false;

                    });

            }
        },
    }
</script>

<style scoped>
    .pointer {
        cursor: pointer;
    }

    /*
     * This is the same div that gets .hovered applied to it.
     * There is an extra 1px padding on this div because .hovered has a border.
     */
    .action-plans > div {
       /* padding: 5px;*/
    }

    .action-plans:not(:last-of-type) {
        margin-bottom: 20px;
        border-bottom: 1px solid #111;

    }

    .action-plans.action-plans_hascompleted {
        border-bottom: none;
    }

    .action-plan:not(:last-of-type) {
        /*border-bottom: 1px solid #111;*/
    }

    .correction {
        background-color: #f3f5f7;
    }

    .action-plan {
        /*padding: 5px;*/
    }

    .hovered {
       /* border-radius: 10px;*/
        /*border: 1px solid rgba(255, 0, 0, 0.6);*/
        /* Remove 1px from all edges because of the border-width */
        /*padding: 4px !important;*/
    }
    .action-plan-body, .action-plan-footer {
        transition: all 90ms;
    }
    .hovered .action-plan-body {
        border: 3px solid #dd5959;
        background: #f3e9e9;
        opacity: .4;

    }
    .hovered .action-plan-footer {
        opacity: .4;
    }



</style>
<style>
    .modal-transparent-backdrop ~ .modal-backdrop {
        background: #0000001f;
    }
    footer.modal-footer {
        border-bottom-right-radius: 0.2rem;
        border-bottom-left-radius: 0.2rem;
        background: #eaeaea;
    }
    .autosaving-icon {
        position: absolute;
        right: 10px;
        top: 10px;
    }
    .is-loading {
        opacity: .4;
    }
</style>
