<template>
    <div>
        <div class="card-deck card-meter-columns" :class="{'is-loading': isLoading}">
            <loading v-if="isLoading" style="font-size: 1.2rem !important; position: absolute; top: 10px; right: 10px;"></loading>
            <template v-for="(row, index) in rows">
                <div class="card card-meter" :class="{meter_uses_readings: uses_readings}" :key="row.id" :meter_title="meter_title" :verb="verb">
                    <input type="hidden" :name="`${name}[meters][${index}][id]`" v-model="row.id"/>
                    <div class="card-header">
                        <input
                            type="text"
                            class="form-control form-control-lg meter-name-input"
                            :class="{'meter_name_required': row.name_is_empty_error}"
                            :name="`${name}[meters][${index}][name]`"
                            @input="enableNewMeter(); clearMeterNameMissingError(index)"
                            v-model="row.name"
                            :placeholder="`Enter a ${meter_title} name`"
                        />
                        <div class="meter_name_required_feedback" v-if="row.name_is_empty_error">
                            <p>Please enter a name to refer to this {{ meter_title }}</p>
                        </div>
                        <div class="text-right">
                            <button class="btn btn-link btn-sm meter-description-toggle-btn" type="button" data-toggle="collapse" :data-target="`#description_meter_${index}`" aria-expanded="false" :aria-controls="`description_meter_${index}`">
                                Add description/ref
                            </button>
                        </div>
                        <div :id="`description_meter_${index}`" class="collapse">
                            <div class="form-group">
                                <input
                                    type="text"
                                    class="form-control"
                                    :name="`${name}[meters][${index}][description]`"

                                    v-model="row.description"
                                    placeholder="Add a description or reference"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="card-body meter-usage-amount-area text-monospace form-inline">

                        <div class="form-group">
                            <label for="`${name}[meters][${index}][kwh_used]`" class="meter-usage-amount-input"><span v-html="units"></span> &nbsp;{{verb}}</label>
                            <input
                                type="number"
                                step="0.1"
                                min="0"
                                class="form-control"
                                :name="`${name}[meters][${index}][kwh_used]`"
                                @input="solveKwh('kwh_used', index); checkForMeterName(index)"
                                v-model="row.kwh_used"
                            />
                        </div>


                        <div class="form-group mt-2" v-if="show_fuel_type">

                            <label for="`${name}[meters][${index}][fuel_type_id]`" class="meter-usage-amount-input">Type</label>

                            <select
                                class="form-control ml-2"
                                :name="`${name}[meters][${index}][fuel_type_id]`"
                                v-model="row.fuel_type_id"
                            >
                                <option value="">Choose a type...</option>
                                <option v-for="fuel_type in fuel_types" :value="fuel_type.id" :key="fuel_type.id">{{ fuel_type.name }}</option>
                            </select>

                        </div>

                        <slot name="additional_meter_fields" v-bind:slotProps="{row: row, index: index, name: name, solveKwh: solveKwh, updateRow: updateRow}"></slot>

                    </div>
                    <div class="card-body meter-readings-input-pair px-0" v-if="uses_readings">
                        <div class="text-right">
                            <button class="btn btn-link btn-sm" type="button" data-toggle="collapse" :data-target="`#meter_readings_for_${index}`" aria-expanded="false" :aria-controls="`meter_readings_for_${index}`">
                                Enter meter readings
                            </button>
                        </div>
                        <div :id="`meter_readings_for_${index}`" class="collapse">
                            <div class="px-2">
                                <p class="text-center">If you know your meter readings you can use these to automatically calculate the total usage</p>
                                <div class="row no-gutters">

                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="`${name}[meters][${index}][current_reading]`" class="form-control-sm">Current reading</label>
                                            <input
                                                type="number"
                                                step="0.1"
                                                class="form-control form-control-sm"
                                                min="0"
                                                :name="`${name}[meters][${index}][current_reading]`"
                                                @input="solveKwh('current_reading', index)"
                                                v-model="row.current_reading"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label  class="form-control-sm">Previous reading</label>
                                            <input
                                                type="number"
                                                step="0.1"
                                                class="form-control form-control-sm disabled"
                                                disabled
                                                :value="((value || {}).meters[index] || {}).current_reading"
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="showMeterDelete(row,index)" class="meter-delete text-right">
                        <button class="btn btn-link btn-sm mr-1 meter-delete-btn" type="button" @click="deleteMeter(row,index)">Delete</button>
                    </div>
                </div>

            </template>


        </div>
        <div class="btn btn-outline-primary meter-add-new-trigger" :class="{disabled: !canAddRow}" @click="addRow()">
            <i class="fas fa-plus"></i> <span v-html="`Add a ${meter_title}`"></span>
        </div>


        <div class="meter-total-usage-area">
            <table class="table table-md-responsive">
                <tbody>
                <tr>
                    <th scope="row">Total <span v-html="units"></span></th>
                    <td class="text-monospace">{{ total_kwh }}</td>
                </tr>
                <tr v-if="showCO2Totals">
                    <th scope="row">Total CO<sub>2</sub>e <i class="fad fa-info-circle" style="position: relative; display: inline-block;" v-tooltip.bottom="'Carbon equivalent, shown in tonnes per year'"></i></th>
                    <td class="text-monospace">{{ total_co2 }} tonnes/yr</td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>
</template>

<script>
    import '../../other/previousElementSibling';
    import '../../other/children';
    import '../../other/array';


    import { toFloat, formatFloat } from '../../other/Number';
    import findLast from 'lodash/findLast';
    import Loading from "../LoadingComponent.vue";
/*  <div class="w-100 d-none d-sm-block d-md-none" v-if="index % 2"><!-- wrap every 2 on sm--></div> */
    // We are doing this so that I can write _actual_ lodash in the script, even
    // though I only imported the one function.
    const _ = {};
    _.findLast = findLast;


    export default {
        name: 'CarbonMeter',
        components: {
          Loading
        },
        props: {
            name: { type: String, },
            scopes: { type: [Array, Object], default: () => [], },
            value: { type: [Array, Object], default: () => [], },
            max: { type: Number, default: -1, },
            model: { type: [Array, Object], default: () => [], },
            units: { type: String, default: '' },
            meter_title: { type: String, default: 'meter'},
            uses_readings: { type: Boolean, default: true },
            verb: {type: String, default: 'used'},
            show_fuel_type: { type: Boolean, default: false },
            fuel_types: { type: [Array, Object], default: () => [], },
        },
        data: function() {
            return {
                rows: [],
                start: '',
                end: '',
                canAddNewMeter: false,
                showCO2Totals: true,
                isLoading: false,
            };
        },
        computed: {
            factor() {
                let d = new Date(this.start == '' ? Date() : this.start);
                let scope = Object.values(this.scopes)[0];

                if (!scope) {
                    return 0;
                }

                let factor = scope.factors[d.getFullYear()] || _.findLast(scope.factors);
                if (!factor) {
                    return 0;
                }

                return factor.factor; // <-- this code is horrible to read (>_<)
            },
            total_kwh() {
                let total = this.rows.reduce(function (carry, item) {
                    return carry + (parseFloat(item.kwh_used) || 0);
                }, 0);
                return this.formatFloat(total);
            },
            total_co2() {

                return ((this.toFloat(this.total_kwh) * this.factor) / 1000).toFixed(1);
            },

            canAddRow: {
                get: function () {
                    let canAddRow = false,
                        lastRow = _.findLast(this.rows);

                    if (lastRow) {
                        // Is the last row empty?
                        let allValues = lastRow.name + lastRow.description + lastRow.kwh_used;

                        if (allValues && allValues !== '0') {
                            canAddRow = true;
                        }
                    }


                    if (this.max !== -1 && this.rows.length >= this.max) {
                        canAddRow = false;
                    }

                    return canAddRow;
                }
                ,
                set: function (value) {
                    this.canAddNewMeter = true;
                }

            }
        },
        methods: {
            toFloat,
            formatFloat,

            pushRow() {
                this.rows.push({
                    id: '',
                    name: '',
                    description: '',
                    current_reading: '',
                    kwh_used: '', // <-- Not sure whether to set to 0 or '',
                    fuel_type: '',
                    fuel_type_id: '',
                    fueltype: '',
                    greentariff: 0,
                    name_is_empty_error: false
                });
            },

            rowIsEmpty(row) {
                return row
                && !!(row.name
                || row.description
                || row.current_reading
                || row.kwh_used);
            },

            addRow() {
                //this.rows = this.rows.filter(row => this.rowIsEmpty(row));
                if (this.max === -1 || this.rows.length < this.max) {
                    this.pushRow();
                }
            },

            enableNewMeter() {
                this.canAddRow = true;
            },

            canAddRowMethod() {

                let canAddRow = false,
                    lastRow = _.findLast(this.rows);

                if(lastRow) {
                   // Is the last row empty?
                   let allValues = lastRow.name + lastRow.description + lastRow.kwh_used;

                   if(allValues) {
                       canAddRow = true;
                   }

                }


                if(this.max !== -1 && this.rows.length >= this.max ) {
                    canAddRow = false;
                }

                return canAddRow;
            },

            checkForMeterName(index = undefined) {
                try {
                    if(!this.rows[index].name.length) {
                        this.rows[index].name_is_empty_error = true;
                    }
                }
                catch(e) { }
            },
            clearMeterNameMissingError( index = undefined) {
                try {
                    this.rows[index].name_is_empty_error = false;
                }
                catch(e) { }
            },

            solveKwh(column, index = undefined) {
                try {
                    let fuel_type = this.rows[index].fuel_type || null,
                        type_class_slug = fuel_type ? fuel_type.type_class_slug : null;

                    let prevReading = this.toFloat(
                        (this.value.meters[index] || {current_reading: 0}).current_reading
                    );

                    // Make sure we always have values - even if zero
                    this.rows[index].current_reading = this.rows[index].current_reading || 0;
                    this.rows[index].kwh_used = this.rows[index].kwh_used || 0;
                    this.rows[index].fuel_type = this.rows[index].fuel_type || '';

                    if (prevReading && prevReading > 0) {
                        if (column === 'kwh_used') {
                            this.rows[index].current_reading = this.toFloat(this.rows[index].kwh_used) + prevReading;
                        } else if (column === 'current_reading') {
                            this.rows[index].kwh_used = this.toFloat(this.rows[index].current_reading) - prevReading;
                        }
                    }

                    // Fixed that when incrementing current usage, the kwh_used
                    // field was showing weird pointer numbers.
                    this.rows[index].current_reading = this.toFloat(this.rows[index].current_reading);
                    this.rows[index].kwh_used = this.toFloat(this.rows[index].kwh_used);
                }
                catch(e) {
                    //console.log("Solve KwH error",e);
                }

                //this.addRow();
            },

            /**
             * @todo Refresh totals when changing sub-types/options
             */
            updateRow(row,index) {

            },

            showMeterDelete(row,index) {
                return true;
                return index > 0;
            },

            deleteMeter(row,index) {
                this.isLoading = true;
                this.$bvModal.msgBoxConfirm('Are you sure you want to delete this meter?', {
                    contentClass: 'shadow rounded border-0',
                    centered: true,
                    modalClass: 'modal-transparent-backdrop',
                    okVariant: 'danger',
                    okTitle: 'Yes, delete it',
                    cancelTitle: 'Forget I said anything',
                }).then(value => {

                    if(value) {
                        this.isLoading = true;
                        // NB Deletions are handled by emptying and then re-saving the full meter array rather than
                        // through individual requests
                        this.rows.splice(index, 1);
                        this.isLoading = false;

                    }
                    else {
                        this.isLoading = false;
                    }

                })
                .catch(err => {
                    // An error occurred
                    this.isLoading = false;
                });
            },

        },

        mounted() {
            // This is so that this.rows and this.model are _not_ linked by
            // reference.
            const model = JSON.parse(JSON.stringify(this.model));
            //this.start = model.period_start;
            //this.end = model.period_end;
            this.rows = model.meters;

            // On first run, we want to make sure the whole data is populated.
            this.rows.forEach((row, index) => {
                this.solveKwh(row.kwh_used == null ? 'current_reading' : 'kwh_used', index);
            });

            this.showCO2Totals = this.$show_co2_during_input;

            if (this.rows.length === 0) {
                this.addRow();
            }
        },
    }
</script>

<style scoped>
.meter_name_required {
    border-color: red !important;
}
.meter_name_required_feedback {
    display: block;
    background: #dcd0cb;
    padding: 8px;
    font-size: 14px;
    color: #6d1717;
}
.is-loading {
    opacity: .4;
}
.meter-delete-btn {
    color: #e9ecef;
    font-size: .7rem;
}
</style>
