<template>
    <a class="btn btn-link" :class="css_class" @click.prevent="confirmExit" :href="url">Exit form</a>
</template>

<script>
export default {
    name: "ExitSaveButton",
    props: {
        'url': { 'type': String, },
        'css_class': '',
    },
    methods: {
        confirmExit: function() {

            let questionnaireHasChanges = document.getElementById("questionnaire").classList.contains('changesmade');

            if(!questionnaireHasChanges) {
                window.location.href = this.url;
                return false;
            }

            this.$bvModal.msgBoxConfirm('Are you sure? Anything you haven\'t already saved will be lost', {
                contentClass: 'shadow rounded border-0',
                centered    : true,
                modalClass  : 'modal-transparent-backdrop',
                okVariant   : 'danger',
                okTitle     : 'Yes, exit without saving',
                cancelTitle : 'No, let me carry on',
            }).then(value => {
                if ( value ) {
                    // Carry on leaving
                    window.location.href = this.url;
                }

            })
                .catch(err => {

                });


        }
    }
}
</script>

<style>
.modal-transparent-backdrop ~ .modal-backdrop {
    background: rgba(16, 22, 16, 0.43);
}
</style>
