<template>
    <div
        class="loading fas fa-2x"
        :class="[faIcon, faAnimation]"
    ></div>
</template>

<script>
    export default {
        name: 'Loading',
        props: {
            icon: {
                type: String,
                default: 'fa-spinner',
            },
            animation: {
                type: String,
                default: 'fa-spin',
            },
        },
        computed: {
            faIcon() {
                return ~this.icon.indexOf('fa-') ? this.icon : 'fa-'+this.icon;
            },
            faAnimation() {
                return ~this.animation.indexOf('fa-') ? this.animation : 'fa-'+this.animation;
            },
        }
    }
</script>
