<template>
<div class="vue-toast">
    <slot></slot>
</div>
</template>

<script>
export default {
    name: "ToastMessageComponent",
    props: {
        theme: {type: String, default: 'bubble', },
        duration: 5000,
        containerClass: {type: String, default: 'vuetoast-wrapper', },
        message: '',
        type: {type: String, default: 'info', },
        icon: '', // Can use fontawesome classes, e.g. "fal fa-check", and best to include a right margin (e.g. mr-3)
        keepOnHover: true,
        closeable: true
    },
    mounted() {
        this.$toasted.show(this.message, {
            theme: this.theme,
            position: "top-center",
            duration : this.duration,
            containerClass: this.containerClass,
            type: this.type,
            icon: this.icon,
            keepOnHover: this.keepOnHover,
            action : this.closeable ? {
                text : 'Close',
                onClick : (e, toastObject) => {
                    toastObject.goAway(0);
                    this.$toasted.clear();
                }
            } : null
        });
    }
}
</script>

<style>
.toasted.bubble {
    line-height: 1.4em !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    border-radius: 8px;
}
.toasted-container .toasted .action {

}
.toasted-container .toasted.info .action {
    color: #fff;
}

.flash-general-info .toasted.bubble {
    max-width: 750px;
    padding: 20px 40px !important;
}
.toasted.bubble.info {
    background: var(--mid-olive);
}
.flash-warning .toasted.bubble.error {
    background: var(--orange);
}
</style>
