var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("apexchart", {
        attrs: {
          options: _vm.chartOptions,
          height: _vm.height,
          width: _vm.width,
          type: _vm.chart_type,
          series: _vm.chartData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }