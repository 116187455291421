<template>
    <div>
        <vue-file-agent
            :theme="listTheme"
            ref="vueFileAgent"
            :multiple="true"
            :deletable="deleteable"
            :meta="true"
            :linkable="true"
            :maxSize="'10MB'"
            :maxFiles="20"
            :editable="editable"
            @rename="onRename($event)"
            @select="filesSelected($event)"
            @upload="uploadResponse('success', $event)"
            @upload:error="uploadResponse('error', $event)"
            @beforedelete="onBeforeDelete($event)"
            @delete="onDelete($event)"
            :uploadUrl="url"
            :uploadHeaders="uploadHeaders"
            :errorText="{
              type: 'Invalid file type',
              size: 'Files should not exceed 10MB in size',
            }"

            v-model="files"
        ></vue-file-agent>
    </div>
</template>
<script>

    import VueFileAgentPlugin from 'vue-file-agent';
    import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';
    import moment from "moment/moment";
    export default {
        name: 'EvidenceFiles',
        components: {
            'vue-file-agent': VueFileAgentPlugin.VueFileAgent,
        },
        props: {
            filelistref: { 'type': String, },
            existingfiles: { type: [Array, Object], default: () => [], },
            url: { 'type': String },
            listTheme: {
                type: String,
                default: () => "'default'" // 'default','list'
            },
            editable: { type: Boolean, default: () => true },
            deleteable: { type: Boolean, default: () => true },
        },
        data() {
          return {
              files: [],
              fileRecordsForUpload: [],
              uploadHeaders: {
                  'X-Test-Header': 'vue-file-agent',
                  'X-CSRF-TOKEN': window.Laravel.csrfToken,
                  'X-Requested-With': 'XMLHttpRequest'
              },
              fileRecordToDelete: null
          }
        },
        beforeMount() {
            this.loadFiles();
            $(document).on("keydown", ":input.file-name-input", function(event) {
                if (event.key == "Enter") {
                    event.preventDefault();
                }
            });
        },
        afterMount() {
            for(let file in this.files) {
                this.$refs.vueFileAgent.fileRecords.push(this.files[file]);
                this.$refs.vueFileAgent.rawFileRecords.push(this.files[file]);
            }
        },
        methods: {

            /**
             * This is currently never called as we use the autoupload feature. We can pass the id values
             * into the component and send them along manually with the file data. We can also add any ad hoc values
             * to the request.
             * @deprecated
             */
            uploadFiles: function () {

                let question = this.question_id,
                    application = this.application_id,
                    user = this.user_id;

                this.$refs.vueFileAgent.upload(this.url, this.uploadHeaders, this.fileRecordsForUpload, function createFormData(fileData) {
                    var formData = new FormData();
                    formData.append("question",question);
                    formData.append("application", application);
                    formData.append("user", user);
                    formData.append("file", fileData.file);
                    return formData;
                });
                this.fileRecordsForUpload = [];
            },

            filesSelected: function (fileRecordsNewlySelected) {
                var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
                this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
            },

            loadFiles() {
                for(let file in this.existingfiles) {

                    this.files.push(this.existingfiles[file]);
                }
            },

            onRename(fileRecord) {
               let newName = fileRecord.name(); // pass "false" to get name with extension
                fileRecord['extradata'] = 'Some info';
                return fileRecord;
            },

            uploadResponse(responsestatus, response) {
                if(responsestatus === 'error') {
                    //console.log(response);
                    return true;
                }
                if(responsestatus === 'success') {
                    //console.log(response.data.id);
                }

            },

            onBeforeDelete(fileRecord){
                if(confirm('Are you sure?')){
                    this.fileRecordToDelete = fileRecord;
                    /*this.$refs.vueFileAgent.fileRecords = [fileRecord];
                    this.$refs.vueFileAgent.rawFileRecords = [fileRecord];
                    console.log("Agent file record store",this.$refs.vueFileAgent.fileRecords);
                    this.$refs.vueFileAgent.deleteFileRecord(fileRecord);*/
                    //this.$refs.vueFileAgent.deleteUpload(this.url, this.uploadHeaders, fileRecord);
                }
            },

            onDelete(fileRecord){

                let fd = new FormData();
                fd.append('file',fileRecord);

                let deleteUrl = this.url + '?id=' + fileRecord.id;

                axios.delete(deleteUrl)
                    .then((response) => {

                });

                // delete from sever
                //this.$refs.vueFileAgent.deleteUpload(this.url, this.uploadHeaders, this.fileRecordToDelete);
                //this.$refs.vueFileAgent.deleteFileRecord(fileRecord);
            }

        }

    }
</script>

<style lang="scss">

.vue-file-agent {
    &.file-input-wrapper {
        background: #f4f4f4;
        border-radius: 11px;
        border-style: dotted;
        transition: all 240ms ease-in-out;

        &:hover {
            background: #f7f3df;
            border: 2px dashed #f0c2a0;

             .file-preview-new {
                .help-text {
                    color: #f6993f;
                }
                 svg {
                     fill: #f6993f;
                 }
             }

        }
    }
}

.vue-file-agent .file-preview-wrapper {
    border-radius: 12px;
    overflow: hidden;
    border: none;
    margin: 5px !important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.12);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 0.3s;
    .file-ext,
    .image-dimension,
    .file-size,
    .file-delete {
        opacity: 0;
        transition: all 100ms ease-in-out;
        font-size: 10px;
    }

    &:hover {
        .file-ext,
        .image-dimension,
        .file-size,
        .file-delete {
            opacity: 1;
        }
    }

    .file-delete {
        background: red;
        border-radius: 50%;
        color: #fff;
        transition: all 100ms ease-in-out;
        height: 23px;
        font-size: 18px;
        line-height: 21px;
        width: 23px;
        margin-right: 3px;
        margin-top: 3px;
        &:hover {
            background: #862a18;
        }
    }
}
@media screen and (min-width: 980px) {
    .vue-file-agent .file-preview-wrapper {
        margin: 19px !important;
    }
}

// PDF background colour
.vue-file-agent .file-preview-wrapper-pdf .file-preview {
    background-color: rgba(193, 30, 7, 0.71) !important;

}

.theme-list {
    .grid-block-wrapper .grid-block {
        width: 100%;
        margin: 0 !important;
        border-radius: 0;
    }
}

.no-uploads {
    .vue-file-agent.file-input-wrapper {
        border: none;
        background: #f4f4f4;
        border-radius: 0;
        padding: 0;
        &:hover {
            border: none;
            background: #f4f4f4;
        }
    }
    .file-input, .file-preview-new, .file-preview-new .file-preview {
        display: none;
    }
}
</style>
