<template>
    <div>
        <apexchart :options="chartOptions" :height="height" :width="width" :type="chart_type" :series="chartData"></apexchart>
    </div>
</template>

<script>
    //import Chart from 'chart.js/auto';
    import VueApexCharts from 'vue-apexcharts';
    import axios from 'axios';

    export default {
        name: 'Chart',
        props: {
            dataset_labels: { type: [String,Array,Object], default: () => [], },
            width: { type: Number, default: 800},
            height: { type: Number, default: 300},
            series: { type: [Object,Array], default: () => {}, },

            chart_type: { type: String, default: 'line', },
            chart_id: {type: String, default: 'newchart' },
            chart_url: {type: String, default: '' },
            use_override_data: {type: Boolean, default: false},
            override_data: { type: [Object,Array], default: () => {}, },
            /*data: { type: [Object,Array], default: () => {}, },
            options: { type: Object, default: () => {}, },*/
            color_scheme: { type: [String,Array,Object], default: () => [], },
            animate: {type: Boolean, default: true},
            show_tooltips: {type: Boolean, default: true},
            floating_legend: {type: Boolean, default: true},
            show_legend: {type: Boolean, default: true},
            show_data_labels: {type: Boolean, default: false},
            data_labels_color: {type: String, default: '#fff' },
            horizontal: {type: Boolean, default: true},
        },
        components: {
            apexchart: VueApexCharts,
        },
        data() {
            return {
                barWidth: 24,


                /*chart: '',
                type: 'line',*/
            }
        },

        computed: {
            chartData() {
                return this.use_override_data ? this.override_data : this.series[0].data;
            },
            chartHeight() {
                return 200 + (this.series[0].data.length * this.barWidth);
            },
            chartOptions() {
                return {
                    chart: {
                        id: this.chart_id,
                        height: this.height,
                        type: this.chart_type,
                        animations: {
                            enabled: this.animate,
                        },
                        dropShadow: {
                            enabled: false,
                        },
                        toolbar: {
                            show: true,
                        },
                        xaxis: {
                            labels: {
                                style: {
                                    fontSize: '14px',
                                    fontFamily: 'Nunito, Helvetica, Arial, sans-serif',
                                }
                            }
                        },
                        yaxis: {
                            labels: {
                                style: {
                                    fontSize: '14px',
                                    fontFamily: 'Nunito, Helvetica, Arial, sans-serif',
                                    fontWeight: 400,
                                }
                            }
                        }
                    },

                    states: {
                        hover: {
                            filter: {
                                type: 'darken',
                                value: 0.9,
                            }
                        },
                        active: {
                            allowMultipleDataPointsSelection: false,
                            filter: {
                                type: 'darken',
                                value: 0.9,
                            }
                        },
                    },

                    dataLabels: {
                        enabled: this.show_data_labels,
                        textAnchor: 'start',
                        offsetX: 20,
                        style: {
                            colors: [this.data_labels_color]
                        },
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    show: true,
                                    name: {
                                        fontFamily: 'Nunito, Helvetica, Arial, sans-serif',
                                    },
                                    value: {
                                        fontFamily: 'Nunito, Helvetica, Arial, sans-serif',
                                        formatter: function (val) {

                                            return val + " Tonnes"
                                        }
                                    },
                                    total: {
                                        show: true,
                                        fontFamily: 'Nunito, Helvetica, Arial, sans-serif',
                                        formatter: function (w) {
                                            let val = w.globals.seriesTotals.reduce((a, b) => {
                                                return a + b
                                            }, 0)
                                            if(val) {
                                                val = val.toFixed(2);
                                            }
                                            return val + " Tonnes"
                                        }

                                    }
                                }
                            }
                        },
                        bar: {
                            barHeight: '80%',
                            borderRadius: 4,
                            horizontal: this.horizontal,
                            distributed: true,
                            dataLabels: {
                                position: 'top',
                                enabled: true,

                                offsetX: 5,
                                offsetY: 10,
                                style: {
                                    colors: ['#333']
                                },
                                hideOverflowingLabels: false,
                            },
                        }
                    },
                    legend: {
                        show: this.show_legend,
                        position: 'left',
                        floating: this.floating_legend,
                        labels: {
                            useSeriesColors: true
                        }
                    },
                    yaxis: {
                        labels: {
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Nunito, Helvetica, Arial, sans-serif',
                                fontWeight: 400,
                            },

                            maxHeight: 360,
                            minHeight: 240,
                            maxWidth: 360,
                            minWidth: 240,
                        },

                    },
                    colors: this.color_scheme,
                    scheme: this.color_scheme,
                    /*theme: {
                        monochrome: {
                            enabled: true,
                            color: '#255aee',
                            shadeTo: 'light',
                            shadeIntensity: 0.65
                        }
                    },*/

                    labels: this.dataset_labels,
                    xaxis: {
                      categories: this.dataset_labels,
                        decimalsInFloat: 0,
                        title: {
                            text: 'Tonnes CO2E',
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Nunito, Helvetica, Arial, sans-serif',
                                fontWeight: 400,
                            }
                        },
                        labels: {
                            /*maxHeight: 360,
                            minHeight: 240,
                            maxWidth: 360,
                            minWidth: 240,*/
                        }
                    },
                    tooltip: {
                        enabled: this.show_tooltips
                    }
                    /*
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return val.toFixed(2) + " Tonnes"
                            }
                        },
                        custom: function({series, seriesIndex, dataPointIndex, w}) {
                            return '<div class="arrow_box p-2">' +
                                '<span>' + series[seriesIndex][dataPointIndex].toFixed(2) + ' Tonnes</span>' +
                                '</div>'
                        }
                    }*/
                }
            }
        },

        methods: {
            saveChartToFile() {
                let chart_upload_url = this.chart_url;

                this.$apexcharts.exec(this.chart_id,'dataURI').then(({ imgURI, blob }) => {
                    if (blob) {
                        //navigator.msSaveOrOpenBlob(blob, this.w.globals.chartID + '.png')
                    } else {

                        function dataURLtoBlob(dataurl) {
                            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
                            while(n--){
                                u8arr[n] = bstr.charCodeAt(n);
                            }
                            return new Blob([u8arr], {type:mime});
                        }


//var dataurl = canvas.toDataURL('image/jpeg',0.8); //canvas to jpg dataurl
                        let blob = dataURLtoBlob(imgURI);

                        let filename = this.chart_id || "Chart" + Date.now();
                        filename += ".png";

                        var fd = new FormData();
                        fd.append("image", blob, filename);

                        axios.post(chart_upload_url, fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                            }
                        }).then(response => {

                            this.successfullyCreatedChart();
                        });

                    }
                });

            },
            successfullyCreatedChart() {
                window[this.chart_id] = true;
                if(typeof window.numberOfChartsGenerated != 'undefined') {
                    window.numberOfChartsGenerated++;
                }
                else {
                    window.numberOfChartsGenerated = 1;
                }
                if(window.numberOfChartsGenerated === window.expectedNumberOfCharts) {
                    window.allChartsGenerated = true;
                }
            },
            changeType(newType) {
                /*this.type = newType;
                this.unrender();
                this.render();*/
            },
            unrender() {
                //this.chart && this.chart.destroy();
            },
            render() {
                /*this.chart = new Chart(this.$el, {
                    type: this.type,
                    data: this.data,
                    options: this.options,
                });*/

            }
        },
        watch: {
            type(type) {
                //this.changeType(type);
            },
        },

        mounted() {
            // Whenever the chart is loaded, save a cope of it.
            // We need the timeout to account for any loading animation
            // as the canvas is rendered as it appears on the screen
            if(window.downloadCharts) {
                let Api = this;
                setTimeout(() => {
                    Api.saveChartToFile();
                }, 3000);
            }
        }
    }
</script>
