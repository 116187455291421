var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.netzero_target_pcnt,
          expression: "netzero_target_pcnt",
        },
      ],
      attrs: {
        type: "hidden",
        name: "netzero_target_pcnt",
        id: "netzero_target_pcnt",
      },
      domProps: { value: _vm.netzero_target_pcnt },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.netzero_target_pcnt = $event.target.value
        },
      },
    }),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.netzero_target_year,
          expression: "netzero_target_year",
        },
      ],
      attrs: {
        type: "hidden",
        min: "min_target_year",
        step: "1",
        name: "netzero_target_year",
      },
      domProps: { value: _vm.netzero_target_year },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.netzero_target_year = $event.target.value
        },
      },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("net-zero-target-chart", {
            ref: "netzeroLineChart",
            attrs: {
              data: _vm.chart_data,
              years: _vm.year_labels,
              color_scheme: _vm.color_scheme,
              chart_url: "",
              threshold: _vm.threshold / 1000,
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row my-4" }, [
      _c("div", { staticClass: "card bg-white py-4" }, [
        _c("div", { staticClass: "col-11 text-center" }, [
          _c("p", { staticClass: "lead" }, [
            _vm._v("With a reduction rate of "),
            _c("strong", { staticClass: "font-weight-bold text-lg" }, [
              _vm._v(_vm._s(_vm.netzero_target_pcnt) + "%"),
            ]),
            _vm._v(
              " of your footprint from " +
                _vm._s(_vm.start_year) +
                " (equivalent to "
            ),
            _c("strong", { staticClass: "font-weight-bold" }, [
              _vm._v(
                _vm._s(_vm.netzero_target_tonnes) +
                  " " +
                  _vm._s(_vm.netzero_target_unit)
              ),
            ]),
            _vm._v(" CO2E in the first year) - you would achieve net zero by "),
            _c("strong", { staticClass: "font-weight-bold text-lg" }, [
              _vm._v(_vm._s(_vm.thresholdYear)),
            ]),
            _vm._v("."),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row py-4" }, [
      _c("div", { staticClass: "col-11" }, [
        _c("p", { staticClass: "text-white" }, [
          _vm._v(
            "The chart uses your most recent carbon audit footprint as a starting point. It shows what your estimated path to net zero looks like with the target percentage and/or year that you have selected."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "col-12 col-lg-10 text-white" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-light ml-0 mt-0",
            staticStyle: { "margin-left": "-10px !important" },
            attrs: {
              type: "button",
              "data-toggle": "collapse",
              "data-target": "#input_option_description_full",
              "aria-expanded": "false",
              "aria-controls": "input_option_description_full",
            },
          },
          [
            _c("i", { staticClass: "fa fa-chevron-circle-right fal" }),
            _vm._v("  Give me a bit more detail\n            "),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "collapse mt-4",
            attrs: { id: "input_option_description_full" },
          },
          [
            _c("p", [
              _vm._v(
                "On the chart you will see a dotted horizontal line. This represents the threshold at which you can officially claim to have achieved net zero. It isn't actually zero - it's 10% of you reference year's total emissions."
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "You can set you target reduction percentage, or the year by which you wish to achieve net zero on your Carbon Footprint Overview page (check out your dashboard to find this)."
              ),
            ]),
            _vm._v(" "),
            _c("h5", [_vm._v("This is an illustration only!")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Obviously it is unlikely that you will achieve a steady percentage reduction year-on-year! This chart is about getting some context of the scale of what needs to be done."
              ),
            ]),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }