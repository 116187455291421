import _ from 'lodash';
import { empty } from 'locutus/php/var';

const accepted = (value) => {
    if (value === true) {
        return true;
    }

    if (typeof value === 'string') {
        value = value.toLowerCase();
    }

    return ['true', 'yes', 'y', 'on', 1].indexOf(value) > -1;
};

const hasClass = function(classname) {
    return (this.className && new RegExp("(^|\\s)" + classname + "(\\s|$)").test(this.className)) || false;
};
Element.prototype.hasClass = hasClass;

const removeClass = function (classname) {
    var re = new RegExp("\\b" + classname + " \\b|\\b " + classname + "\\b|\\b" + classname + "\\b");
    if (this.hasClass(classname) || classname == undefined) {
        this.className = this.className.replace(re, "");
    }
    return this;
};
Element.prototype.removeClass = removeClass;

const addClass = function (classname) {
    if (this.hasClass(classname) || classname == undefined) {
        return this;
    }

    this.className += (this.className.length <= 0 ? "" : " ") + classname;
    return this;
};
Element.prototype.addClass = addClass;

const toggleClass = function (classname) {
    return this.hasClass(classname)
        ? this.removeClass(classname)
        : this.addClass(classname);
};
Element.prototype.toggleClass = toggleClass;

/**
 * This is to avoid having to use eval().
 * For what I needed right now I don't need to add anymore code to this as I am
 * only doing basic comparisons of two numbers. Going forward I would like to
 * allow this to convert any string into a Boolean.
 * EDIT: Check this out: https://github.com/naivesound/expr-js
 * @param  {mixed}   value Any expression.
 * @return {Boolean}       The Boolean.
 */
const parseBoolean = (value) => {
    value = value.replace(/(!==|!=|>=|<=|>|<|===|==|&&|\|\|)/, ' $1 ');
    value = value.spilt(/\s+/);

    // Basic example of an expression 1 == 2 etc
    if (value.length == 3) {
        switch (value[1]) {
            case '>':
                return value[0] > value[2];
            case '>=':
                return value[0] >= value[2];
            case '<':
                return value[0] < value[2];
            case '<=':
                return value[0] <= value[2];
            case '==':
                return value[0] == value[2];
            case '===':
                return value[0] === value[2];
            case '!=':
                return value[0] != value[2];
            case '!==':
                return value[0] !== value[2];
        }
    }
};

/**
 * Thanks @remus http://jsfiddle.net/remus/tfjH3/
 * @param  {[type]} str [description]
 * @param  {[type]} val [description]
 * @param  {[type]} obj [description]
 * @return {[type]}     [description]
 */
function parseDotNotationHandler(str, val, obj) {
    var currentObj = obj,
        keys = str.split("."),
        i, l = Math.max(1, keys.length - 1),
        key;

    for (i = 0; i < l; ++i) {
        key = keys[i];
        currentObj[key] = currentObj[key] || {};
        currentObj = currentObj[key];
    }

    currentObj[keys[i]] = val;
    delete obj[str];
}

function parseDotNotation(obj) {
    for (let key in obj) {
        if (key.indexOf(".") !== -1) {
            parseDotNotationHandler(key, obj[key], obj);
        }
    }
    return obj;
}

const toFloat = (num, digits) => {
    // To catch any NaN conversions
    if (empty(num)) {
        num = 0;
    }

    num = num.toString();

    // This is used to determine if the user has typed a comma to separate the
    // thousands, but did not include any decimals.
    // For instance, `11,000` would actually return 11.00. The following if
    // statement is going to prevent that.
    if (num.substr(num.lastIndexOf(',') + 1).length > 2) {
        num = num.replace(',', '');
    }

    // This is because some countries use the comma to separate decimals.
    // The following code with determine whether that is the case, or whether
    // the comma is being used to separate the thousands.
    let dotPos = num.indexOf('.');
    let commaPos = num.lastIndexOf(',');
    let sep = ((dotPos > commaPos) && dotPos) ? dotPos :
        (((commaPos > dotPos) && commaPos) ? commaPos : false);

    // No decimal places found
    if (!sep) {
        return parseFloat(num.replace(/[^0-9]/g, ''));
    }

    num = parseFloat(
        num.substr(0, sep).replace(/[^0-9]/g, '')
        + '.' +
        num.substr(sep + 1).replace(/[^0-9]/g, '')
    );

    return typeof digits == 'undefined' ? num : num.toFixed(digits);
}

const uniqueFirstLetters = (arr, allowed = /\w/i) => {
    return _.uniq(_.map(arr, (item) => {
        let i = 0;
        while (!(item.name || '').charAt(i).match(allowed)) {
            i++;
        }
        return (item.name || '').charAt(i).toUpperCase();
    }));
}

const evens = (arr) => {
    return oddIndex(arr);
}

const odds = (arr) => {
    return evenIndex(arr);
}

const evenIndex = (arr) => {
    let ret = [];
    for (var i = 0; i < arr.length; i++) {
        if (i % 2 === 0) {
            ret.push(arr[i]);
        }
    }

    return ret;
}

const oddIndex = (arr) => {
    let ret = [];
    for (var i = 0; i < arr.length; i++) {
        if (i % 2 !== 0) {
            ret.push(arr[i]);
        }
    }

    return ret;
}

const isDateSupported = () => {
    // https://gomakethings.com/how-to-check-if-a-browser-supports-native-input-date-pickers/
    var input = document.createElement('input');
    var value = 'a';
    input.setAttribute('type', 'date');
    input.setAttribute('value', value);
    return (input.value !== value);
};

export {
    accepted,
    hasClass,
    removeClass,
    addClass,
    toggleClass,
    parseBoolean,
    parseDotNotation,
    toFloat,
    uniqueFirstLetters,
    evenIndex,
    oddIndex,
    evens,
    odds,
    isDateSupported,
}
