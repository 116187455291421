var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "frmValidation--wrap" },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.message,
            expression: "message",
          },
        ],
        staticClass: "frmField",
        class: _vm.classes,
        attrs: { name: _vm.input_name, type: "password", required: "required" },
        domProps: { value: _vm.message },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.message = $event.target.value
            },
            _vm.password_check,
          ],
          focus: _vm.show_messages,
        },
      }),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.show_all_messages
          ? _c("div", { staticClass: "frmValidation--messages" }, [
              _c("p", { staticClass: "frmValidation--intro" }, [
                _vm._v("Please ensure your password..."),
              ]),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "frmValidation",
                  class: { "frmValidation--passed": _vm.message.length > 7 },
                },
                [
                  _c("i", {
                    staticClass: "frmIcon",
                    class:
                      _vm.message.length > 7 ? "fas fa-check" : "fal fa-times",
                  }),
                  _vm._v(" Is longer than 7 characters"),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "frmValidation",
                  class: { "frmValidation--passed": _vm.has_uppercase },
                },
                [
                  _c("i", {
                    staticClass: "frmIcon",
                    class: _vm.has_uppercase ? "fas fa-check" : "fal fa-times",
                  }),
                  _vm._v(" Has a capital letter"),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "frmValidation",
                  class: { "frmValidation--passed": _vm.has_lowercase },
                },
                [
                  _c("i", {
                    staticClass: "frmIcon",
                    class: _vm.has_lowercase ? "fas fa-check" : "fal fa-times",
                  }),
                  _vm._v(" Has a lowercase letter"),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "frmValidation",
                  class: { "frmValidation--passed": _vm.has_number },
                },
                [
                  _c("i", {
                    staticClass: "frmIcon",
                    class: _vm.has_number ? "fas fa-check" : "fal fa-times",
                  }),
                  _vm._v(" Has a number"),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "frmValidation",
                  class: { "frmValidation--passed": _vm.has_special },
                },
                [
                  _c("i", {
                    staticClass: "frmIcon",
                    class: _vm.has_special ? "fas fa-check" : "fal fa-times",
                  }),
                  _vm._v(" Has a special character"),
                ]
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }