var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("table", { staticClass: "table mb-0" }, [
      _c("thead", { staticClass: "border-top-0" }, [
        _c("tr", [
          _vm._m(0),
          _vm._v(" "),
          _vm.has_any_costs
            ? _c("th", { staticClass: "border-top-0 text-right" }, [
                _vm._v("Cost "),
                _c("small", [_vm._v("£")]),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("tbody", [
        _c("tr", [
          _vm._m(1),
          _vm._v(" "),
          _c("th", { staticClass: "align-middle", attrs: { scope: "row" } }, [
            _vm._v("Energy"),
          ]),
          _vm._v(" "),
          _c("td", { staticClass: "align-middle text-right" }, [
            _c("h3", [_vm._v(_vm._s(_vm.total_energy))]),
          ]),
          _vm._v(" "),
          _vm.has_any_costs
            ? _c("td", { staticClass: "align-middle text-right" }, [
                _vm.cost_energy !== "-"
                  ? _c("span", { staticClass: "d-inline small" }, [_vm._v("£")])
                  : _vm._e(),
                _c("span", { staticClass: "d-inline small" }, [
                  _vm._v(" " + _vm._s(_vm.cost_energy)),
                ]),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("tr", [
          _vm._m(2),
          _vm._v(" "),
          _c("th", { staticClass: "align-middle", attrs: { scope: "row" } }, [
            _vm._v("Transport"),
          ]),
          _vm._v(" "),
          _c("td", { staticClass: "align-middle text-right" }, [
            _c("h3", [_vm._v(_vm._s(_vm.total_transport))]),
          ]),
          _vm._v(" "),
          _vm.has_any_costs
            ? _c("td", { staticClass: "align-middle text-right" }, [
                _vm.cost_transport !== "-"
                  ? _c("span", { staticClass: "d-inline small" }, [_vm._v("£")])
                  : _vm._e(),
                _c("span", { staticClass: "d-inline small" }, [
                  _vm._v(" " + _vm._s(_vm.cost_transport)),
                ]),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _vm.total_commute > 0
          ? _c("tr", [
              _vm._m(3),
              _vm._v(" "),
              _c(
                "th",
                { staticClass: "align-middle", attrs: { scope: "row" } },
                [_vm._v("Commuting")]
              ),
              _vm._v(" "),
              _c("td", { staticClass: "align-middle text-right" }, [
                _c("h3", [_vm._v(_vm._s(_vm.total_commute))]),
              ]),
              _vm._v(" "),
              _vm.has_any_costs
                ? _c("td", { staticClass: "align-middle text-right" })
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("tr", [
          _vm._m(4),
          _vm._v(" "),
          _c("th", { staticClass: "align-middle", attrs: { scope: "row" } }, [
            _vm._v("Water"),
          ]),
          _vm._v(" "),
          _c("td", { staticClass: "align-middle text-right" }, [
            _c("h3", [_vm._v(_vm._s(_vm.total_water))]),
          ]),
          _vm._v(" "),
          _vm.has_any_costs
            ? _c("td", { staticClass: "align-middle text-right" }, [
                _vm.cost_water !== "-"
                  ? _c("span", { staticClass: "d-inline small" }, [_vm._v("£")])
                  : _vm._e(),
                _c("span", { staticClass: "d-inline small" }, [
                  _vm._v(" " + _vm._s(_vm.cost_water)),
                ]),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _vm.show_waste_disposal
          ? _c("tr", [
              _vm._m(5),
              _vm._v(" "),
              _c(
                "th",
                { staticClass: "align-middle", attrs: { scope: "row" } },
                [_vm._v("Waste")]
              ),
              _vm._v(" "),
              _c("td", { staticClass: "align-middle text-right" }, [
                _c("h3", [_vm._v(_vm._s(_vm.total_waste))]),
              ]),
              _vm._v(" "),
              _vm.has_any_costs
                ? _c("td", { staticClass: "align-middle text-right" }, [
                    _vm.cost_waste !== "-"
                      ? _c("span", { staticClass: "d-inline small" }, [
                          _vm._v("£"),
                        ])
                      : _vm._e(),
                    _c("span", { staticClass: "d-inline small" }, [
                      _vm._v(" " + _vm._s(_vm.cost_waste)),
                    ]),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.show_coolants
          ? _c("tr", [
              _vm._m(6),
              _vm._v(" "),
              _c(
                "th",
                { staticClass: "align-middle", attrs: { scope: "row" } },
                [_vm._v("Coolants")]
              ),
              _vm._v(" "),
              _c("td", { staticClass: "align-middle text-right" }, [
                _c("h3", [_vm._v(_vm._s(_vm.total_coolants))]),
              ]),
              _vm._v(" "),
              _vm.has_any_costs
                ? _c("td", { staticClass: "align-middle text-right" }, [
                    _vm.cost_coolants !== "-"
                      ? _c("span", { staticClass: "d-inline small" }, [
                          _vm._v("£"),
                        ])
                      : _vm._e(),
                    _c("span", { staticClass: "d-inline small" }, [
                      _vm._v(" " + _vm._s(_vm.cost_coolants)),
                    ]),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.total_generated > 0
        ? _c("tbody", [
            _c("tr", [
              _vm._m(7),
              _vm._v(" "),
              _c(
                "th",
                { staticClass: "align-middle", attrs: { scope: "row" } },
                [_vm._v("Electricity exported")]
              ),
              _vm._v(" "),
              _c("td", { staticClass: "align-middle text-right" }, [
                _c("h3", { staticClass: "text-generated-footprint" }, [
                  _vm._v("- " + _vm._s(_vm.total_generated)),
                ]),
              ]),
              _vm._v(" "),
              _vm.has_any_costs
                ? _c("td", { staticClass: "align-middle text-right" })
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("tfoot", [
        _c("tr", [
          _c(
            "td",
            { staticClass: "text-right align-middle", attrs: { colspan: "2" } },
            [
              _vm._v("\n                Total CO"),
              _c("sub", [_vm._v("2")]),
              _vm._v("e "),
              _c("i", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.bottom",
                    value: "Carbon equivalent, shown in tonnes",
                    expression: "'Carbon equivalent, shown in tonnes'",
                    modifiers: { bottom: true },
                  },
                ],
                staticClass: "fad fa-info-circle",
                staticStyle: { position: "relative", display: "inline-block" },
              }),
            ]
          ),
          _vm._v(" "),
          _c("td", { staticClass: "align-middle text-right" }, [
            _c("h1", [_vm._v(_vm._s(_vm.total))]),
          ]),
          _vm._v(" "),
          _vm.has_any_costs
            ? _c("td", { staticClass: "align-middle text-right" }, [
                _vm.cost_total !== "-"
                  ? _c("span", { staticClass: "d-inline small" }, [_vm._v("£")])
                  : _vm._e(),
                _c("span", { staticClass: "d-inline small" }, [
                  _vm._v(_vm._s(_vm.cost_total)),
                ]),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _vm.total_biomass > 0
          ? _c("tr", [
              _c(
                "td",
                {
                  staticClass: "text-right small",
                  attrs: { colspan: _vm.has_any_costs ? 4 : 3 },
                },
                [
                  _vm._v("\n                Your Biomass usage came to "),
                  _c("span", [_vm._v(_vm._s(_vm.total_biomass))]),
                  _vm._v(" CO"),
                  _c("sub", [_vm._v("2")]),
                  _vm._v(
                    "e, but this is not included in your overall carbon footprint  "
                  ),
                  _c("i", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.bottom",
                        value:
                          "NB Current guidelines for carbon reporting exclude any biomass usage from your overall footprint.<br> " +
                          "It is still useful to record this information as the guidance may change in the future",
                        expression:
                          "'NB Current guidelines for carbon reporting exclude any biomass usage from your overall footprint.<br> ' +\n                'It is still useful to record this information as the guidance may change in the future'",
                        modifiers: { bottom: true },
                      },
                    ],
                    staticClass: "fad fa-info-circle",
                    staticStyle: {
                      position: "relative",
                      display: "inline-block",
                    },
                  }),
                ]
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "th",
      { staticClass: "border-top-0 text-right", attrs: { colspan: "3" } },
      [_vm._v("Total Carbon Equivalent "), _c("small", [_vm._v("(tonnes)")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "align-middle" }, [
      _c("i", { staticClass: "fad fa-plug fa-2x text-energy-footprint" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "align-middle" }, [
      _c("i", { staticClass: "fad fa-car fa-2x text-transport-footprint" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "align-middle" }, [
      _c("i", { staticClass: "fad fa-car-bus fa-2x text-transport-footprint" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "align-middle" }, [
      _c("i", { staticClass: "fad fa-water fa-2x text-water-footprint" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "align-middle" }, [
      _c("i", { staticClass: "fad fa-dumpster fa-2x text-waste-footprint" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "align-middle" }, [
      _c("i", {
        staticClass: "fad fa-temperature-frigid fa-2x text-coolants-footprint",
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "align-middle" }, [
      _c("i", {
        staticClass: "fad fa-battery-half fa-2x text-generated-footprint",
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }