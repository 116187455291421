var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.inputs, function (input, index) {
      return _c("div", { staticClass: "input-group mb-3" }, [
        _vm.prepend
          ? _c(
              "div",
              { staticClass: "input-group-prepend" },
              _vm._l(_vm.prepend, function (text) {
                return _c("span", {
                  staticClass: "input-group-text",
                  domProps: { innerHTML: _vm._s(text) },
                })
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.type === "checkbox"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: input.value,
                  expression: "input.value",
                },
              ],
              staticClass: "form-control",
              attrs: {
                name: input.name,
                placeholder: _vm.placeholder,
                type: "checkbox",
              },
              domProps: {
                checked: Array.isArray(input.value)
                  ? _vm._i(input.value, null) > -1
                  : input.value,
              },
              on: {
                change: function ($event) {
                  var $$a = input.value,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && _vm.$set(input, "value", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          input,
                          "value",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(input, "value", $$c)
                  }
                },
              },
            })
          : _vm.type === "radio"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: input.value,
                  expression: "input.value",
                },
              ],
              staticClass: "form-control",
              attrs: {
                name: input.name,
                placeholder: _vm.placeholder,
                type: "radio",
              },
              domProps: { checked: _vm._q(input.value, null) },
              on: {
                change: function ($event) {
                  return _vm.$set(input, "value", null)
                },
              },
            })
          : _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: input.value,
                  expression: "input.value",
                },
              ],
              staticClass: "form-control",
              attrs: {
                name: input.name,
                placeholder: _vm.placeholder,
                type: _vm.type,
              },
              domProps: { value: input.value },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(input, "value", $event.target.value)
                },
              },
            }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "input-group-append append-input-suffix",
            class: { "not-last": index < _vm.inputs.length - 1 },
          },
          [
            _vm._l(_vm.append, function (text) {
              return _c("span", {
                staticClass: "input-group-text",
                domProps: { innerHTML: _vm._s(text) },
              })
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "input-group-text pointer",
                on: {
                  click: function ($event) {
                    return _vm.addRow()
                  },
                },
              },
              [_vm._v("+")]
            ),
            _vm._v(" "),
            index > (_vm.min || 1) - 1
              ? _c(
                  "span",
                  {
                    staticClass: "input-group-text pointer",
                    on: {
                      click: function ($event) {
                        return _vm.deleteRow(index)
                      },
                    },
                  },
                  [_vm._v("-")]
                )
              : _vm._e(),
          ],
          2
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }