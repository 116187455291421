<template>
    <div>
        <select :multiple="multiple" :name="name" class="d-none" autocomplete="off" :required="required">
            <option v-for="option in getValue" :value="option.id === 0 ? option.name : option.id" :key="option.id" selected>{{ option.name }}</option>
        </select>

        <vue-multiselect
            autocomplete="off"
            :options="allOptions"
            :value="value"
            :multiple="multiple"
            :searchable="true"
            :close-on-select="closeOnSelect"
            :clear-on-select="clearOnSelect"
            :hide-selected="hideSelected"
            :placeholder="placeholder"
            :taggable="taggable"
            @tag="addTag"
            select-label="Add"
            @select="addSelection"
            @remove="removeSelection"
            label="name"
            key="id"
            :show-labels="false"
            track-by="name"
            :option-height="40"
            option-partial="category-option"
        ></vue-multiselect>
    </div>
</template>

<script>
    import "vue-multiselect/dist/vue-multiselect.min.css";
    import VueMultiselect from 'vue-multiselect';

    export default {
        name: 'Multiselect',
        components: {
            VueMultiselect,
        },
        props: {
            name: {
                type: String,
            },
            placeholder: {
                type: String,
                default: 'Select option',
            },
            selected: {
                type: [Array, Object, String, Number],
                default: () => [],
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            hideSelected: {
                type: Boolean,
                default: true,
            },
            closeOnSelect: {
                type: Boolean,
                default: false,
            },
            clearOnSelect: {
                type: Boolean,
                default: () => false,
            },
            options: {
                type: [Array, Object],
                default: () => [],
            },
            taggable: {
                required: false,
                type: Boolean,
                default: false,
            },
            required: {
                type: [Boolean, String],
                default: false,
            },
            markChangedOnSelect: {
                type: String,
                default: ''
            },
            markChangedClassIdentifier: {
                type: String,
                default: 'changesmade'
            }
        },

        data() {
            return {
                value: [],
                allOptions: [],
            }
        },

        computed: {
            getValue() {
                if (!this.multiple && !Array.isArray(this.value)) {
                    this.value = [this.value];
                }

                return this.value;
            },
        },

        watch: {
            value(newValue, oldValue) {
                // This will force the v-model of the parent to update
                this.$emit('input', this.value);
            },
        },

        mounted() {
            this.name = this.name.replace(/^\s*|\s*$/, '');
            if (this.multiple && this.name.substr(-2) !== '[]') {
                console.warn(`You are using the mutliple attribute, but the element's name is not an array. Change the name to %c${this.name}[]%c for the expected behaviour.`, `font-style: italic; background-color: #eff0f1;`, `font-style: inherit; background-color: inherit;`);
            }

            this.value = this.selected;
            this.allOptions = this.options;
        },

        methods: {
            addSelection(option) {
                if (this.multiple && !~this.value.indexOf(option)) {
                    this.value.push(option);
                } else if (!this.multiple) {
                    this.value = option;
                }
                if(this.markChangedOnSelect) {
                    document.getElementById(this.markChangedOnSelect).classList.add(this.markChangedClassIdentifier);
                }
            },

            removeSelection(option) {
                if (this.multiple) {
                    let index = this.value.indexOf(option)

                    if (~index) {
                        this.value.splice(index, 1);
                    }
                }
            },
            addTag(newTag) {
                const tag = {
                    name: newTag,
                    id: 0,
                };
                this.allOptions.push(tag);

                if (this.multiple) {
                    this.value.push(tag);
                } else {
                    this.value = tag;
                }
            },
        },

        created() {
            this.required = !!this.required;
        },
    }
</script>

<style>
    .multiselect--active .multiselect__input {
        width: calc(100% - 40px) !important;
    }
</style>
