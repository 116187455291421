<script>
    export default {
	    el: '#msb',
	    data() {
		    return {
                question_id: null,
		    	toggleSwitchId:'#msbo',
            };
	    },
    	mounted(questionid) {

		    var ComponentData = this.data();

            (function(){

                let HasLocalStorage = ('localStorage' in window) && window['localStorage'] !== null;

                function setSidebarTriggerPref(hidden) {
                    if(HasLocalStorage) {
                        let val = hidden ? 1 : 0;
                        localStorage.setItem('hide_sidebar', val.toString());
                    }
                }

                function shouldHideSidebar() {
                    if(HasLocalStorage) {
                        return parseInt(localStorage.getItem('hide_sidebar'));
                    }
                    return 0;
                }

		    	// Add toggle action to sidebar menu
			    $(ComponentData.toggleSwitchId).on('click', function(e){
			        e.preventDefault();
				    $('body').toggleClass('msb-x');
				    setSidebarTriggerPref($('body').hasClass('msb-x'));
			    });

			    // See if we have closed the menu before, and keep it closed
                if(shouldHideSidebar()) {
                    $('body').addClass('msb-x');
                }

                function scrollParentToChild(parent, child) {

                    // Where is the parent on page
                    var parentRect = parent.getBoundingClientRect();
                    // What can you see?
                    var parentViewableArea = {
                        height: parent.clientHeight,
                        width: parent.clientWidth
                    };

                    // Where is the child
                    var childRect = child.getBoundingClientRect();
                    // Is the child viewable?
                    var isViewable = (childRect.top >= parentRect.top) && (childRect.bottom <= parentRect.top + parentViewableArea.height);

                    // if you can't see the child try to scroll parent
                    if (!isViewable) {
                        // Should we scroll using top or bottom? Find the smaller ABS adjustment
                        const scrollTop = childRect.top - parentRect.top;
                        const scrollBot = childRect.bottom - parentRect.bottom;

                        parent.scrollTop = scrollTop - scrollBot;

                    }

                }

                // Scroll question into view
                let whichQuestion = questionid || 0;
                if(whichQuestion) {

                    scrollParentToChild($("#msb")[0],$("[data-nav-for-question="+ whichQuestion +"]")[0]);

                }

		    }());
        }
    }
</script>
