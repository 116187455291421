var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    staticClass: "loading fas fa-2x",
    class: [_vm.faIcon, _vm.faAnimation],
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }