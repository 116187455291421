<script>
import RemoveUserButton from "./RemoveUserButton.vue";

export default  {
    name: 'BusinessUsersList',
    components: {
      RemoveUserButton
    },
    props: {
        business: {'type': [Object], 'default': {},},
        row_data: {'type': [Object,Array], 'default': {},},
        current_user: 0
    },
    data() {
        return {
            rows: [],
            show: false
        }
    },
    mounted() {

        // This is just a natty little way of animating the list in. Only took a couple of hours...
        const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

        const repeatedGreetings = async (row) => {


            for(let rowindex in this.row_data) {
                let row = this.row_data[rowindex];
                this.rows.push(row);
                await sleep(150);
            }
        }
        repeatedGreetings();

    },
    methods: {
        removeUserRow(options) {

            this.rows = _.remove(this.rows, function (n) {
                return n.id != options.user_id;
            });

        }
    }
}
</script>

<template>
    <div class="business-users-list">
        <table class="table table-sm table-striped">
            <thead>
            <tr>
                <th>Name</th>
                <th>Email</th>
                <th>&nbsp;</th>
            </tr>
            </thead>

            <transition-group
                name="fade"
                tag="tbody"
                appear
            >
                    <tr class="business-user-list-item" v-for="(row, index) in rows" :key="row.id" :data-index="index">
                        <td>{{ row.name }} <span v-if="row.is_current">(You)</span></td>
                        <td>{{ row.email }}</td>
                        <td>
                            <remove-user-button
                                v-if="!row.is_current"
                                :user_id="row.id"
                                :business_id="business.id"
                                :post_url="row.remove_url"
                                v-on:user_removed_from_grid="removeUserRow($event)"
                            ></remove-user-button>
                        </td>
                    </tr>
            </transition-group>

        </table>
    </div>
</template>

<style>
.business-user-list-item {
    backface-visibility: hidden;
    transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
    transform: scaleY(1);
}
.business-user-list-item > div:last-of-type {
    margin-left: auto;
}
</style>
