var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("i", {
    directives: [
      {
        name: "tooltip",
        rawName: "v-tooltip.bottom",
        value: _vm.message,
        expression: "message",
        modifiers: { bottom: true },
      },
    ],
    staticClass: "fad fa-info-circle",
    staticStyle: { position: "relative", display: "inline-block" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }