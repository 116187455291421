import { render, staticRenderFns } from "./FootprintReportDetail.vue?vue&type=template&id=8ad310c0&scoped=true"
import script from "./FootprintReportDetail.vue?vue&type=script&lang=js"
export * from "./FootprintReportDetail.vue?vue&type=script&lang=js"
import style0 from "./FootprintReportDetail.vue?vue&type=style&index=0&id=8ad310c0&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ad310c0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/vhosts/admin.eqm.org.uk/admin.eqm.org.uk/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8ad310c0')) {
      api.createRecord('8ad310c0', component.options)
    } else {
      api.reload('8ad310c0', component.options)
    }
    module.hot.accept("./FootprintReportDetail.vue?vue&type=template&id=8ad310c0&scoped=true", function () {
      api.rerender('8ad310c0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/report/FootprintReportDetail.vue"
export default component.exports