var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "question-nav-item nav-link",
      class: _vm.classes,
      attrs: { "data-nav-for-question": _vm.question_id },
    },
    [
      _c(
        "a",
        {
          staticClass: "a-button",
          class: { disabled: _vm.disabled },
          attrs: { disabled: _vm.disabled, href: _vm.url },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.navigate.apply(null, arguments)
            },
          },
        },
        [
          _c("span", { staticClass: "align-self-start question-number" }, [
            _vm.iconclass
              ? _c(
                  "span",
                  { staticClass: "question-number-text question-number-icon" },
                  [_c("i", { class: _vm.iconclass })]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "question-text" }, [
            _vm._v(_vm._s(_vm.text)),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "align-self-start question-status" }, [
            _c("i", { class: _vm.answered_icon_class }),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }