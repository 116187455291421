<template>
    <div id="applications_table">
        <div class="card">
            <div class="card-body">
                <div class="row mb-4">
                    <div class="col-lg-6">
                        <multiselect
                            v-model="selectedBusiness"

                            :options="businesses"
                            label="name"
                            track-by="id"
                            :searchable="true"
                            :loading="isLoading"
                            :clear-on-select="false"
                            @search-change="asyncFind"
                            placeholder="Search for a business"
                            no-options="Start typing a business name"
                            @select="setSelectedBusinessId"
                            noOptions="Start typing a business name"
                        >
                            <template slot="noOptions">
                                <span>Start typing a business name</span>
                            </template>
                        </multiselect>
                    </div>
                    <div class="col-lg-4 form-inline">
                        <span class="col-form-label mr-2">Date range</span>
                        <date-range-picker
                            ref="picker"

                            :locale-data="{ firstDay: 1, format: 'dd/mm/yyyy' }"

                            :timePicker="false"
                            :showDropdowns="true"
                            v-model="dateRange"

                            :linkedCalendars="true"
                            @update="applyDateRange"

                        >
                            <template v-slot:input="picker" style="min-width: 350px;">
                                {{ picker.startDate | date }} - {{ picker.endDate | date }}
                            </template>
                        </date-range-picker>
                    </div>
                    <div class="col-lg-2">
                        <button class="btn btn-eqm-green btn-sm float-right mt-1" @click.prevent="download"><i class="fad fa-file-excel"></i> Download</button>
                    </div>
                </div>

                <b-row>
                    <b-col lg="6">
                        <b-pagination
                            v-model="currentPage"
                            :per-page="perPage"
                            :total-rows="totalRows"
                            aria-controls="applications_table"
                            first-number
                            last-number
                        >
                            <template #first-text><i class="fad fa-chevron-double-left"></i></template>
                            <template #prev-text><i class="fas fa-chevron-left"></i></template>
                            <template #next-text><i class="fas fa-chevron-right"></i></template>
                            <template #last-text><i class="fad fa-chevron-double-right"></i></template>
                        </b-pagination>
                    </b-col>
                    <b-col sm="5" md="6" class="justify-content-end d-flex">

                            <!--label="Per page"
                            label-for="per-page-select"
                            label-cols-sm="6"
                            label-cols-md="4"
                            label-cols-lg="3"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-0 mt-1 justify-content-end"-->

                            <label for="per-page-select" class="col-form-label col-form-label-sm text-sm-right mr-3 mt-1">Per page</label>

                            <div>
                                <b-form-select
                                    id="per-page-select"
                                    v-model="perPage"
                                    :options="pageOptions"
                                    class="custom-select-sm mt-1"
                                ></b-form-select>
                            </div>

                    </b-col>
                </b-row>
            </div>
        </div>

<div class="position-relative">
        <b-table striped hover outlined
                 ref="applicationsData"
                 sort-icon-left
                 :items="recordProvider"
                 :fields="fields"
                 :per-page="perPage"
                 :current-page="currentPage"
                 :filter="filter"
                 filter-debounce="120"
                 @filtered="onFiltered"
                 :no-provider-sorting="true"
                 :no-provider-filtering="true"
        >


            <template #cell(status)="data">
                <span v-html="data.value"></span>
            </template>

            <template  #cell(actions)="data">

                    <b-button class="d-block btn btn-light" v-b-popover:applications_table.hover.top="'View report'" @click="openFootprintDetail(data)"><i class="fad fa-chart-pie"></i></b-button>
            </template>

        </b-table>
    <div v-if="isLoading" class="position-absolute table-loading-mask">
    <loading></loading>
    </div>

</div>
        <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalRows"
            aria-controls="applications_table"
            first-number
            last-number
        >
            <template #first-text><i class="fad fa-chevron-double-left"></i></template>
            <template #prev-text><i class="fas fa-chevron-left"></i></template>
            <template #next-text><i class="fas fa-chevron-right"></i></template>
            <template #last-text><i class="fad fa-chevron-double-right"></i></template>
        </b-pagination>

        <footprint-report-detail
            v-if="footprint !== null && !footprint.is_legacy"
            :footprint_source="footprint"
            faqurl="faqurl"
            @footprint-detail-modal-close="closeFootprintDetail"
        ></footprint-report-detail>
    </div>
</template>
<script>
    import moment from 'moment';
    import "vue-multiselect/dist/vue-multiselect.min.css";
    import Multiselect from 'vue-multiselect'
    import DateRangePicker from 'vue2-daterange-picker'
        import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    import FootprintReportDetail from "../report/FootprintReportDetail.vue";
    import Loading from "../LoadingComponent.vue";
    import _ from 'lodash';
    import ToastMessageComponent from "../ToastMessageComponent.vue";


    export default {
        name: 'SubmissionsGrid',
        components: {
            Multiselect,
            DateRangePicker,
            FootprintReportDetail,
            Loading,
            ToastMessageComponent
        },
        props: {
            src: { type: String, default: '', },
            initbusinesses: { type: [Array, Object], default: () => [], },
            download_url: { type: String, default: '', },
            reporturl: { type: String },
            faqurl: {type: String},
        },

        data() {
            return {
                footprint: null,
                allfootprints: [],
                perPage: 15,
                pageOptions: [15, 50, { value: 1000, text: "Loads" }],
                totalRows: 1,
                currentPage: 1,
                filter: null,
                dateRange: {startDate: '', endDate: ''},
                fields: [
                    {
                      key: 'status',
                      label: 'Status',
                      formatter: function(value, key, item) {
                          switch(item.application_status_id) {
                              case 3:
                                  return `<span class="badge badge-eqm-green">Finished</span>`;
                              case 2:
                              case 1:
                                  return `<span class="badge badge-light">Started</span>`;
                              default:
                                  return '';
                          }


                      },
                        sortable: false,
                    },
                    {
                        key: 'application.business.name',
                        label: 'Business',
                        sortable: true,
                        stickyColumn: true,
                    },
                    {
                        key: 'carbon_footprint.start_date',
                        label: 'Reporting period',
                        sortable: true,
                        formatter: function(value, key, item) {
                            if(!item.start_date) {
                                return '';
                            }
                            let start = moment(item.start_date),
                                end = moment(item.end_date),
                                startStr = start.format('MM/YYYY'),
                                endStr = end.format('MM/YYYY');

                            return `${startStr} - ${endStr}`

                        }
                    },
                    {
                        key: 'updated_at',
                        label: 'Last updated',
                        sortable: true,
                        formatter: function(value, key, item) {
                            if(!value) {
                                return '';
                            }
                            let start = moment(value);

                            return start.format('DD/MM/YYYY')

                        }
                    },
                    {
                        key: 'actions',
                        label: 'Actions',
                        formatter: function(value,key,item) {
                            let bus_id = item.business_id || 0,
                                app_id = item.application_id || 0;
                            if(!bus_id || !app_id) {
                                return '';
                            }
                            let url = item.view_url.replace('--business_id--',bus_id);
                            url = url.replace('--application_id--',app_id);

                            if(item.application_status_id !== 3) {
                                return '';
                            }

                            return `<a href="${url}" class="btn btn-sm btn-light" target="_blank"><i class="fas fa-chart-pie"></i> View report</a>`
                        }
                    }
                ],
                activeRecords: [],
                selectedBusinessId: 0,
                selectedBusiness: [],
                businesses: [],
                isLoading: false,
            }
        },
        filters: {
            date (val) {
                if(val) {
                    let dt = moment(val);
                    return dt.format('DD/MM/YYYY')
                }
                return val ? val.toLocaleString() : ''
            }
        },
        computed: {

        },

        methods:{

            openFootprintDetail(itemdata) {
                this.getResults(itemdata.item.carbon_footprint_id);
            },

            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },

            /**
             * Lists the applications, applying any filters
             *
             * @param ctx
             * @returns {Promise<AxiosResponse<any>>}
             */
            recordProvider(ctx) {

                const promise = axios.get(this.src
                    + '?page=' + ctx.currentPage
                    + '&perPage=' + ctx.perPage
                    + '&business=' + this.selectedBusinessId
                    + '&start=' + (this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : '')
                    + '&end=' + (this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') : '')
                    || '')

                // Must return a promise that resolves to an array of items
                return promise.then(response => {
                    // Pluck the array of items off our axios response
                    const items = response.data.data;
                    this.totalRows = response.data.total;
                    this.currentPage = response.data.current_page;
                    // Must return an array of items or an empty array if an error occurred
                    this.activeRecords = items || []
                    return items || []
                })
            },

            /**
             * Searches for businesses in the business filter multiselect
             * @param query
             */
            asyncFind (query) {
                this.isLoading = true;

                axios.get('/api/businesses/quick/search',{
                        params: {
                            search: query
                        }
                    })
                    .then((response) => {

                        this.businesses = response.data.data;
                        this.isLoading = false;
                    });
            },
            clearAll () {
                this.selectedBusiness = [];
            },
            setSelectedBusinessId(business) {
                this.selectedBusinessId = business.id;
                this.$refs.applicationsData.refresh();
            },

            /**
             * Calls a controller method to generate a csv/xls export
             */
            download() {

                let fd = new FormData();
                this.activeRecords.forEach((item) => {
                    fd.append('footprints[]', item.carbon_footprint_id);
                });

                axios.post(this.download_url,fd,{
                    responseType: 'blob',
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    let now = moment(), nowStr = now.format('YYYY-MM-DD-HH-MM'), filename = `applications_export_${nowStr}.xlsx`;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                });
            },

            /**
             * When selecting a date range, trigger a reload of the apps list
             * @param dateRange {startDate,endDate}
             */
            applyDateRange(dateRange) {
                if(!dateRange.startDate || !dateRange.endDate) {
                    return true;
                }
                this.$refs.applicationsData.refresh()
            },

            closeFootprintDetail() {
                this.isLoading = false;
                this.footprint = null;
            },

            loadResults: function (footprint_id) {
                this.isLoading = true;
                this.footprint = null;
                axios.get(this.reporturl, {
                    params: {
                        footprint: footprint_id
                    }
                })
                    .then((response) => {

                        if(response.status === 200 && !response.data.has_error) {
                            this.footprint = response.data;
                        }
                        else {
                            this.$toasted.show(response.data.msg || 'An error occurred', {
                                position: "top-center",
                                duration : 5000,
                                type: 'error',
                                keepOnHover: true,
                                action : {
                                    text : 'Close',
                                    onClick : (e, toastObject) => {
                                        toastObject.goAway(0);
                                        this.$toasted.clear();
                                    }
                                }
                            });
                        }
                        this.isLoading = false;

                        //this.$forceUpdate();
                    });

            },
        },
        created: function(){
            this.businesses = this.initbusinesses;
            // Register the function for loading results
            this.getResults = _.debounce(this.loadResults, 500)
        }
    }
</script>
<style>
    .daterangepicker .drp-buttons .btn {
        font-size: 14px;
        font-weight: normal;
    }

    .table-loading-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        text-align: center;
        align-content: center;
        justify-content: center;
        padding-top: 40px;
        top: 0;
        left: 0;
        background: rgba(183,182,182,0.54);
    }

    .carbon-intensity-factors {
        margin-bottom: 2.2rem;
    }

    @media screen and (min-width: 980px) {
        .carbon-intensity-factors {
            margin-top: 2.5rem;
            margin-bottom: 3.5rem;
        }
    }

    .carbon-intensity-factor {
        border: 3px solid #fff;
        border-radius: 8px;
        padding: .9rem;
        margin-bottom: 30px;
    }

    .carbon-intensity-factor-title {
        color: #fff;

    }

    .apexcharts-legend-text {
        /*color: #fff !important;*/
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
        font-size: 16px !important;
    }

    .apexcharts-pie {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    }

    #chart {
        .apexcharts-legend-text {
            color: #ffffff !important;
        }
    }

    .apexcharts-canvas {
        margin: 0 auto;
    }
</style>
