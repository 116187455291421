var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "btn btn-link",
      class: _vm.css_class,
      attrs: { href: _vm.url },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.confirmExit.apply(null, arguments)
        },
      },
    },
    [_vm._v("Exit form")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }