<template>
    <div>
        <input type="hidden" name="netzero_target_pcnt" id="netzero_target_pcnt" v-model="netzero_target_pcnt">
        <input
            type="hidden"
            min="min_target_year"
            step="1"
            name="netzero_target_year"
            v-model="netzero_target_year"
        />
        <div class="row">
            <div class="col-12">
                <net-zero-target-chart
                    :data="chart_data"
                    :years="year_labels"
                    :color_scheme="color_scheme"
                    ref="netzeroLineChart"
                    chart_url=""
                    :threshold="threshold/1000"
                ></net-zero-target-chart>
            </div>
        </div>

        <div class="row py-4">
            <div class="col-11">
                <p class="text-white">The chart uses your most recent carbon audit footprint as a starting point. It shows what your estimated path to net zero looks like with the target percentage and/or year that you have selected.</p>
            </div>
        </div>

        <div class="row  my-4">
            <div class="card bg-white py-4">
            <div class="col-11  text-center">
                <p class="lead ">With a reduction rate of <strong class="font-weight-bold text-lg">{{netzero_target_pcnt}}%</strong> of your footprint from {{start_year}} (equivalent to <strong class="font-weight-bold">{{netzero_target_tonnes}} {{netzero_target_unit}}</strong> CO2E in the first year) - you would achieve net zero by <strong class="font-weight-bold text-lg">{{thresholdYear}}</strong>.</p>
            </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-12 col-lg-10 text-white">
                <button class="btn btn-light ml-0 mt-0 " style="margin-left: -10px !important;" type="button" data-toggle="collapse" data-target="#input_option_description_full" aria-expanded="false" aria-controls="input_option_description_full">
                    <i class="fa fa-chevron-circle-right fal"></i>  Give me a bit more detail
                </button>
                <div id="input_option_description_full" class="collapse mt-4">
                    <p>On the chart you will see a dotted horizontal line. This represents the threshold at which you can officially claim to have achieved net zero. It isn't actually zero - it's 10% of you reference year's total emissions.</p>
                    <p>You can set you target reduction percentage, or the year by which you wish to achieve net zero on your Carbon Footprint Overview page (check out your dashboard to find this).</p>
                    <h5>This is an illustration only!</h5>
                    <p>Obviously it is unlikely that you will achieve a steady percentage reduction year-on-year! This chart is about getting some context of the scale of what needs to be done.</p>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
    import NetZeroTargetChart from './NetZeroTargetChart';
    import VueSlider from 'vue-slider-component'
    import Loading from "../LoadingComponent.vue";
    /*import 'vue-slider-component/theme/material.css'*/
    import axios from 'axios';
    // Initialise axios
    axios.defaults.headers.common = {
        'X-CSRF-TOKEN': window.Laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    export default {
        name: 'NetZeroTargetsReadonly',
        props: {
            data: { type: [String,Array,Object], default: () => {}, },
            dataset_labels: { type: [String,Array,Object], default: () => [], },
            color_scheme: { type: [String,Array,Object], default: () => [], },
            initial_pcnt: {type: Number, default: 3},
            initial_year: {type: Number, default: 2050},
            min_target_year: {type: Number, default: 2019},
            start_year: {type: Number, default: 2000},
            total: {type: Number, default: 0},
            threshold: {type: Number, default: 0},
            business: {type: Number, default: 0},
        },
        data() {
          return {
              targetsCanBeSaved: false,
              isLoading: false,
              netzero_target_pcnt: 3.0,
              netzero_target_year: 2050,
              year_labels: [],
              chart_data: [{
                  name: 'Projection',
                  data: []
              }],
              thresholdYear: 0,
              thresholdYearActual: 0,
              max_pcnt: 20,
              default_max_pcnt: 20,
              business_id: 0,
          }
        },
        methods: {
            updateData() {
                this.targetsCanBeSaved = true;
                this.calculateDataset(this.start_year,this.total,this.netzero_target_year,this.netzero_target_pcnt);
                this.$refs.netzeroLineChart.update(this.chart_data,this.year_labels);
                //this.saveTargetsToDatabase();
            },
            updateYearData() {
                this.targetsCanBeSaved = true;
                this.calculateDatasetToYear(this.start_year,this.total,this.netzero_target_year);
                this.$refs.netzeroLineChart.update(this.chart_data,this.year_labels);
                //this.saveTargetsToDatabase();
            },

            saveTargetsToDatabase() {
                this.isLoading = true;

                axios.put(`/profile/businesses/${this.business_id}/carbonactions/updatetargets`,{

                            pcnt: this.netzero_target_pcnt,
                            year: this.netzero_target_year

                    })
                    .then((response) => {

                        if(response && response.data.success) {
                            this.targetsCanBeSaved = false;
                        }
                        this.isLoading = false;
                    });
            },

            /**
             *
             * This is a funky little number. Given a target year in the future, it calculates what annual percentage
             * reduction is required to get from the initial total to beneath the net zero threshold.
             * You may just have to take the maths for granted.
             *
             * @param startYear The year of the footprint audit
             * @param startTotal The total emissions in the first year (the start
             * @param targetYear
             * @returns {null}
             */
            calculateDatasetToYear(startYear,startTotal,targetYear = startYear) {

                let threshold = (startTotal * 0.1),
                    nYears = targetYear - startYear,
                    resultantTotal = startTotal,
                    approxPcnt = 0;

                if(nYears < 1) {
                    return null;
                }


                for(let tryPcnt = 1; tryPcnt <= 90; tryPcnt ++) {
                    let multiplier = 1 - (tryPcnt/100);
                    resultantTotal = Math.pow(multiplier,nYears) * startTotal;
                    if(resultantTotal <= threshold) {
                        approxPcnt = tryPcnt;
                        break;
                    }
                }


                if(approxPcnt) {

                    this.max_pcnt = this.default_max_pcnt;
                    if(approxPcnt > this.max_pcnt) {
                        let newMax = approxPcnt + 10;
                        if(newMax % 2 !== 0) {
                            newMax++;
                        }
                        this.max_pcnt = newMax;
                    }

                    this.netzero_target_pcnt = approxPcnt;
                    this.calculateDataset(startYear,startTotal,targetYear,approxPcnt);
                }

            },
            calculateDataset(startYear,startTotal,targetYear, targetPcnt) {

                let yearValues = [], threshold = 0, calcYear;

                // This year's value is the total emissions
                yearValues[startYear] = startTotal;

                // Threshold for net zero
                threshold = (startTotal * 0.1) / 1000;

                calcYear = startYear;
                while(calcYear < targetYear) {
                    calcYear++;
                    let currentTotal = yearValues[calcYear - 1];
                    yearValues[calcYear] = currentTotal * ( (100 - targetPcnt) / 100 );
                }

                let valuesArr = [], labelsArr = [], thresholdLineValues = [], thresholdYear = false, thresholdYearActual = 0;

                yearValues.forEach(function(value, index) {
                    value = value/1000;
                    if(value <= threshold && thresholdYear === false) {
                        thresholdYear = index;
                        thresholdYearActual = index;
                    }

                    if(value < 1) {
                        valuesArr.push(parseFloat(value.toFixed(8)));
                    }
                    else {
                        valuesArr.push(parseFloat(value.toFixed(1)));
                    }

                    labelsArr.push(index);
                    thresholdLineValues.push(threshold);
                });
                this.thresholdYearActual = thresholdYearActual;
                if(thresholdYear === false) {
                    thresholdYear = "after "+targetYear;
                    this.thresholdYearActual = targetYear;
                }
                this.thresholdYear = thresholdYear;
                this.chart_data[0].data = valuesArr;
                //this.chart_data[1].data = thresholdLineValues;
                this.year_labels = labelsArr;
            }
        },
        computed: {
            netzero_year() {
                return 2001;
            },
            netzero_target_tonnes() {

                let emissionsInKg = ( this.total * (this.netzero_target_pcnt / 100) ),
                    emissionsInTonnes = emissionsInKg/1000;

                return emissionsInKg < 100 ? emissionsInKg.toFixed(1) : emissionsInTonnes.toFixed(2);
            },
            netzero_target_unit() {
                let emissionsInKg = ( this.total * (this.netzero_target_pcnt / 100) );
                if(emissionsInKg < 100) {
                    return 'Kg';
                }
                return 'Tonnes';
            }
        },
        components: {
            NetZeroTargetChart,
            VueSlider,
            Loading
        },
        mounted() {
            this.business_id = this.business;
            this.netzero_target_pcnt = this.initial_pcnt;
            this.netzero_target_year = this.initial_year;
            this.calculateDataset(this.start_year,this.total,this.netzero_target_year,this.netzero_target_pcnt);

            this.$refs.netzeroLineChart.update(this.chart_data,this.year_labels);
        }
    }
</script>
