import number_format from 'locutus/php/strings/number_format';

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isInteger#Polyfill
Number.isInteger = Number.isInteger || function (value) {
    return typeof value === 'number' &&
        isFinite(value) &&
        Math.floor(value) === value;
};

const isInt = (int) => {
    return Number.isInteger(int) || int == ~~int;
};

const isInteger = (int) => {
    return isInt(int);
};

const is_numeric = (number) => {
    if (isNaN(number)
        || number == null
        || number === true
        || number === false
        || typeof number === 'undefined'
        || typeof number === 'object'
        || Array.isArray(number)
    ) {
        return false;
    }

    return true;
}

const toFloat = (number) => {
    let num = number.toString().replace(/\s+|,|\-/g, '');

    return isNaN(Number(num)) ? 0 : Number(num);
}

/**
 * Take a variable and convert it into a Number.
 * @param  {mixed}  number   Anything really. Well, anything scalar...
 * @param  {Number} decimals How many decimal places do you want?
 * @return {Number}          This will convert something into a Number. This
 *                           means we can use this and then perform addition
 *                           and not worry about it concatenating our string!
 */
const number = (number, decimals = 0) => {
    return Number(
        Number(number).toFixed(decimals)
    );
}

const formatFloat = (float, decimals = 2) => {
    return number_format(
        Number(
            parseFloat(Number(float))
                .toFixed(decimals)
        ) || 0
    );
};

export {
    isInt,
    isInteger,
    is_numeric,
    toFloat,
    number,
    formatFloat,
}
