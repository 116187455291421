<template>
    <div>
        <div v-for="(input, index) in inputs" :key="index">
            <slot name="before"></slot>

            <div class="input-group mb-1">
                <div class="input-group-prepend">
                    <span v-if="prepend[0] === true" class="input-group-text" v-html="prepend[index + 1]"></span>
                    <span v-else v-for="(text, key, index) in prepend" class="input-group-text" :key="index" v-html="text"></span>
                </div><!-- .input-group-prepend -->

                <ResizeAuto>
                    <template v-slot:default="{resize}">
                                <textarea
                                    :name="input.name"
                                    :placeholder="placeholder"
                                    class="form-control"
                                    v-model="input.value"
                                    @input="resize"
                                    rows="1"
                                ></textarea>
                    </template>
                </ResizeAuto>

                <div class="input-group-append append-input-suffix" :class="{ 'not-last' : index < (inputs.length - 1) }">
                    <span v-if="append[0] === true" class="input-group-text" v-html="append[index + 1]"></span>
                    <span v-else v-for="(text, key, index) in append" class="input-group-text" :key="index" v-html="text"></span>

                    <span class="input-group-text pointer" @click="addRow()">+</span>
                    <span class="input-group-text pointer" v-if="index > (min || 1) - 1" @click="deleteRow(index)">-</span>
                </div><!-- .input-group-append -->
            </div><!-- .input-group -->

            <slot name="after"></slot>
        </div>
    </div>
</template>

<script>
    import ResizeAuto from "../components/ResizeAuto";
    export default {
        name: 'AppendTextareaComponent',
        components: {
            ResizeAuto
        },
        props: {
            'name': {type: String, default: 'name[]', },
            'value': { type: [Array, Object], default: () => [], },
            'placeholder': { type: String, },
            'min': { type: Number, },
            'max': { type: Number, },

            // Setting the first element of prepend / append to === true will
            // mean that the array will be spread across each row rather than
            // each row containing _every_ element of the array.
            'prepend': { type: [String, Array], default: () => [], },
            'append': { type: [String, Array], default: () => [], },
        },
        data: function () {
            return {
                inputs: []
            }
        },

        methods: {
            addRow() {
                if (typeof this.max === 'undefined' || this.inputs.length < this.max) {
                    this.inputs.push({
                        name: this.name,
                        value: '',
                    });
                }
            },
            deleteRow(index) {
                if ((this.inputs[index].value.length > 0 && confirm('Are you sure you want to delete this row and lose your data?')) || this.inputs[index].value.length === 0) {
                    this.inputs.splice(index, 1);
                }
            },
        },

        beforeMount() {
            if ((this.name || '').substr(-2) !== '[]') {
                console.warn('Textarea name is not an array. This can likely overwrite data.');
            }

            // Set the value of the textareas.
            // We have to pass an array rather than use slots because we are
            // dynamically creating each row, and that means that the slot gets
            // confused as to what we are passing in.
            if (this.value !== undefined) {
                let i = 0;
                for (let item in this.value) {
                    // Don't let fake rows get inserted
                    if (this.value[item] === null || typeof this.value[item] === 'undefined') {
                        continue;
                    }

                    if (typeof this.inputs[i] === 'undefined' && (this.inputs.length < ~~this.max || typeof this.max === 'undefined')) {
                        this.inputs.push({
                            name: this.name.replace('[]', `[${item}]`),
                            value: '',
                        });
                    }

                    this.inputs[i].value = this.value[item];
                    i++;
                }
            }

            // Determine how many elements we are going to have
            let i = this.inputs.length || 0;
            do {
                // The only time we don't want anymore rows is if we are going
                // to end up going over the limit.
                if (this.max !== undefined && this.inputs.length == ~~this.max) {
                    break;
                }

                this.inputs.push({
                    name: this.name,
                    value: '',
                });

                i++;
            } while (i < ~~this.min);

            // Convert prepend and append into arrays
            // Note: If you want to use an array in your view then you must use:
            // v-bind:prepend="['a', 'b']"
            // That is, you must use v-bind, otherwise Vue will treat the
            // parameter as a string and not an array.
            if (this.prepend !== undefined && typeof this.prepend === 'string') {
                this.prepend = [this.prepend];
            }
            if (this.append !== undefined && typeof this.append === 'string') {
                this.append = [this.append];
            }


            this[this.name + '_toggle'] = this.inputs[0]['value'] != '' ? true : false;
        },

    }
</script>

<style scoped>
    .pointer {
        cursor: pointer;
    }
</style>
