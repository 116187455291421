var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "select",
        {
          staticClass: "d-none",
          attrs: {
            multiple: _vm.multiple,
            name: _vm.name,
            autocomplete: "off",
            required: _vm.required,
          },
        },
        _vm._l(_vm.getValue, function (option) {
          return _c(
            "option",
            {
              key: option.id,
              attrs: { selected: "" },
              domProps: { value: option.id === 0 ? option.name : option.id },
            },
            [_vm._v(_vm._s(option.name))]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("vue-multiselect", {
        key: "id",
        attrs: {
          autocomplete: "off",
          options: _vm.allOptions,
          value: _vm.value,
          multiple: _vm.multiple,
          searchable: true,
          "close-on-select": _vm.closeOnSelect,
          "clear-on-select": _vm.clearOnSelect,
          "hide-selected": _vm.hideSelected,
          placeholder: _vm.placeholder,
          taggable: _vm.taggable,
          "select-label": "Add",
          label: "name",
          "show-labels": false,
          "track-by": "name",
          "option-height": 40,
          "option-partial": "category-option",
        },
        on: {
          tag: _vm.addTag,
          select: _vm.addSelection,
          remove: _vm.removeSelection,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }