<template>
    <div id="netzerochart">

    </div>
</template>
<script>
    //import Chart from 'chart.js/auto';
    import VueApexCharts from 'vue-apexcharts';
    import axios from 'axios';

    export default {
        name: 'NetZeroTargetChart',
        props: {
            data: { type: [String,Array,Object], default: () => {}, },
            years: { type: [String,Array,Object], default: () => {}, },
            threshold: { type: Number, default: 0, },
            color_scheme: { type: [String,Array,Object], default: () => [], },
            chart_id: {type: String, default: 'netZeroTargetChart' },
            chart_url: {type: String, default: '' },
        },
        data() {
            return {
                chart: null,
                /*subtle_colour: '#fdaa5a',
                * ,{
                    name: "Net Zero threshold",
                    data: [0]
                }
                * */
                subtle_colour: '#7aa94b',

                chart_data: [{
                    name: "Loading...",
                    data: [0]
                }],
                chart_year_labels: [2020]
            }
        },
        components: {
            apexchart: VueApexCharts,
        },
        methods: {
            update(dataset, year_labels) {
                this.chart_data = dataset;
                this.chart_year_labels = year_labels;
                this.render();
            },
            unrender() {

            },
            render() {

                this.chart.updateOptions({
                    xaxis: {
                        categories: this.chart_year_labels,
                        labels: {
                            style: {
                                fontSize: this.labelFontSize(),
                            },
                        },
                    }
                });
                this.chart.updateSeries(this.chart_data);
                if(window.downloadCharts) {
                    let Api = this;
                    setTimeout(() => {
                        Api.saveChartToFile();
                    }, 2000);
                }

            },

            labelFontSize() {
                return this.chart_year_labels && this.chart_year_labels.length > 20 ? '12px' : '15px';
            },

            initChart() {

                this.chart = new ApexCharts(this.$el, {
                    chart: {
                        id: this.chart_id,
                        height: 350,
                        type: 'line',
                        dropShadow: {
                            enabled: false,
                            color: '#000',
                            top: 18,
                            left: 7,
                            blur: 10,
                            opacity: 0.2
                        },
                        toolbar: {
                            show: false
                        }
                    },
                    /*colors: this.color_scheme,*/
                    colors: ['#ffffff','#ffffff'],
                    dataLabels: {
                        enabled: false,
                        enabledOnSeries: [0]
                    },
                    series: this.chart_data,
                    stroke: {
                        width: [8,2],
                        /*curve: ['smooth', 'straight']*/
                        curve: 'straight',
                        dashArray: [0,5],
                        lineCap: 'round'
                    },
                    title: {
                        enabled: false
                    },
                    grid: {
                        borderColor: this.subtle_colour,
                        row: {
                            colors: [this.subtle_colour, 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0
                        },
                    },
                    markers: {
                        size: [1,0]
                    },
                    xaxis: {
                        categories: this.chart_year_labels,
                        title: {
                            enabled: false
                        },
                        labels: {
                            rotate: 1,
                            hideOverlappingLabels: false,
                            style: {
                                fontSize: '17px',
                                fontFamily: 'Nunito, Helvetica, Arial, sans-serif',
                                colors: '#ffffff'
                            },
                        },
                        axisBorder: {
                            color: this.subtle_colour,
                        },
                        axisTicks: {
                            color: this.subtle_colour,
                        },
                        tickAmount: 10,
                    },
                    yaxis: {
                        title: {
                          enabled: true,
                          text: 'Tonnes CO2E',
                            style: {
                                fontSize: '16px',
                                fontFamily: 'Nunito, Helvetica, Arial, sans-serif',
                                fontWeight: 'normal',
                                color: '#ffffff'
                            },
                        },
                        labels: {
                            formatter: function (value) {
                                if(value < 5) {
                                    return value.toFixed(1);
                                }
                                return Math.round(value);
                            },
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Nunito, Helvetica, Arial, sans-serif',
                                colors: '#ffffff'
                            },
                        },

                    },
                    legend: {
                        show: false,
                        labels: {
                            colors: ['#ffffff']
                        },
                        onItemClick: {
                            toggleDataSeries: false
                        },
                    },
                    annotations: {
                        position: 'front',
                        yaxis: [
                            {
                                y: this.threshold,
                                borderColor: '#ffffffd9',
                                borderWidth: 2,
                                opacity: 0.3,
                                strokeDashArray: 5,
                                label: {
                                    position: 'left',
                                    textAnchor: 'start',
                                    offsetX: 30,
                                    offsetY: -7,
                                    borderColor: '#7aa94b',
                                    style: {
                                        color: '#ffffff',
                                        background: '#7aa94b',
                                        fontSize: '12px',
                                        fontFamily: 'Nunito, Helvetica, Arial, sans-serif',
                                    },
                                    text: `Net Zero threshold of ${Math.round(this.threshold)} tonnes * `
                                }
                            }
                        ]
                    }
                });

                this.chart.render();
            },

            saveChartToFile() {
                let chart_upload_url = this.chart_url;

                this.$apexcharts.exec(this.chart_id,'dataURI').then(({ imgURI, blob }) => {
                    if (blob) {
                        //navigator.msSaveOrOpenBlob(blob, this.w.globals.chartID + '.png')
                    } else {

                        function dataURLtoBlob(dataurl) {
                            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
                            while(n--){
                                u8arr[n] = bstr.charCodeAt(n);
                            }
                            return new Blob([u8arr], {type:mime});
                        }


//var dataurl = canvas.toDataURL('image/jpeg',0.8); //canvas to jpg dataurl
                        let blob = dataURLtoBlob(imgURI);

                        let filename = this.chart_id || "Chart" + Date.now();
                        filename += ".png";

                        var fd = new FormData();
                        fd.append("image", blob, filename);

                        axios.post(chart_upload_url, fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                            }
                        }).then(response => {

                        });

                    }
                });

            },
        },
        mounted() {
            this.chart_data = this.data;
            this.chart_year_labels = this.year_labels;
            this.initChart();

        }
    }
</script>
