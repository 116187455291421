<template>
    <i class="fad fa-info-circle"
       style="position: relative; display: inline-block;"
       v-tooltip.bottom="message"></i>
</template>

<script>
import Vue from 'vue';
import VTooltip from "v-tooltip";
Vue.use(VTooltip);
export default {
    name: "TooltipComponent",
    props: {
        message: {type: String, default: 'FYI', },
    }
}
</script>

<style scoped>

</style>
