var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "applications_table" } },
    [
      _c("div", { staticClass: "card" }, [
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c("div", { staticClass: "row mb-4" }, [
              _c(
                "div",
                { staticClass: "col-lg-6" },
                [
                  _c(
                    "multiselect",
                    {
                      attrs: {
                        options: _vm.businesses,
                        label: "name",
                        "track-by": "id",
                        searchable: true,
                        loading: _vm.isLoading,
                        "clear-on-select": false,
                        placeholder: "Search for a business",
                        "no-options": "Start typing a business name",
                        noOptions: "Start typing a business name",
                      },
                      on: {
                        "search-change": _vm.asyncFind,
                        select: _vm.setSelectedBusinessId,
                      },
                      model: {
                        value: _vm.selectedBusiness,
                        callback: function ($$v) {
                          _vm.selectedBusiness = $$v
                        },
                        expression: "selectedBusiness",
                      },
                    },
                    [
                      _c("template", { slot: "noOptions" }, [
                        _c("span", [_vm._v("Start typing a business name")]),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-lg-4 form-inline" },
                [
                  _c("span", { staticClass: "col-form-label mr-2" }, [
                    _vm._v("Date range"),
                  ]),
                  _vm._v(" "),
                  _c("date-range-picker", {
                    ref: "picker",
                    attrs: {
                      "locale-data": { firstDay: 1, format: "dd/mm/yyyy" },
                      timePicker: false,
                      showDropdowns: true,
                      linkedCalendars: true,
                    },
                    on: { update: _vm.applyDateRange },
                    scopedSlots: _vm._u([
                      {
                        key: "input",
                        fn: function (picker) {
                          return [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm._f("date")(picker.startDate)) +
                                " - " +
                                _vm._s(_vm._f("date")(picker.endDate)) +
                                "\n                            "
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.dateRange,
                      callback: function ($$v) {
                        _vm.dateRange = $$v
                      },
                      expression: "dateRange",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-2" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-eqm-green btn-sm float-right mt-1",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.download.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fad fa-file-excel" }),
                    _vm._v(" Download"),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { lg: "6" } },
                  [
                    _c("b-pagination", {
                      attrs: {
                        "per-page": _vm.perPage,
                        "total-rows": _vm.totalRows,
                        "aria-controls": "applications_table",
                        "first-number": "",
                        "last-number": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "first-text",
                          fn: function () {
                            return [
                              _c("i", {
                                staticClass: "fad fa-chevron-double-left",
                              }),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "prev-text",
                          fn: function () {
                            return [
                              _c("i", { staticClass: "fas fa-chevron-left" }),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "next-text",
                          fn: function () {
                            return [
                              _c("i", { staticClass: "fas fa-chevron-right" }),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "last-text",
                          fn: function () {
                            return [
                              _c("i", {
                                staticClass: "fad fa-chevron-double-right",
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.currentPage,
                        callback: function ($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  {
                    staticClass: "justify-content-end d-flex",
                    attrs: { sm: "5", md: "6" },
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass:
                          "col-form-label col-form-label-sm text-sm-right mr-3 mt-1",
                        attrs: { for: "per-page-select" },
                      },
                      [_vm._v("Per page")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("b-form-select", {
                          staticClass: "custom-select-sm mt-1",
                          attrs: {
                            id: "per-page-select",
                            options: _vm.pageOptions,
                          },
                          model: {
                            value: _vm.perPage,
                            callback: function ($$v) {
                              _vm.perPage = $$v
                            },
                            expression: "perPage",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "position-relative" },
        [
          _c("b-table", {
            ref: "applicationsData",
            attrs: {
              striped: "",
              hover: "",
              outlined: "",
              "sort-icon-left": "",
              items: _vm.recordProvider,
              fields: _vm.fields,
              "per-page": _vm.perPage,
              "current-page": _vm.currentPage,
              filter: _vm.filter,
              "filter-debounce": "120",
              "no-provider-sorting": true,
              "no-provider-filtering": true,
            },
            on: { filtered: _vm.onFiltered },
            scopedSlots: _vm._u([
              {
                key: "cell(status)",
                fn: function (data) {
                  return [
                    _c("span", { domProps: { innerHTML: _vm._s(data.value) } }),
                  ]
                },
              },
              {
                key: "cell(actions)",
                fn: function (data) {
                  return [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover:applications_table.hover.top",
                            value: "View report",
                            expression: "'View report'",
                            arg: "applications_table",
                            modifiers: { hover: true, top: true },
                          },
                        ],
                        staticClass: "d-block btn btn-light",
                        on: {
                          click: function ($event) {
                            return _vm.openFootprintDetail(data)
                          },
                        },
                      },
                      [_c("i", { staticClass: "fad fa-chart-pie" })]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _vm.isLoading
            ? _c(
                "div",
                { staticClass: "position-absolute table-loading-mask" },
                [_c("loading")],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("b-pagination", {
        attrs: {
          "per-page": _vm.perPage,
          "total-rows": _vm.totalRows,
          "aria-controls": "applications_table",
          "first-number": "",
          "last-number": "",
        },
        scopedSlots: _vm._u([
          {
            key: "first-text",
            fn: function () {
              return [_c("i", { staticClass: "fad fa-chevron-double-left" })]
            },
            proxy: true,
          },
          {
            key: "prev-text",
            fn: function () {
              return [_c("i", { staticClass: "fas fa-chevron-left" })]
            },
            proxy: true,
          },
          {
            key: "next-text",
            fn: function () {
              return [_c("i", { staticClass: "fas fa-chevron-right" })]
            },
            proxy: true,
          },
          {
            key: "last-text",
            fn: function () {
              return [_c("i", { staticClass: "fad fa-chevron-double-right" })]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.currentPage,
          callback: function ($$v) {
            _vm.currentPage = $$v
          },
          expression: "currentPage",
        },
      }),
      _vm._v(" "),
      _vm.footprint !== null && !_vm.footprint.is_legacy
        ? _c("footprint-report-detail", {
            attrs: { footprint_source: _vm.footprint, faqurl: "faqurl" },
            on: { "footprint-detail-modal-close": _vm.closeFootprintDetail },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }