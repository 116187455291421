<template>
    <div class="question-nav-item nav-link" :class="classes" :data-nav-for-question="question_id">
    <a class="a-button" :class="{'disabled': disabled}"
        :disabled="disabled"
        :href="url"
        @click.prevent="navigate"
        >
        <span class="align-self-start question-number">

           <span v-if="iconclass" class="question-number-text question-number-icon"><i :class="iconclass"></i></span>

        </span>
        <span class="question-text">{{text}}</span>
        <span class="align-self-start question-status"><i :class="answered_icon_class"></i></span>
    </a>
    </div>
</template>

<script>
export default {
    name: "QuestionnaireSidebarItem",
    props: {
        question_id: {type: String, default: ''},
        classlist: {type: [String,Array], default: () => []},
        iconclass: {type: String, default: ''},
        formselector: {type: String, default: 'questionnaire'},
        disabled: false,
        url: {type: String, default: '#'},
        text: {type: String, default: 'Link text'},
        answered_icon_class: {type: String, default: 'fa fa-dot'},
    },
    data() {
        return {
            classes: []
        }
    },
    mounted() {
        this.classes = JSON.parse(this.classlist);
    },
    methods: {
        navigate() {
            // In the carbon audit section, we can't just submit the form and rely on the "next question id" functionality
            // because the routing is all bundled up internally. On those pages then, we just treat the sidebar nav like a
            // regular link (with the implication that saving doesn't happen to whatever is on the carbon audit page...)
            if(document.getElementById('active_carbon_audit_section')) {
                window.location.href = this.url;
            }
            else {
                if(document.getElementById('next_question_id')) {
                    document.getElementById('next_question_id').value = this.question_id;
                    document.getElementById('savequestionaction_override').value = 'next';
                    document.getElementById(this.formselector).submit();
                }
                else if(document.getElementById('previous_question_id')) {
                    document.getElementById('previous_question_id').value = this.question_id;
                    document.getElementById('savequestionaction_override').value = 'previous';
                    document.getElementById(this.formselector).submit();
                }
                else {
                    window.location.href = this.url;
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
