var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "btn btn-sm btn-outline-danger",
      attrs: { name: "removeuser" },
      on: {
        click: function ($event) {
          return _vm.removeUser()
        },
      },
    },
    [_vm._v("Remove")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }