var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "business-users-list" }, [
    _c(
      "table",
      { staticClass: "table table-sm table-striped" },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "transition-group",
          { attrs: { name: "fade", tag: "tbody", appear: "" } },
          _vm._l(_vm.rows, function (row, index) {
            return _c(
              "tr",
              {
                key: row.id,
                staticClass: "business-user-list-item",
                attrs: { "data-index": index },
              },
              [
                _c("td", [
                  _vm._v(_vm._s(row.name) + " "),
                  row.is_current ? _c("span", [_vm._v("(You)")]) : _vm._e(),
                ]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(row.email))]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    !row.is_current
                      ? _c("remove-user-button", {
                          attrs: {
                            user_id: row.id,
                            business_id: _vm.business.id,
                            post_url: row.remove_url,
                          },
                          on: {
                            user_removed_from_grid: function ($event) {
                              return _vm.removeUserRow($event)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            )
          }),
          0
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Email")]),
        _vm._v(" "),
        _c("th", [_vm._v(" ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }