var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "action-plans",
      class: { "action-plans_hascompleted": _vm.has_completed },
    },
    [
      (_vm.title || "").length > 0 && _vm.showtoggles
        ? _c("h2", { staticClass: "h5" }, [
            _vm._v("\n        " + _vm._s(_vm.title) + "\n\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isAutoSaving,
              expression: "isAutoSaving",
            },
          ],
          staticClass: "autosaving-icon",
        },
        [_c("i", { staticClass: "fad fa-spinner fa-spin" })]
      ),
      _vm._v(" "),
      (_vm.isEmpty(_vm.rows) || !_vm.has_actions) && !_vm.visible
        ? _c(
            "button",
            {
              staticClass: "btn btn-success toggle action-plan-toggle",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.showActionPlan()
                },
              },
            },
            [_c("i", { class: `fas fa-plus` }), _vm._v(" Add action\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.visible
        ? _c(
            "div",
            { staticClass: "action-plan-actions" },
            _vm._l(_vm.rows, function (row, index) {
              return _c(
                "div",
                {
                  key: row.id,
                  staticClass: "action-plan",
                  class: {
                    correction: row.isCAR,
                    hovered: row.hovered || _vm.row_hover_states[index].hovered,
                    "is-loading": _vm.isLoading,
                    "my-3": index > 0,
                    "mb-3 mt-0": index == 0,
                  },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: row.existing_id,
                        expression: "row.existing_id",
                      },
                    ],
                    attrs: {
                      type: "hidden",
                      name:
                        _vm.name +
                        "_actions[" +
                        (row.existing_id ? row.existing_id : index) +
                        "][existing_id]",
                      disabled: !_vm.editable,
                    },
                    domProps: { value: row.existing_id },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(row, "existing_id", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "inputwrapper",
                      refInFor: true,
                      staticClass: "card action-plan-actions-input-wrap",
                      class: {
                        "empty-action-row fade collapse":
                          row.is_empty_action_row,
                        show: !row.hide_empty_by_default,
                      },
                    },
                    [
                      _c("div", { staticClass: "row no-gutters" }, [
                        _c("div", { staticClass: "col-lg-9" }, [
                          _c(
                            "div",
                            { staticClass: "card-body action-plan-body" },
                            [
                              row.isCAR
                                ? _c(
                                    "div",
                                    {
                                      class: {
                                        "my-3": index > 1,
                                        "mb-3 mt-0": index == 1,
                                      },
                                    },
                                    [
                                      _c("h3", { staticClass: "h5" }, [
                                        _vm._v("Corrective Action Request"),
                                      ]),
                                      _vm._v(" "),
                                      _c("h4", { staticClass: "h6" }, [
                                        _vm._v(
                                          "This action has been assigned to you by an administrator."
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              index > (_vm.min || 1) - 1 && row.status !== 1
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "action-plan-remove-action-icon",
                                      class: {
                                        disabled: _vm.rows.length <= _vm.min,
                                        pointer: _vm.rows.length > _vm.min,
                                      },
                                      attrs: {
                                        disabled: _vm.rows.length <= _vm.min,
                                      },
                                      on: {
                                        mouseenter: function ($event) {
                                          return _vm.deleteOnEnter(index)
                                        },
                                        mouseleave: function ($event) {
                                          return _vm.deleteOnLeave(index)
                                        },
                                        click: function ($event) {
                                          return _vm.deleteRow(index)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fad fa-times-circle text-danger",
                                        attrs: { title: "Remove this action" },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "m-0 text-large font-weight-bold action-plan-action-title",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      row.status == 1
                                        ? "What needed doing"
                                        : "What needs doing:"
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("ResizeAuto", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ resize }) {
                                        return [
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: row.value,
                                                expression: "row.value",
                                              },
                                            ],
                                            staticClass:
                                              "form-control action-plan-action-input textarea",
                                            class: {
                                              disabled:
                                                row.status == 1 ||
                                                !_vm.editable,
                                            },
                                            attrs: {
                                              rows: _vm.textarearows,
                                              name:
                                                _vm.name +
                                                "_actions[" +
                                                (row.existing_id
                                                  ? row.existing_id
                                                  : index) +
                                                "][action]",
                                            },
                                            domProps: { value: row.value },
                                            on: {
                                              input: [
                                                function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    row,
                                                    "value",
                                                    $event.target.value
                                                  )
                                                },
                                                resize,
                                              ],
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _vm.editable ||
                              row.showcomments ||
                              (!_vm.editable &&
                                row.comments &&
                                row.comments.length)
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "m-1 mt-2 small action-add-comments-trigger",
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleComments(index)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !row.showcomments,
                                            expression: "!row.showcomments",
                                          },
                                        ],
                                        staticClass: "fal fa-plus",
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            row.showcomments || !_vm.editable
                                              ? "Comments"
                                              : "Add comment"
                                          )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: row.showcomments,
                                      expression: "row.showcomments",
                                    },
                                  ],
                                },
                                [
                                  _c("ResizeAuto", {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ resize }) {
                                            return [
                                              _c("textarea", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: row.comments,
                                                    expression: "row.comments",
                                                  },
                                                ],
                                                staticClass:
                                                  "form-control action-plan-comments-input",
                                                class: {
                                                  disabled:
                                                    row.status == 1 ||
                                                    !_vm.editable,
                                                },
                                                attrs: {
                                                  name:
                                                    _vm.name +
                                                    "_actions[" +
                                                    (row.existing_id
                                                      ? row.existing_id
                                                      : index) +
                                                    "][comments]",
                                                },
                                                domProps: {
                                                  value: row.comments,
                                                },
                                                on: {
                                                  input: [
                                                    function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        row,
                                                        "comments",
                                                        $event.target.value
                                                      )
                                                    },
                                                    resize,
                                                  ],
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-lg-3" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "card-footer action-plan-footer pt-2 pb-3",
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-md-4 col-lg-11" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "small font-weight-bold mb-0",
                                      },
                                      [_vm._v("Due date")]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: row.due_date,
                                          expression: "row.due_date",
                                        },
                                      ],
                                      staticClass:
                                        "form-control form-control-sm",
                                      attrs: {
                                        type: "date",
                                        name:
                                          _vm.name +
                                          "_actions[" +
                                          (row.existing_id
                                            ? row.existing_id
                                            : index) +
                                          "][due_date]",
                                        disabled: !_vm.editable,
                                      },
                                      domProps: { value: row.due_date },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            row,
                                            "due_date",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-md-4 col-lg-11" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "small font-weight-bold mb-0",
                                      },
                                      [_vm._v("Action status")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: row.status,
                                            expression: "row.status",
                                          },
                                        ],
                                        staticClass:
                                          "form-control form-control-sm",
                                        attrs: {
                                          name:
                                            _vm.name +
                                            "_actions[" +
                                            (row.existing_id
                                              ? row.existing_id
                                              : index) +
                                            "][status]",
                                          disabled: !_vm.editable,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              row,
                                              "status",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                        },
                                      },
                                      _vm._l(
                                        _vm.statuses,
                                        function (status, key) {
                                          return _c(
                                            "option",
                                            {
                                              key: key,
                                              domProps: { value: key },
                                            },
                                            [_vm._v(_vm._s(status))]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: row.status == 1,
                                        expression: "row.status == 1",
                                      },
                                    ],
                                    staticClass: "col-md-4 col-lg-11",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "small font-weight-bold mb-0",
                                      },
                                      [_vm._v("Completed date")]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: row.completed_at,
                                          expression: "row.completed_at",
                                        },
                                      ],
                                      staticClass:
                                        "form-control form-control-sm",
                                      attrs: {
                                        type: "date",
                                        name:
                                          _vm.name +
                                          "_actions[" +
                                          (row.existing_id
                                            ? row.existing_id
                                            : index) +
                                          "][completed_at]",
                                        disabled: !_vm.editable,
                                      },
                                      domProps: { value: row.completed_at },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            row,
                                            "completed_at",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "controls mt-3" }, [
                    _vm.add_control
                      ? _c(
                          "span",
                          {
                            class: {
                              btn: true,
                              "btn-eqm-green": true,
                              disabled: _vm.rows.length >= _vm.max,
                              pointer: _vm.rows.length < _vm.max,
                              "d-none": index != _vm.rows.length - 1,
                            },
                            attrs: {
                              disabled: _vm.rows.length >= _vm.max,
                              visible: index == _vm.rows.length - 1,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.addRow(index)
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "fas fa-plus" }),
                            _vm._v(" Add another action\n                "),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.completed_rows.length
        ? _c(
            "div",
            { staticClass: "completed-actions" },
            [
              _c("h5", { staticClass: "h5 completed-actions-title" }, [
                _vm._v(
                  _vm._s(_vm.completed_rows.length) +
                    " Completed action" +
                    _vm._s(_vm.completed_rows.length > 1 ? "s" : "") +
                    " "
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-link completed-actions-toggle",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.toggleCompletedActions()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.show_completed ? "Hide list" : "Show") + "  "
                    ),
                    _c("i", {
                      staticClass: "fas",
                      class: {
                        "fa-chevron-up": _vm.show_completed,
                        "fa-chevron-right": !_vm.show_completed,
                      },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm._l(_vm.completed_rows, function (row, index) {
                return _vm.show_completed
                  ? _c(
                      "div",
                      {
                        key: row.id,
                        staticClass: "completed-action-plan d-flex",
                        class: {
                          "is-loading": _vm.isLoading,
                          "my-3": index > 0,
                          "mb-3 mt-0": index == 0,
                        },
                      },
                      [
                        _c("div", { staticClass: "completed-action-date" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(row.completed_at) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "completed-action-plan-action" },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(row.value) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e()
              }),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }