var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "card-deck card-meter-columns",
        class: { "is-loading": _vm.isLoading },
      },
      [
        _vm.isLoading
          ? _c("loading", {
              staticStyle: {
                "font-size": "1.2rem !important",
                position: "absolute",
                top: "10px",
                right: "10px",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.rows, function (row, index) {
          return [
            _c(
              "div",
              {
                key: row.id,
                staticClass: "card card-meter",
                class: { meter_uses_readings: _vm.uses_readings },
                attrs: { meter_title: _vm.meter_title, verb: _vm.verb },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: row.id,
                      expression: "row.id",
                    },
                  ],
                  attrs: {
                    type: "hidden",
                    name: `${_vm.name}[meters][${index}][id]`,
                  },
                  domProps: { value: row.id },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(row, "id", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "card-header" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: row.name,
                        expression: "row.name",
                      },
                    ],
                    staticClass:
                      "form-control form-control-lg meter-name-input",
                    class: { meter_name_required: row.name_is_empty_error },
                    attrs: {
                      type: "text",
                      name: `${_vm.name}[meters][${index}][name]`,
                      placeholder: `Enter a ${_vm.meter_title} name`,
                    },
                    domProps: { value: row.name },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(row, "name", $event.target.value)
                        },
                        function ($event) {
                          _vm.enableNewMeter()
                          _vm.clearMeterNameMissingError(index)
                        },
                      ],
                    },
                  }),
                  _vm._v(" "),
                  row.name_is_empty_error
                    ? _c(
                        "div",
                        { staticClass: "meter_name_required_feedback" },
                        [
                          _c("p", [
                            _vm._v(
                              "Please enter a name to refer to this " +
                                _vm._s(_vm.meter_title)
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-right" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-link btn-sm meter-description-toggle-btn",
                        attrs: {
                          type: "button",
                          "data-toggle": "collapse",
                          "data-target": `#description_meter_${index}`,
                          "aria-expanded": "false",
                          "aria-controls": `description_meter_${index}`,
                        },
                      },
                      [
                        _vm._v(
                          "\n                            Add description/ref\n                        "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "collapse",
                      attrs: { id: `description_meter_${index}` },
                    },
                    [
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: row.description,
                              expression: "row.description",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            name: `${_vm.name}[meters][${index}][description]`,
                            placeholder: "Add a description or reference",
                          },
                          domProps: { value: row.description },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(row, "description", $event.target.value)
                            },
                          },
                        }),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "card-body meter-usage-amount-area text-monospace form-inline",
                  },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "meter-usage-amount-input",
                          attrs: {
                            for: "`${name}[meters][${index}][kwh_used]`",
                          },
                        },
                        [
                          _c("span", {
                            domProps: { innerHTML: _vm._s(_vm.units) },
                          }),
                          _vm._v("  " + _vm._s(_vm.verb)),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: row.kwh_used,
                            expression: "row.kwh_used",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "number",
                          step: "0.1",
                          min: "0",
                          name: `${_vm.name}[meters][${index}][kwh_used]`,
                        },
                        domProps: { value: row.kwh_used },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(row, "kwh_used", $event.target.value)
                            },
                            function ($event) {
                              _vm.solveKwh("kwh_used", index)
                              _vm.checkForMeterName(index)
                            },
                          ],
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _vm.show_fuel_type
                      ? _c("div", { staticClass: "form-group mt-2" }, [
                          _c(
                            "label",
                            {
                              staticClass: "meter-usage-amount-input",
                              attrs: {
                                for: "`${name}[meters][${index}][fuel_type_id]`",
                              },
                            },
                            [_vm._v("Type")]
                          ),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: row.fuel_type_id,
                                  expression: "row.fuel_type_id",
                                },
                              ],
                              staticClass: "form-control ml-2",
                              attrs: {
                                name: `${_vm.name}[meters][${index}][fuel_type_id]`,
                              },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    row,
                                    "fuel_type_id",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v("Choose a type..."),
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.fuel_types, function (fuel_type) {
                                return _c(
                                  "option",
                                  {
                                    key: fuel_type.id,
                                    domProps: { value: fuel_type.id },
                                  },
                                  [_vm._v(_vm._s(fuel_type.name))]
                                )
                              }),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._t("additional_meter_fields", null, {
                      slotProps: {
                        row: row,
                        index: index,
                        name: _vm.name,
                        solveKwh: _vm.solveKwh,
                        updateRow: _vm.updateRow,
                      },
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.uses_readings
                  ? _c(
                      "div",
                      {
                        staticClass: "card-body meter-readings-input-pair px-0",
                      },
                      [
                        _c("div", { staticClass: "text-right" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-link btn-sm",
                              attrs: {
                                type: "button",
                                "data-toggle": "collapse",
                                "data-target": `#meter_readings_for_${index}`,
                                "aria-expanded": "false",
                                "aria-controls": `meter_readings_for_${index}`,
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Enter meter readings\n                        "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "collapse",
                            attrs: { id: `meter_readings_for_${index}` },
                          },
                          [
                            _c("div", { staticClass: "px-2" }, [
                              _c("p", { staticClass: "text-center" }, [
                                _vm._v(
                                  "If you know your meter readings you can use these to automatically calculate the total usage"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row no-gutters" }, [
                                _c("div", { staticClass: "col-6" }, [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "form-control-sm",
                                        attrs: {
                                          for: "`${name}[meters][${index}][current_reading]`",
                                        },
                                      },
                                      [_vm._v("Current reading")]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: row.current_reading,
                                          expression: "row.current_reading",
                                        },
                                      ],
                                      staticClass:
                                        "form-control form-control-sm",
                                      attrs: {
                                        type: "number",
                                        step: "0.1",
                                        min: "0",
                                        name: `${_vm.name}[meters][${index}][current_reading]`,
                                      },
                                      domProps: { value: row.current_reading },
                                      on: {
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              row,
                                              "current_reading",
                                              $event.target.value
                                            )
                                          },
                                          function ($event) {
                                            return _vm.solveKwh(
                                              "current_reading",
                                              index
                                            )
                                          },
                                        ],
                                      },
                                    }),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-6" }, [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "label",
                                      { staticClass: "form-control-sm" },
                                      [_vm._v("Previous reading")]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      staticClass:
                                        "form-control form-control-sm disabled",
                                      attrs: {
                                        type: "number",
                                        step: "0.1",
                                        disabled: "",
                                      },
                                      domProps: {
                                        value: (
                                          (_vm.value || {}).meters[index] || {}
                                        ).current_reading,
                                      },
                                    }),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showMeterDelete(row, index)
                  ? _c("div", { staticClass: "meter-delete text-right" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-link btn-sm mr-1 meter-delete-btn",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteMeter(row, index)
                            },
                          },
                        },
                        [_vm._v("Delete")]
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ]
        }),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "btn btn-outline-primary meter-add-new-trigger",
        class: { disabled: !_vm.canAddRow },
        on: {
          click: function ($event) {
            return _vm.addRow()
          },
        },
      },
      [
        _c("i", { staticClass: "fas fa-plus" }),
        _vm._v(" "),
        _c("span", {
          domProps: { innerHTML: _vm._s(`Add a ${_vm.meter_title}`) },
        }),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "meter-total-usage-area" }, [
      _c("table", { staticClass: "table table-md-responsive" }, [
        _c("tbody", [
          _c("tr", [
            _c("th", { attrs: { scope: "row" } }, [
              _vm._v("Total "),
              _c("span", { domProps: { innerHTML: _vm._s(_vm.units) } }),
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "text-monospace" }, [
              _vm._v(_vm._s(_vm.total_kwh)),
            ]),
          ]),
          _vm._v(" "),
          _vm.showCO2Totals
            ? _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v("Total CO"),
                  _c("sub", [_vm._v("2")]),
                  _vm._v("e "),
                  _c("i", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.bottom",
                        value: "Carbon equivalent, shown in tonnes per year",
                        expression:
                          "'Carbon equivalent, shown in tonnes per year'",
                        modifiers: { bottom: true },
                      },
                    ],
                    staticClass: "fad fa-info-circle",
                    staticStyle: {
                      position: "relative",
                      display: "inline-block",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "text-monospace" }, [
                  _vm._v(_vm._s(_vm.total_co2) + " tonnes/yr"),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }