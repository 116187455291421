<script>
export default {
    name: 'AppendInputSimpleComponent',
    props: ['value','name'],
    data: function () {
        return { values: [] };
    },

    created: function () {
        this.values = Array.from(this.value);
    },
    methods: {
        updateValue: function() {
            this.$emit('input', this.values);
        },
        deleteValue: function(index) {
            this.values.splice(index, 1);
            this.$emit('input', this.values);
        },
        addValue: function() {
            this.values.push('');
            this.$emit('input', this.values);
        }
    }
}
</script>

<template>
    <div class="multi-text w-100">

        <div v-for="(item, index) in values" class="input-group mb-3">
            <input type="text"
                   :name="name"
                   class="form-control"
                   v-model="values[index]" @input="updateValue()" />
            <div class="input-group-append append-input-suffix">
                <span class="input-group-text pointer" @click="deleteValue(index)">-</span>
            </div>
        </div>
        <div class="d-flex align-items-end justify-content-end">
            <a href="#" class="btn btn-small btn-link" @click="addValue()">Add another</a>
        </div>

    </div>
</template>

<style scoped>

</style>
