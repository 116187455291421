import $ from 'jquery';
import { parseDotNotation } from '../general';

// Thanks: https://stackoverflow.com/a/44411312/3578036
// Used to set the old() data from the form to the Vue model
export default function init() {
    return {
        bind: function (el, binding, vnode) {
            // Replace array syntax with dot syntax
            let arr = binding.arg.replace(/(?:\]\[|\[)/g, '.').replace(']', '');
            let obj = {};
            obj[arr] = binding.value;

            // We are using this to convert a string into an object.
            // This means that test.string.value becomes { test: { string: value }}
            obj = parseDotNotation(obj);

            // Setting the first parameter to true will make the extend method work
            // recursively.
            $.extend(true, vnode.context, obj);
        }
    };
}
