var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "multi-text w-100" },
    [
      _vm._l(_vm.values, function (item, index) {
        return _c("div", { staticClass: "input-group mb-3" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.values[index],
                expression: "values[index]",
              },
            ],
            staticClass: "form-control",
            attrs: { type: "text", name: _vm.name },
            domProps: { value: _vm.values[index] },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.values, index, $event.target.value)
                },
                function ($event) {
                  return _vm.updateValue()
                },
              ],
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "input-group-append append-input-suffix" }, [
            _c(
              "span",
              {
                staticClass: "input-group-text pointer",
                on: {
                  click: function ($event) {
                    return _vm.deleteValue(index)
                  },
                },
              },
              [_vm._v("-")]
            ),
          ]),
        ])
      }),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex align-items-end justify-content-end" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-small btn-link",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                return _vm.addValue()
              },
            },
          },
          [_vm._v("Add another")]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }