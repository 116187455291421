var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.footprint
      ? _c("div", {
          class: { "modal-backdrop": _vm.modal },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.closeModal.apply(null, arguments)
            },
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.footprint
      ? _c(
          "div",
          {
            staticClass: "record-modal",
            class: {
              "modal modal-slide fade": _vm.modal,
              show: _vm.footprint !== [] && _vm.footprint,
            },
            attrs: { id: "record_modal" },
          },
          [
            _c("div", { class: { "modal-dialog": _vm.modal } }, [
              _vm.modal
                ? _c("div", { staticClass: "modal-header" }, [
                    _c(
                      "div",
                      { staticClass: "carbon-footprint-date-range-heading" },
                      [
                        _vm._v(
                          "\n                    Detailed results for the period:"
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c("h3", { staticClass: "h3 mt-3 mb-5 mr-3" }, [
                          _vm._v(
                            _vm._s(_vm.footprint.start_date_string) +
                              " - " +
                              _vm._s(_vm.footprint.end_date_string)
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "modal-close d-flex ml-5 btn btn-info align-items-center",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.closeModal.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "mr-2" }, [_vm._v("Close")]),
                        _vm._v(" "),
                        _c("i", { staticClass: "fal fa-times" }),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { class: { "modal-content": _vm.modal } }, [
                _c("div", { staticClass: "footprintresultsdetails" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "container-fluid bg-nice-black py-3 mb-0 footprint-report-overall-chart",
                    },
                    [
                      _c("div", { staticClass: "container" }, [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex",
                            staticStyle: {
                              "align-items": "center",
                              "justify-content": "space-around",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: { "min-width": "380px" },
                                style: {
                                  width: _vm.footprint["has_cost_data"]
                                    ? "25vw"
                                    : "45vw",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "total_footprint_value text-center",
                                  },
                                  [
                                    _c(
                                      "h1",
                                      {
                                        staticClass:
                                          "display-2 mt-1 mb-0 text-white font-weight-bold",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatNumber(
                                              _vm.footprint["totalEmissions"] /
                                                1000,
                                              2
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._m(0),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("overall-footprint-chart", {
                                  attrs: {
                                    data: JSON.stringify(
                                      _vm.footprint["overallChartData"]
                                    ),
                                    dataset_labels:
                                      _vm.footprint["labels_formatted"],
                                    color_scheme:
                                      _vm.footprint["colours_formatted"],
                                    show_waste_disposal:
                                      _vm.show_waste_disposal,
                                    show_coolants: _vm.show_waste_disposal,
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "pl-5 pr-5 pt-3 pb-2 bg-white" },
                              [
                                _c("overall-table", {
                                  attrs: {
                                    total_energy: _vm.formatNumber(
                                      _vm.footprint["totalEnergy"] / 1000,
                                      2
                                    ),
                                    total_transport: _vm.formatNumber(
                                      _vm.footprint["totalTransport"] / 1000,
                                      2
                                    ),
                                    total_commute: _vm.formatNumber(
                                      _vm.footprint["totalCommute"] / 1000,
                                      2
                                    ),
                                    total_water: _vm.formatNumber(
                                      _vm.footprint["totalWater"] / 1000,
                                      2
                                    ),
                                    total_waste: _vm.formatNumber(
                                      _vm.footprint["totalWaste"] / 1000,
                                      2
                                    ),
                                    total_coolants: _vm.formatNumber(
                                      _vm.footprint["totalCoolants"] / 1000,
                                      2
                                    ),
                                    total_generated: _vm.formatNumber(
                                      _vm.footprint["totalGenerated"] / 1000,
                                      2
                                    ),
                                    total_biomass: _vm.formatNumber(
                                      _vm.footprint["totalBiomass"] / 1000,
                                      2
                                    ),
                                    total: _vm.formatNumber(
                                      _vm.footprint["totalEmissions"] / 1000,
                                      2
                                    ),
                                    n_trees: _vm.formatNumber(
                                      _vm.footprint["totalEmissions"] / 7,
                                      2
                                    ),
                                    cost_energy:
                                      _vm.footprint["costs"] &&
                                      _vm.footprint["costs"]["energy"]
                                        ? _vm.formatNumber(
                                            _vm.footprint["costs"]["energy"],
                                            0
                                          )
                                        : "-",
                                    cost_transport:
                                      _vm.footprint["costs"] &&
                                      _vm.footprint["costs"]["transport"]
                                        ? _vm.formatNumber(
                                            _vm.footprint["costs"]["transport"],
                                            0
                                          )
                                        : "-",
                                    cost_water:
                                      _vm.footprint["costs"] &&
                                      _vm.footprint["costs"]["water"]
                                        ? _vm.formatNumber(
                                            _vm.footprint["costs"]["water"],
                                            0
                                          )
                                        : "-",
                                    cost_waste:
                                      _vm.footprint["costs"] &&
                                      _vm.footprint["costs"]["waste"]
                                        ? _vm.formatNumber(
                                            _vm.footprint["costs"]["waste"],
                                            0
                                          )
                                        : "-",
                                    cost_coolants:
                                      _vm.footprint["costs"] &&
                                      _vm.footprint["costs"]["coolants"]
                                        ? _vm.formatNumber(
                                            _vm.footprint["costs"]["coolants"],
                                            0
                                          )
                                        : "-",
                                    cost_total:
                                      _vm.footprint["costs"] &&
                                      _vm.footprint["total_cost"]
                                        ? _vm.formatNumber(
                                            _vm.footprint["total_cost"],
                                            0
                                          )
                                        : "-",
                                    has_any_costs:
                                      !!_vm.footprint["has_cost_data"],
                                    show_waste_disposal:
                                      _vm.show_waste_disposal,
                                    show_coolants: _vm.show_coolants,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm._m(1),
                  _vm._v(" "),
                  _vm.footprint.show_intensity_factors
                    ? _c("div", [
                        _c(
                          "div",
                          { staticClass: "container-fluid bg-green py-3 mb-5" },
                          [
                            _c("div", { staticClass: "container pt-4 pb-4" }, [
                              _c("h2", { staticClass: "h1 mb-3 text-white" }, [
                                _vm._v("Carbon Intensity"),
                              ]),
                              _vm._v(" "),
                              _vm.footprint.intensity_factors.length
                                ? _c("div", {}, [
                                    _c(
                                      "p",
                                      { staticClass: "lead text-white" },
                                      [
                                        _vm._v(
                                          "Carbon intensity ratios help you compare your carbon footprint with other businesses or sectors. They are calculated by dividing your total carbon emissions by a metric that is comparable between businesses, for example the number of staff (Full Time Equivalent) or your turnover (£ million turnover)."
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "row carbon-intensity-factors",
                                      },
                                      _vm._l(
                                        _vm.footprint.intensity_factors,
                                        function (factor, index) {
                                          return _c(
                                            "div",
                                            {
                                              staticClass: "col-4 text-center",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "carbon-intensity-factor",
                                                },
                                                [
                                                  _c(
                                                    "h4",
                                                    {
                                                      staticClass:
                                                        "carbon-intensity-factor-title",
                                                    },
                                                    [
                                                      _vm._m(2, true),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(factor.title)
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "fa-stack fa-5x",
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fas fa-square fa-stack-2x",
                                                        staticStyle: {
                                                          color:
                                                            "rgb(88 88 88 / 32%)",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("i", {
                                                        class:
                                                          _vm.formatIntensityFactorClass(
                                                            factor
                                                          ),
                                                      }),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "total_footprint_value intensity_value text-center",
                                                    },
                                                    [
                                                      _c(
                                                        "h1",
                                                        {
                                                          staticClass:
                                                            "display-2 mt-1 mb-0 text-white font-weight-bold",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\n                                                    " +
                                                              _vm._s(
                                                                _vm.formatIntensityValue(
                                                                  factor.intensity_value_string
                                                                )
                                                              ) +
                                                              "\n\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-white",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              factor.intensity_unit_string
                                                            ) + " CO"
                                                          ),
                                                          _c("sub", [
                                                            _vm._v("2"),
                                                          ]),
                                                          _vm._v(
                                                            "e per " +
                                                              _vm._s(
                                                                factor.unit_description
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      factor.title ===
                                                      "Annual Turnover"
                                                        ? _c("i", {
                                                            directives: [
                                                              {
                                                                name: "tooltip",
                                                                rawName:
                                                                  "v-tooltip.bottom",
                                                                value:
                                                                  _vm.convertToPerMillion(
                                                                    factor.intensity
                                                                  ),
                                                                expression:
                                                                  "convertToPerMillion(factor.intensity)",
                                                                modifiers: {
                                                                  bottom: true,
                                                                },
                                                              },
                                                            ],
                                                            staticClass:
                                                              "fad fa-info-circle",
                                                            staticStyle: {
                                                              position:
                                                                "relative",
                                                              display:
                                                                "inline-block",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      { staticClass: "lead text-white" },
                                      [
                                        _vm._v(
                                          "Carbon intensity ratios are useful because they allow:"
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._m(3),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      { staticClass: "lead text-white" },
                                      [
                                        _vm._v(
                                          "They are particularly helpful in demonstrating carbon reductions in a growing organisation."
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.footprint.intensity_factors.length
                                ? _c("div", { staticClass: "row" }, [
                                    _vm._m(4),
                                    _vm._v(" "),
                                    _vm._m(5),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "container-fluid bg-gray-600 py-3 mb-5" },
                    [
                      _c("div", { staticClass: "container pt-4 pb-4" }, [
                        _c("h2", { staticClass: "h1 mb-3 text-white" }, [
                          _vm._v("Carbon Footprint Breakdown"),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "lead text-white" }, [
                          _vm._v(
                            "The charts and tables below show your carbon emissions across the various activities within your business. Activities with the greatest emissions are a good place to start your carbon reduction actions. "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "text-white" }, [
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-link text-white",
                              attrs: { href: _vm.faqurl, target: "_blank" },
                            },
                            [
                              _c("i", { staticClass: "fa fa-info-circle fal" }),
                              _vm._v(
                                " You can read more detail about how these numbers are generated and what they mean on the FAQs page"
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "container footprint-report-breakdown pb-5 mb-5",
                    },
                    [
                      _c(
                        "tabs",
                        {
                          attrs: {
                            options: { useUrlFragment: false },
                            "cache-lifetime": "0",
                          },
                        },
                        [
                          _c(
                            "tab",
                            {
                              attrs: {
                                name: "Energy audit",
                                prefix:
                                  "<i class='fad fa-plug fa-2x text-energy-footprint mr-3'></i>",
                              },
                            },
                            [
                              _vm.footprint["totalEnergy"]
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-block position-relative mb-4",
                                    },
                                    [
                                      _c("chart", {
                                        attrs: {
                                          series: [
                                            {
                                              data: _vm.footprint[
                                                "energyDetailsChartData"
                                              ]["values"],
                                            },
                                          ],
                                          dataset_labels:
                                            _vm.footprint[
                                              "energyDetailsChartData"
                                            ]["labels"],
                                          color_scheme:
                                            _vm.footprint["backgroundColours"],
                                          chart_url: _vm.footprint["charturl"],
                                          chart_type: _vm.chartType(
                                            _vm.footprint[
                                              "energyDetailsChartData"
                                            ]["labels"]
                                          ),
                                          chart_id: "totalEnergyChart",
                                          use_override_data:
                                            _vm.chartType(
                                              _vm.footprint[
                                                "energyDetailsChartData"
                                              ]["labels"]
                                            ) === "bar",
                                          override_data: [
                                            {
                                              data: _vm.footprint[
                                                "energyDetailsChartData"
                                              ]["values"],
                                            },
                                          ],
                                          floating_legend: false,
                                          show_legend:
                                            _vm.chartType(
                                              _vm.footprint[
                                                "energyDetailsChartData"
                                              ]["labels"]
                                            ) !== "bar",
                                          show_data_labels:
                                            _vm.chartType(
                                              _vm.footprint[
                                                "energyDetailsChartData"
                                              ]["labels"]
                                            ) === "bar",
                                          show_tooltips:
                                            _vm.chartType(
                                              _vm.footprint[
                                                "energyDetailsChartData"
                                              ]["labels"]
                                            ) !== "bar",
                                          horizontal: true,
                                          data_labels_color: "#333",
                                          width: 800,
                                          height:
                                            _vm.footprint[
                                              "energyDetailsChartData"
                                            ]["labels"].length * 90,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.footprint["totalEnergy"]
                                ? _c(
                                    "table",
                                    { staticClass: "table table-striped" },
                                    [
                                      _c("thead", [
                                        _c("tr", [
                                          _c(
                                            "th",
                                            {
                                              staticClass: "text-left",
                                              staticStyle: {
                                                "border-top": "none",
                                              },
                                            },
                                            [_vm._v("Energy type")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticClass: "text-left",
                                              staticStyle: {
                                                "border-top": "none",
                                              },
                                            },
                                            [
                                              _vm._v("CO"),
                                              _c("sub", [_vm._v("2")]),
                                              _vm._v("e "),
                                              _c("small", [_vm._v("(Tonnes)")]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticClass: "text-left",
                                              staticStyle: {
                                                "border-top": "none",
                                              },
                                            },
                                            [_vm._v("Usage")]
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "tbody",
                                        _vm._l(
                                          _vm.footprint[
                                            "energyDetailsChartData"
                                          ]["labels"],
                                          function (
                                            energychartdatalabel,
                                            index
                                          ) {
                                            return _c("tr", [
                                              _c("td", [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(energychartdatalabel)
                                                  ),
                                                ]),
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatNumber(
                                                      _vm.footprint[
                                                        "energyDetailsChartData"
                                                      ]["values_kg"][index] /
                                                        1000,
                                                      2
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatNumber(
                                                      _vm.footprint[
                                                        "energyDetailsChartData"
                                                      ]["usage"][index],
                                                      0
                                                    )
                                                  ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm.footprint[
                                                        "energyDetailsChartData"
                                                      ]["units"][index]
                                                    )
                                                ),
                                              ]),
                                            ])
                                          }
                                        ),
                                        0
                                      ),
                                      _vm._v(" "),
                                      _c("tfoot", [
                                        _vm.footprint["totalBiomass"] > 0
                                          ? _c("tr", [
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "text-left pt-3",
                                                  attrs: { colspan: "3" },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        Your Biomass usage came to "
                                                  ),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatNumber(
                                                          _vm.footprint[
                                                            "totalBiomass"
                                                          ] / 1000,
                                                          2
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                  _vm._v(" Tonnes CO"),
                                                  _c("sub", [_vm._v("2")]),
                                                  _vm._v(
                                                    "e, but this is not included in your overall carbon footprint "
                                                  ),
                                                  _c("i", {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName:
                                                          "v-tooltip.bottom",
                                                        value:
                                                          "NB Current guidelines for carbon reporting exclude any biomass usage from your overall footprint.<br> " +
                                                          "It is still useful to record this information as the guidance may change in the future",
                                                        expression:
                                                          "'NB Current guidelines for carbon reporting exclude any biomass usage from your overall footprint.<br> ' +\n                    'It is still useful to record this information as the guidance may change in the future'",
                                                        modifiers: {
                                                          bottom: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass:
                                                      "fad fa-info-circle",
                                                    staticStyle: {
                                                      position: "relative",
                                                      display: "inline-block",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c(
                                            "th",
                                            { attrs: { scope: "row" } },
                                            [_vm._v("Total for Energy")]
                                          ),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatNumber(
                                                  _vm.footprint[
                                                    "energyDetailsChartData"
                                                  ]["total"],
                                                  2
                                                )
                                              ) + " Tonnes CO"
                                            ),
                                            _c("sub", [_vm._v("2")]),
                                            _vm._v("e"),
                                          ]),
                                        ]),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.footprint["totalEnergy"]
                                ? _c("p", [
                                    _vm._v(
                                      "No energy usage data was submitted"
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "tab",
                            {
                              attrs: {
                                name: "Transport audit",
                                prefix:
                                  "<i class='fad fa-car fa-2x  text-transport-footprint mr-3'></i>",
                              },
                            },
                            [
                              _vm.footprint["totalTransport"]
                                ? _c("div", { staticClass: "col-12" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-block position-relative mb-4",
                                      },
                                      [
                                        _c("chart", {
                                          attrs: {
                                            series: [
                                              {
                                                data: _vm.footprint[
                                                  "vehicleDetailsChartData"
                                                ]["values"],
                                              },
                                            ],
                                            dataset_labels:
                                              _vm.footprint[
                                                "vehicleDetailsChartData"
                                              ]["labels"],
                                            color_scheme:
                                              _vm.footprint[
                                                "backgroundColours"
                                              ],
                                            chart_url:
                                              _vm.footprint["charturl"],
                                            chart_type: _vm.chartType(
                                              _vm.footprint[
                                                "vehicleDetailsChartData"
                                              ]["labels"]
                                            ),
                                            chart_id: "totalTransportChart",
                                            use_override_data:
                                              _vm.chartType(
                                                _vm.footprint[
                                                  "vehicleDetailsChartData"
                                                ]["labels"]
                                              ) === "bar",
                                            override_data: [
                                              {
                                                data: _vm.footprint[
                                                  "vehicleDetailsChartData"
                                                ]["values"],
                                              },
                                            ],
                                            floating_legend: false,
                                            show_legend:
                                              _vm.chartType(
                                                _vm.footprint[
                                                  "vehicleDetailsChartData"
                                                ]["labels"]
                                              ) !== "bar",
                                            show_data_labels:
                                              _vm.chartType(
                                                _vm.footprint[
                                                  "vehicleDetailsChartData"
                                                ]["labels"]
                                              ) === "bar",
                                            show_tooltips:
                                              _vm.chartType(
                                                _vm.footprint[
                                                  "vehicleDetailsChartData"
                                                ]["labels"]
                                              ) !== "bar",
                                            horizontal: true,
                                            data_labels_color: "#333",
                                            width: 800,
                                            height:
                                              _vm.chartType(
                                                _vm.footprint[
                                                  "vehicleDetailsChartData"
                                                ]["labels"]
                                              ) === "bar"
                                                ? _vm.footprint[
                                                    "vehicleDetailsChartData"
                                                  ]["labels"].length * 90
                                                : 240,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.footprint["totalTransport"]
                                ? _c(
                                    "table",
                                    { staticClass: "table table-striped" },
                                    [
                                      _c("thead", [
                                        _c("tr", [
                                          _c(
                                            "th",
                                            {
                                              staticClass: "text-left",
                                              staticStyle: {
                                                "border-top": "none",
                                              },
                                            },
                                            [_vm._v("Transport")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticClass: "text-left",
                                              staticStyle: {
                                                "border-top": "none",
                                              },
                                            },
                                            [
                                              _vm._v("CO"),
                                              _c("sub", [_vm._v("2")]),
                                              _vm._v("e "),
                                              _c("small", [_vm._v("(Tonnes)")]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticClass: "text-left",
                                              staticStyle: {
                                                "border-top": "none",
                                              },
                                            },
                                            [
                                              _vm._v("Distance "),
                                              _c("small", [_vm._v("(Km)")]),
                                              _c("sup", [_vm._v("*")]),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "tbody",
                                        _vm._l(
                                          _vm.footprint[
                                            "vehicleDetailsChartData"
                                          ]["labels"],
                                          function (
                                            transportchartdatalabel,
                                            index
                                          ) {
                                            return _c("tr", [
                                              _c("td", [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      transportchartdatalabel
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatNumber(
                                                      _vm.footprint[
                                                        "vehicleDetailsChartData"
                                                      ]["values_kg"][index] /
                                                        1000,
                                                      2
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatNumber(
                                                      _vm.footprint[
                                                        "vehicleDetailsChartData"
                                                      ]["usage"][index],
                                                      0
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ])
                                          }
                                        ),
                                        0
                                      ),
                                      _vm._v(" "),
                                      _c("tfoot", [
                                        _c("tr", [
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                padding: "0",
                                                "font-size": ".85rem",
                                              },
                                              attrs: { colspan: "2" },
                                            },
                                            [
                                              _c(
                                                "small",
                                                {
                                                  staticClass: "text-right",
                                                  staticStyle: {
                                                    "text-align": "right",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "* distances are converted to Kilometres prior to calculating CO2E. You enter the data in miles."
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("tfoot", [
                                        _c("tr", [
                                          _c(
                                            "th",
                                            { attrs: { scope: "row" } },
                                            [_vm._v("Total for Transport")]
                                          ),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatNumber(
                                                  _vm.footprint[
                                                    "vehicleDetailsChartData"
                                                  ]["total"],
                                                  2
                                                )
                                              ) + " Tonnes CO"
                                            ),
                                            _c("sub", [_vm._v("2")]),
                                            _vm._v("e"),
                                          ]),
                                        ]),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.footprint["totalTransport"]
                                ? _c("p", [
                                    _vm._v("No transport data was submitted"),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "tab",
                            {
                              attrs: {
                                name: "Water audit",
                                prefix:
                                  "<i class='fad fa-water fa-2x text-water-footprint mr-3'></i>",
                              },
                            },
                            [
                              _vm.footprint["totalWater"]
                                ? _c(
                                    "table",
                                    { staticClass: "table table-striped" },
                                    [
                                      _c("thead", [
                                        _c("tr", [
                                          _c(
                                            "th",
                                            {
                                              staticClass: "text-left",
                                              staticStyle: {
                                                "border-top": "none",
                                              },
                                            },
                                            [_vm._v("Aspect")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticClass: "text-left",
                                              staticStyle: {
                                                "border-top": "none",
                                              },
                                            },
                                            [
                                              _vm._v("CO"),
                                              _c("sub", [_vm._v("2")]),
                                              _vm._v("e "),
                                              _c("small", [_vm._v("(Tonnes)")]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticClass: "text-left",
                                              staticStyle: {
                                                "border-top": "none",
                                              },
                                            },
                                            [
                                              _vm._v("Usage "),
                                              _c("small", [
                                                _vm._v("(m"),
                                                _c("sup", [_vm._v("3")]),
                                                _vm._v(")"),
                                              ]),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "tbody",
                                        _vm._l(
                                          _vm.footprint[
                                            "waterDetailsChartData"
                                          ]["labels"],
                                          function (
                                            waterchartdatalabel,
                                            index
                                          ) {
                                            return _c("tr", [
                                              _c("td", [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(waterchartdatalabel)
                                                  ),
                                                ]),
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatNumber(
                                                      _vm.footprint[
                                                        "waterDetailsChartData"
                                                      ]["values_kg"][index] /
                                                        1000,
                                                      2
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatNumber(
                                                      _vm.footprint[
                                                        "waterDetailsChartData"
                                                      ]["usage"][index],
                                                      0
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ])
                                          }
                                        ),
                                        0
                                      ),
                                      _vm._v(" "),
                                      _c("tfoot", [
                                        _c("tr", [
                                          _c(
                                            "th",
                                            { attrs: { scope: "row" } },
                                            [_vm._v("Total for Water")]
                                          ),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatNumber(
                                                  _vm.footprint[
                                                    "waterDetailsChartData"
                                                  ]["total"],
                                                  2
                                                )
                                              ) + " Tonnes CO"
                                            ),
                                            _c("sub", [_vm._v("2")]),
                                            _vm._v("e"),
                                          ]),
                                        ]),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.footprint["totalWater"]
                                ? _c("p", [
                                    _vm._v("No water usage data was submitted"),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.show_waste_disposal
                            ? _c(
                                "tab",
                                {
                                  attrs: {
                                    name: "Waste audit",
                                    prefix:
                                      "<i class='fad fa-dumpster fa-2x text-waste-footprint mr-3'></i>",
                                  },
                                },
                                [
                                  _vm.footprint["totalWaste"]
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-block position-relative mb-4",
                                        },
                                        [
                                          _c("chart", {
                                            attrs: {
                                              series: [
                                                {
                                                  data: _vm.footprint[
                                                    "wasteDetailsChartData"
                                                  ]["values"],
                                                },
                                              ],
                                              dataset_labels:
                                                _vm.formatLabelsForChart(
                                                  _vm.footprint[
                                                    "wasteDetailsChartData"
                                                  ]["labels"]
                                                ),
                                              color_scheme:
                                                _vm.footprint[
                                                  "backgroundColours"
                                                ],
                                              chart_url:
                                                _vm.footprint["charturl"],
                                              chart_type: _vm.chartType(
                                                _vm.footprint[
                                                  "wasteDetailsChartData"
                                                ]["labels"]
                                              ),
                                              chart_id: "totalWasteChart",
                                              use_override_data:
                                                _vm.chartType(
                                                  _vm.footprint[
                                                    "wasteDetailsChartData"
                                                  ]["labels"]
                                                ) === "bar",
                                              override_data: [
                                                {
                                                  data: _vm.footprint[
                                                    "wasteDetailsChartData"
                                                  ]["values"],
                                                },
                                              ],
                                              floating_legend: false,
                                              show_legend:
                                                _vm.chartType(
                                                  _vm.footprint[
                                                    "wasteDetailsChartData"
                                                  ]["labels"]
                                                ) !== "bar",
                                              show_data_labels:
                                                _vm.chartType(
                                                  _vm.footprint[
                                                    "wasteDetailsChartData"
                                                  ]["labels"]
                                                ) === "bar",
                                              show_tooltips:
                                                _vm.chartType(
                                                  _vm.footprint[
                                                    "wasteDetailsChartData"
                                                  ]["labels"]
                                                ) !== "bar",
                                              horizontal: true,
                                              data_labels_color: "#333",
                                              width: 800,
                                              height:
                                                _vm.chartType(
                                                  _vm.footprint[
                                                    "wasteDetailsChartData"
                                                  ]["labels"]
                                                ) === "bar"
                                                  ? _vm.footprint[
                                                      "wasteDetailsChartData"
                                                    ]["labels"].length * 90
                                                  : 240,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.footprint["totalWaste"]
                                    ? _c(
                                        "table",
                                        { staticClass: "table table-striped" },
                                        [
                                          _c("thead", [
                                            _c("tr", [
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "text-left",
                                                  staticStyle: {
                                                    "border-top": "none",
                                                  },
                                                },
                                                [_vm._v("Waste stream")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "text-left",
                                                  staticStyle: {
                                                    "border-top": "none",
                                                  },
                                                },
                                                [_vm._v("Recycled?")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "text-left",
                                                  staticStyle: {
                                                    "border-top": "none",
                                                  },
                                                },
                                                [
                                                  _vm._v("CO"),
                                                  _c("sub", [_vm._v("2")]),
                                                  _vm._v("e "),
                                                  _c("small", [
                                                    _vm._v("(Tonnes)"),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "text-left",
                                                  staticStyle: {
                                                    "border-top": "none",
                                                  },
                                                },
                                                [
                                                  _vm._v("Volume "),
                                                  _c("small", [
                                                    _vm._v("(Tonnes)"),
                                                  ]),
                                                ]
                                              ),
                                            ]),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "tbody",
                                            _vm._l(
                                              _vm.footprint[
                                                "wasteDetailsChartData"
                                              ]["labels"],
                                              function (
                                                wastechartdatalabel,
                                                index
                                              ) {
                                                return _c("tr", [
                                                  _c("td", [
                                                    _c("strong", [
                                                      _vm._v(
                                                        _vm._s(
                                                          wastechartdatalabel
                                                        )
                                                      ),
                                                    ]),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.footprint[
                                                          "wasteDetailsChartData"
                                                        ]["recycling_labels"][
                                                          index
                                                        ]
                                                      )
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.footprint[
                                                          "wasteDetailsChartData"
                                                        ]["values"][index] <
                                                          0.005
                                                          ? `${_vm.formatNumber(
                                                              _vm.footprint[
                                                                "wasteDetailsChartData"
                                                              ]["values_kg"][
                                                                index
                                                              ],
                                                              2
                                                            )} Kg`
                                                          : _vm.formatNumber(
                                                              _vm.footprint[
                                                                "wasteDetailsChartData"
                                                              ]["values_kg"][
                                                                index
                                                              ] / 1000,
                                                              2
                                                            )
                                                      ) + "\n"
                                                    ),
                                                    _vm._v(" "),
                                                    _vm.footprint[
                                                      "wasteDetailsChartData"
                                                    ]["values"][index] < 0.005
                                                      ? _c("i", {
                                                          directives: [
                                                            {
                                                              name: "tooltip",
                                                              rawName:
                                                                "v-tooltip.bottom",
                                                              value:
                                                                "The CO2E value is too small to display in tonnes, so it is shown in Kilograms",
                                                              expression:
                                                                "'The CO2E value is too small to display in tonnes, so it is shown in Kilograms'",
                                                              modifiers: {
                                                                bottom: true,
                                                              },
                                                            },
                                                          ],
                                                          staticClass:
                                                            "fad fa-info-circle",
                                                          staticStyle: {
                                                            position:
                                                              "relative",
                                                            display:
                                                              "inline-block",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatNumber(
                                                          _vm.footprint[
                                                            "wasteDetailsChartData"
                                                          ]["usage"][index],
                                                          3
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ])
                                              }
                                            ),
                                            0
                                          ),
                                          _vm._v(" "),
                                          _c("tfoot", [
                                            _c("tr", [
                                              _c(
                                                "th",
                                                { attrs: { scope: "row" } },
                                                [
                                                  _vm._v(
                                                    "Total for Waste Disposal"
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatNumber(
                                                      _vm.footprint[
                                                        "wasteDetailsChartData"
                                                      ]["total"],
                                                      2
                                                    )
                                                  ) + " Tonnes CO"
                                                ),
                                                _c("sub", [_vm._v("2")]),
                                                _vm._v("e"),
                                              ]),
                                            ]),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.footprint["totalWaste"]
                                    ? _c("p", [
                                        _vm._v(
                                          "No waste disposal data was submitted"
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.show_coolants
                            ? _c(
                                "tab",
                                {
                                  attrs: {
                                    name: "Coolants audit",
                                    prefix:
                                      "<i class='fad fa-temperature-frigid fa-2x text-coolants-footprint mr-3'></i>",
                                  },
                                },
                                [
                                  _vm.footprint["totalCoolants"]
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-block position-relative mb-4",
                                        },
                                        [
                                          _c("chart", {
                                            attrs: {
                                              series: [
                                                {
                                                  data: _vm.footprint[
                                                    "coolantsDetailsChartData"
                                                  ]["values"],
                                                },
                                              ],
                                              dataset_labels:
                                                _vm.formatLabelsForChart(
                                                  _vm.footprint[
                                                    "coolantsDetailsChartData"
                                                  ]["labels"]
                                                ),
                                              color_scheme:
                                                _vm.footprint[
                                                  "backgroundColours"
                                                ],
                                              chart_url:
                                                _vm.footprint["charturl"],
                                              chart_type: _vm.chartType(
                                                _vm.footprint[
                                                  "coolantsDetailsChartData"
                                                ]["labels"]
                                              ),
                                              chart_id: "totalcoolantsChart",
                                              use_override_data:
                                                _vm.chartType(
                                                  _vm.footprint[
                                                    "coolantsDetailsChartData"
                                                  ]["labels"]
                                                ) === "bar",
                                              override_data: [
                                                {
                                                  data: _vm.footprint[
                                                    "coolantsDetailsChartData"
                                                  ]["values"],
                                                },
                                              ],
                                              floating_legend: false,
                                              show_legend:
                                                _vm.chartType(
                                                  _vm.footprint[
                                                    "coolantsDetailsChartData"
                                                  ]["labels"]
                                                ) !== "bar",
                                              show_data_labels:
                                                _vm.chartType(
                                                  _vm.footprint[
                                                    "coolantsDetailsChartData"
                                                  ]["labels"]
                                                ) === "bar",
                                              show_tooltips:
                                                _vm.chartType(
                                                  _vm.footprint[
                                                    "coolantsDetailsChartData"
                                                  ]["labels"]
                                                ) !== "bar",
                                              horizontal: true,
                                              data_labels_color: "#333",
                                              width: 800,
                                              height:
                                                _vm.chartType(
                                                  _vm.footprint[
                                                    "coolantsDetailsChartData"
                                                  ]["labels"]
                                                ) === "bar"
                                                  ? _vm.footprint[
                                                      "coolantsDetailsChartData"
                                                    ]["labels"].length * 90
                                                  : 240,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.footprint["totalCoolants"]
                                    ? _c(
                                        "table",
                                        { staticClass: "table table-striped" },
                                        [
                                          _c("thead", [
                                            _c("tr", [
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "text-left",
                                                  staticStyle: {
                                                    "border-top": "none",
                                                  },
                                                },
                                                [_vm._v("Refrigerant/f-gas")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "text-left",
                                                  staticStyle: {
                                                    "border-top": "none",
                                                  },
                                                },
                                                [_vm._v("Device type")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "text-left",
                                                  staticStyle: {
                                                    "border-top": "none",
                                                  },
                                                },
                                                [
                                                  _vm._v("CO"),
                                                  _c("sub", [_vm._v("2")]),
                                                  _vm._v("e "),
                                                  _c("small", [
                                                    _vm._v("(Tonnes)"),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "text-left",
                                                  staticStyle: {
                                                    "border-top": "none",
                                                  },
                                                },
                                                [
                                                  _vm._v("Volume "),
                                                  _c("small", [_vm._v("(Kg)")]),
                                                ]
                                              ),
                                            ]),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "tbody",
                                            _vm._l(
                                              _vm.footprint[
                                                "coolantsDetailsChartData"
                                              ]["labels"],
                                              function (
                                                coolantschartdatalabel,
                                                index
                                              ) {
                                                return _c("tr", [
                                                  _c("td", [
                                                    _c("strong", [
                                                      _vm._v(
                                                        _vm._s(
                                                          coolantschartdatalabel
                                                        )
                                                      ),
                                                    ]),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.footprint[
                                                          "coolantsDetailsChartData"
                                                        ]["recycling_labels"][
                                                          index
                                                        ]
                                                      )
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.footprint[
                                                          "coolantsDetailsChartData"
                                                        ]["values"][index] <
                                                          0.005
                                                          ? `${_vm.formatNumber(
                                                              _vm.footprint[
                                                                "coolantsDetailsChartData"
                                                              ]["values_kg"][
                                                                index
                                                              ],
                                                              2
                                                            )} Kg`
                                                          : _vm.formatNumber(
                                                              _vm.footprint[
                                                                "coolantsDetailsChartData"
                                                              ]["values_kg"][
                                                                index
                                                              ] / 1000,
                                                              2
                                                            )
                                                      ) +
                                                        "\n                                        "
                                                    ),
                                                    _vm._v(" "),
                                                    _vm.footprint[
                                                      "coolantsDetailsChartData"
                                                    ]["values"][index] < 0.005
                                                      ? _c("i", {
                                                          directives: [
                                                            {
                                                              name: "tooltip",
                                                              rawName:
                                                                "v-tooltip.bottom",
                                                              value:
                                                                "The CO2E value is too small to display in tonnes, so it is shown in Kilograms",
                                                              expression:
                                                                "'The CO2E value is too small to display in tonnes, so it is shown in Kilograms'",
                                                              modifiers: {
                                                                bottom: true,
                                                              },
                                                            },
                                                          ],
                                                          staticClass:
                                                            "fad fa-info-circle",
                                                          staticStyle: {
                                                            position:
                                                              "relative",
                                                            display:
                                                              "inline-block",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatNumber(
                                                          _vm.footprint[
                                                            "coolantsDetailsChartData"
                                                          ]["usage"][index],
                                                          3
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ])
                                              }
                                            ),
                                            0
                                          ),
                                          _vm._v(" "),
                                          _c("tfoot", [
                                            _c("tr", [
                                              _c(
                                                "th",
                                                { attrs: { scope: "row" } },
                                                [_vm._v("Total for Coolants")]
                                              ),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatNumber(
                                                      _vm.footprint[
                                                        "coolantsDetailsChartData"
                                                      ]["total"],
                                                      2
                                                    )
                                                  ) + " Tonnes CO"
                                                ),
                                                _c("sub", [_vm._v("2")]),
                                                _vm._v("e"),
                                              ]),
                                            ]),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.footprint["totalCoolants"]
                                    ? _c("p", [
                                        _vm._v("No coolant data was submitted"),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "tab",
                            {
                              attrs: {
                                name: "Energy exported",
                                prefix:
                                  "<i class='fad fa-battery-half fa-2x text-generated-footprint mr-3'></i>",
                              },
                            },
                            [
                              _vm.footprint["totalGenerated"]
                                ? _c("div", { staticClass: "col-12" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-block position-relative mb-4",
                                      },
                                      [
                                        _c("chart", {
                                          attrs: {
                                            series: [
                                              {
                                                data: _vm.footprint[
                                                  "generationDetailsChartData"
                                                ]["values"],
                                              },
                                            ],
                                            dataset_labels:
                                              _vm.footprint[
                                                "generationDetailsChartData"
                                              ]["labels"],
                                            color_scheme:
                                              _vm.footprint[
                                                "backgroundColours"
                                              ],
                                            chart_url:
                                              _vm.footprint["charturl"],
                                            chart_type: "donut",
                                            chart_id: "totalGeneratedChart",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.footprint["totalGenerated"]
                                ? _c(
                                    "table",
                                    { staticClass: "table table-striped" },
                                    [
                                      _c("thead", [
                                        _c("tr", [
                                          _c(
                                            "th",
                                            {
                                              staticClass: "text-left",
                                              staticStyle: {
                                                "border-top": "none",
                                              },
                                            },
                                            [_vm._v("Energy type")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticClass: "text-left",
                                              staticStyle: {
                                                "border-top": "none",
                                              },
                                            },
                                            [
                                              _vm._v("CO"),
                                              _c("sub", [_vm._v("2")]),
                                              _vm._v("e "),
                                              _c("small", [_vm._v("(Tonnes)")]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticClass: "text-left",
                                              staticStyle: {
                                                "border-top": "none",
                                              },
                                            },
                                            [
                                              _vm._v("Exported "),
                                              _c("small", [_vm._v("(kWh)")]),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "tbody",
                                        _vm._l(
                                          _vm.footprint[
                                            "generationDetailsChartData"
                                          ]["labels"],
                                          function (
                                            generationchartdatalabel,
                                            index
                                          ) {
                                            return _c("tr", [
                                              _c("td", [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      generationchartdatalabel
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatNumber(
                                                      _vm.footprint[
                                                        "generationDetailsChartData"
                                                      ]["values_kg"][index] /
                                                        1000,
                                                      2
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatNumber(
                                                      _vm.footprint[
                                                        "generationDetailsChartData"
                                                      ]["usage"][index],
                                                      0
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ])
                                          }
                                        ),
                                        0
                                      ),
                                      _vm._v(" "),
                                      _c("tfoot", [
                                        _c("tr", [
                                          _c(
                                            "th",
                                            { attrs: { scope: "row" } },
                                            [
                                              _vm._v(
                                                "Total exported to the grid"
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatNumber(
                                                  _vm.footprint[
                                                    "generationDetailsChartData"
                                                  ]["total"],
                                                  2
                                                )
                                              ) + " Tonnes CO"
                                            ),
                                            _c("sub", [_vm._v("2")]),
                                            _vm._v("e"),
                                          ]),
                                        ]),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.footprint["totalGenerated"]
                                ? _c("p", [
                                    _vm._v(
                                      "No electricity generation data was submitted"
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "text-white" }, [
      _vm._v("Tonnes CO"),
      _c("sub", [_vm._v("2")]),
      _vm._v("e"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container footprint-report-introtext" }, [
      _c("div", { staticClass: "row py-5 align-items-center" }, [
        _c("div", { staticClass: "col-7" }, [
          _c("p", { staticClass: "lead pr-5" }, [
            _vm._v("The summary above shows the total "),
            _c("em", [_vm._v("Carbon Dioxide Equivalent")]),
            _vm._v(" (CO"),
            _c("sub", [_vm._v("2")]),
            _vm._v("e) for your business based on the data you have entered."),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-5" }, [
          _c("p", [
            _c("strong", [_vm._v("CO"), _c("sub", [_vm._v("2")]), _vm._v("e")]),
            _vm._v(
              " (also written as carbon dioxide equivalent, CO2 equivalent or CO2eq) is a measure that is used to compare emissions from various greenhouse gases on the basis of their GWP (global warming potential) by converting amounts of other gases to the equivalent amount of Carbon Dioxide (CO"
            ),
            _c("sub", [_vm._v("2")]),
            _vm._v(")."),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("small", [_c("em", [_vm._v("by")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "lead text-white" }, [
      _c("li", [_vm._v("Comparison over time")]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Comparison across different organisation sectors and products."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-10" }, [
      _c("p", { staticClass: "lead text-white" }, [
        _vm._v(
          "We can't find any information in this footprint to use to calculate your carbon intensity figures. You may want to "
        ),
        _c(
          "a",
          { staticClass: "text-white font-weight-bold", attrs: { href: "#" } },
          [_vm._v("update your answers")]
        ),
        _vm._v(
          ", ensuring that any data you enter is relevant to the period of time that this carbon audit relates to."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-8 mt-4" }, [
      _c("p", { staticClass: "text-white" }, [
        _vm._v(
          "Carbon Intensity is a way of looking at your footprint that helps to compare it to other businesses or sectors."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }