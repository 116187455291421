<template>
    <div>
    <div class="container-fluid py-4" :class="outer_class"><loading v-if="isLoading" :class="'mt-3'"></loading>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <slot name="topcontent">
                        <div id="overviewchart">

                        </div>
                    </slot>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12 col-lg-10">

                    <slot name="bottomcontent">
                        <div id="overviewchart">

                        </div>
                    </slot>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>
<script>

    import VueApexCharts from 'vue-apexcharts';
    import axios from 'axios';
    import Loading from "../LoadingComponent.vue";

    export default {
        name: 'OverviewChart',
        props: {

            color_scheme: { type: [String,Array,Object], default: () => [], },

            chart_id: {type: String, default: 'overviewTargetChart' },
            dataurl: { type: String, default: '' },
            current_reporting_period: {type: String, default: ''},
            show_waste_disposal: {type: Boolean, default: false },
            show_coolants: {type: Boolean, default: false },
            outer_class: {type: String, default: ''},
            label_color: {type: String, default: '#212529'},
        },
        data() {
            return {
                footprint: null,
                isLoading: false,
                chart: null,

                subtle_colour: '#7aa94b',

                chart_data: [{
                    name: "Loading...",
                    data: [0]
                }],
                chart_year_labels: [2020],
                chart_date_labels: {},
                chart_threshold: { type: Number, default: 0, },
                chart_min: { type: Number, default: 0, },
                chart_max: { type: Number, default: 1000, },

                chart_styles: {
                    xaxis: {

                        title: {
                            fontSize: '16px',
                            fontFamily: 'Nunito, Helvetica, Arial, sans-serif',
                            fontWeight: 'bold',
                            color: this.label_color
                        },
                        labels: {
                            fontSize: '16px',
                            fontFamily: 'Nunito, Helvetica, Arial, sans-serif',
                            fontWeight: 'normal',
                            colors: this.label_color
                        }
                    },
                    yaxis: {

                        title: {
                            fontSize: '16px',
                            fontFamily: 'Nunito, Helvetica, Arial, sans-serif',
                            fontWeight: 'bold',
                            color: this.label_color
                        },
                        labels: {
                            fontSize: '14px',
                            fontFamily: 'Nunito, Helvetica, Arial, sans-serif',
                            colors: this.label_color
                        }
                    },
                    dataLabels: {
                        fontSize: '14px',
                        fontFamily: 'Nunito, Helvetica, Arial, sans-serif',
                        fontWeight: 'bold',
                        colors: ['#509E02']
                    },
                    tooltip: {
                        fontSize: '14px',
                        fontFamily: 'Nunito, Helvetica, Arial, sans-serif'
                    }
                }
            }
        },
        computed: {
            xaxisConfig() {
                return {
                    color: this.label_color,
                    type: 'category',
                    //categories: this.date_labels,
                    labels: {
                        style: this.chart_styles.xaxis.labels,
                    },
                    title: {
                        text: 'Reporting Periods',
                        offsetX: 0,
                        offsetY: -2,

                        style: this.chart_styles.xaxis.title,
                    },
                }
            },
            yaxisConfig() {
                return {
                    forceNiceScale: true,
                    color: this.label_color,
                    title: {
                        enabled: true,
                        text: 'Tonnes CO2E',
                        offsetX: -4,
                        style: this.chart_styles.yaxis.title,
                    },
                    min: this.chart_min,
                    max: this.chart_max,
                    labels: {
                        formatter: function (value) {
                            if(value < 5 && value > -5) {
                                return value.toFixed(1);
                            }
                            return Math.round(value);
                        },
                        style: this.chart_styles.yaxis.labels,
                    },

                }
            }
        },
        components: {
            apexchart: VueApexCharts,
            Loading
        },

        methods: {

            update(dataset, year_labels) {
                this.chart_data = dataset;
                this.render();
            },
            unrender() {

            },
            render() {
                this.chart.updateSeries(this.chart_data);
            },

            reloadChart(firstload) {

                axios.get(this.dataurl).then((response) => {

                    this.chart_data = response.data.seriesData;
                    this.chart_date_labels = response.data.dateLabels;
                    this.chart_max = response.data.max;
                    this.chart_min = response.data.min;
                    this.chart_threshold = response.data.threshold;
                    this.initChart();
                    if(!firstload) {
                        this.chart.updateOptions({
                            labels: this.chart_date_labels,
                            series: this.chart_data,
                            xaxis: this.xaxisConfig,
                            yaxis: this.yaxisConfig,
                        })
                    }

                })
            },

            chartSeriesColours() {
                  // Always present: transport, commute, energy, water
                  let colours = ['#008FFB','#A5978B','#F7C548','#6bcfcf'];
                  // Optionally waste
                  if(this.show_waste_disposal) {
                      colours.push('#af4106');
                  }
                if(this.show_coolants) {
                    colours.push('#676563');
                }
                  // THen always generation and totals
                  colours.push('#f6993f','#509E02');
                  return colours;
            },

            chartSeriesStrokes() {
                 // Always transport, commute, energy, water
                 let widths = [0, 0, 0, 0];
                if(this.show_waste_disposal) {
                    widths.push(0);
                }
                if(this.show_coolants) {
                    widths.push(0);
                }
                // Always generation and totals: totals use a thick line
                widths.push(0,4);
                return widths;
            },

            getTotalSeriesIndex() {
                let numberOfSeries = 5;
                if(this.show_waste_disposal) {
                    numberOfSeries++;
                }
                if(this.show_coolants) {
                    numberOfSeries++;

                }
                return numberOfSeries;
            },

            initChart() {

                this.chart = new ApexCharts(document.getElementById('overviewchart'), {
                    chart: {
                        id: this.chart_id,
                        type: 'line',
                        height: 450,
                        stacked: true,
                        dropShadow: {
                            enabled: false,
                            color: '#000',
                            top: 18,
                            left: 7,
                            blur: 10,
                            opacity: 0.2
                        },
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: true
                        },
                        events: {
                            markerClick: function(event, chartContext, { seriesIndex, dataPointIndex, config}) {
                                // Whoa, this is a little fragile isn't it...
                                let footprint_id = chartContext.series.w.config.series[0].footprints[dataPointIndex];
                                chartContext.series.w.config.marker_click_callback(footprint_id);
                            },
                            legendClick: function(event, chartContext, { seriesIndex, dataPointIndex, config}) {
                            }
                        }
                    },
                    fill: {
                      opacity: 1
                    },
                    stroke: {
                        width: this.chartSeriesStrokes()
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            borderRadius: 4,
                            stacked: true,
                        },
                    },
                    colors: this.chartSeriesColours(),
                    tooltip: {
                        enabled: true,
                        style: this.chart_styles.tooltip,
                        y: {
                            formatter: function(value,o) {
                                if(value < 5 && value > -5) {
                                    return value.toFixed(1) + ' Tonnes';
                                }
                                return Math.round(value) + ' Tonnes';
                            },
                            title: {
                                formatter: (seriesName) => seriesName,
                            },
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        enabledOnSeries: [this.getTotalSeriesIndex()],
                        formatter: function(value, opt) {
                            if(value < 5 && value > -5) {
                                return value.toFixed(1);
                            }
                            return Math.round(value);
                        },
                        style: this.chart_styles.dataLabels
                    },
                    series: this.chart_data,

                    title: { enabled: false },
                    labels: this.chart_date_labels,
                    legend: {
                        position: 'right',
                        offsetY: 30,
                        fontSize: '16px',
                        fontFamily: 'Nunito, Helvetica, Arial, sans-serif',
                        labels: {
                            colors: this.label_color
                        },

                    },

                    xaxis: this.xaxisConfig,
                    yaxis: this.yaxisConfig,

                });

                this.chart.render();

                /**
                 * @todo Make this more resilient
                 */
                if(this.current_reporting_period) {
                    $(".apexcharts-xaxis").find("tspan:contains('"+this.current_reporting_period+"')").css("font-weight","bold");
                }
            },
        },
        mounted() {
            this.reloadChart(true)
        }
    }
</script>
<style scoped>

</style>
