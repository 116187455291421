var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("vue-file-agent", {
        ref: "vueFileAgent",
        attrs: {
          theme: _vm.listTheme,
          multiple: true,
          deletable: _vm.deleteable,
          meta: true,
          linkable: true,
          maxSize: "10MB",
          maxFiles: 20,
          editable: _vm.editable,
          uploadUrl: _vm.url,
          uploadHeaders: _vm.uploadHeaders,
          errorText: {
            type: "Invalid file type",
            size: "Files should not exceed 10MB in size",
          },
        },
        on: {
          rename: function ($event) {
            return _vm.onRename($event)
          },
          select: function ($event) {
            return _vm.filesSelected($event)
          },
          upload: function ($event) {
            return _vm.uploadResponse("success", $event)
          },
          "upload:error": function ($event) {
            return _vm.uploadResponse("error", $event)
          },
          beforedelete: function ($event) {
            return _vm.onBeforeDelete($event)
          },
          delete: function ($event) {
            return _vm.onDelete($event)
          },
        },
        model: {
          value: _vm.files,
          callback: function ($$v) {
            _vm.files = $$v
          },
          expression: "files",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }