<script>
export default {
    name: 'RemoveUserButton',
    props: {
        'business_id': { 'type': [Number], 'default': 0, },
        'user_id': { 'type': [Number], 'default': 0, },
        'post_url': { 'type': [String] },
    },
    methods: {
        removeUser() {
            this.$bvModal.msgBoxConfirm(`Are you sure you want to remove this user?`, {
                contentClass: 'shadow rounded border-0',
                centered: true,
                modalClass: 'modal-transparent-backdrop',
                okVariant: 'danger',
                okTitle: 'Yes, remove them',
                cancelTitle: 'Forget I said anything',
            }).then(value => {
                if(value) {
                    this.isLoading = true;

                    axios.delete(this.post_url,)
                        .then((response) => {
                            if(response.data.success) {
                                this.$emit('user_removed_from_grid',{user_id: this.user_id});
                            }
                            this.isLoading = false;
                        });
                }
            })
                .catch(err => {
                    // An error occurred
                });
        }
    }
}
</script>

<template>
    <button name="removeuser" class="btn btn-sm btn-outline-danger" @click="removeUser()">Remove</button>
</template>

<style>
.modal-transparent-backdrop ~ .modal-backdrop {
    background: #0000001f;
}
footer.modal-footer {
    border-bottom-right-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
    background: #eaeaea;
}
</style>
