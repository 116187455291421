<template>
    <div>
        <div v-for="(input, index) in inputs" class="input-group mb-3">
            <div v-if="prepend" class="input-group-prepend">
                <span v-for="text in prepend" class="input-group-text" v-html="text"></span>
            </div>
            <input
                :type="type"
                :name="input.name"
                :placeholder="placeholder"
                class="form-control"
                v-model="input.value"
            />
            <div class="input-group-append append-input-suffix" :class="{ 'not-last' : index < (inputs.length - 1) }">
                <span v-for="text in append" class="input-group-text" v-html="text"></span>
                <span class="input-group-text pointer" @click="addRow()">+</span>
                <span class="input-group-text pointer" v-if="index > (min || 1) - 1" @click="deleteRow(index)">-</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AppendInputComponent',
        props: {
            'type': { type: String, default: 'text', },
            'name': { type: String, default: 'name[]', },
            'value': { type: Array, default: () => [], }, // v-bind:value ;D
            'placeholder': { type: String, },
            'min': { type: Number, },
            'max': { type: Number, },
            'prepend': { type: Array, default: () => [], },
            'append': { type: Array, default: () => [], },
        },
        beforeMount() {
            if ((this.name || '').substr(-2) !== '[]') {
                console.warn('Input name is not an array. This can likely overwrite data.');
            }

            // Set the value of the textareas.
            // We have to pass an array rather than use slots because we are
            // dynamically creating each row, and that means that the slot gets
            // confused as to what we are passing in.
            if (this.value !== undefined) {
                let i = 0;
                for (let item in this.value) {
                    // Don't let fake rows get inserted
                    if (this.value[item] === null || typeof this.value[item] === 'undefined') {
                        continue;
                    }

                    if (typeof this.inputs[i] === 'undefined' && (this.inputs.length < ~~this.max || typeof this.max === 'undefined')) {
                        this.inputs.push({
                            name: this.name.replace('[]', `[${item}]`),
                            value: '',
                        });
                    }

                    this.inputs[i].value = this.value[item];
                    i++;
                }
            }

            // Determine how many elements we are going to have
            let i = this.inputs.length || 0;
            do {
                // The only time we don't want anymore rows is if we are going
                // to end up going over the limit.
                if (this.max !== undefined && this.inputs.length == ~~this.max) {
                    continue;
                }

                // Set this to -1 index
                this.inputs.push({
                    name: this.name.replace('[]', '[-1]'),
                    value: '',
                });

                i++;
            } while (i < ~~this.min);

            // Convert prepend and append into arrays
            // Note: If you want to use an array in your view then you must use:
            // v-bind:prepend="['a', 'b']"
            // That is, you must use v-bind, otherwise Vue will treat the
            // parameter as a string and not an array.
            if (this.prepend !== undefined && typeof this.prepend === 'string') {
                this.prepend = [this.prepend];
            }
            if (this.append !== undefined && typeof this.append === 'string') {
                this.append = [this.append];
            }
        },
        data: function () {
            return {
                inputs: []
            }
        },
        methods: {
            addRow() {
                if (typeof this.max === 'undefined' || this.inputs.length < this.max) {
                    this.inputs.push({
                        name: this.name,
                        value: '',
                    });
                }
            },
            deleteRow(index) {
                if ((this.inputs[index].value.length > 0 && confirm('Are you sure you want to delete this row and lose your data?')) || this.inputs[index].value.length === 0) {
                    this.inputs.splice(index, 1);
                }
            }
        }
    }
</script>

<style scoped>
    .pointer {
        cursor: pointer;
    }
</style>
