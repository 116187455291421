import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'slick-carousel';

import $ from 'jquery';
import { debounce } from 'lodash';

const _ = {
    debounce,
}

let slickSettings = {
    autoplay: true,
    autoplaySpeed: 5000,

    centerMode: true,

    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 650,
            settings: {
                slidesToShow: 1,
            },
        },
        {
            breakpoint: 340,
            settings: 'unslick',
        },
    ],
};

$('.slick').slick(slickSettings);

// Workaround because the unslick setting causes Slick to destroy itself, ergo
// it cannot reinitialise itself because it doesn't exist anymore.
$(window).on('resize', function () {
    if ($('.slick.slick-initialized').length === 0) {
        $('.slick').slick(slickSettings);
    }
});

$(window).on('resize load', _.debounce(function() {
    // Remove the old height from all captions, then get the highest height of
    // all captions and then set all captions to have that height.
    $('.caption')
    .css('height', '')
    .equalizeHeights();
}, 400));
