<template>
    <div>
        <div id="chart"></div>
    </div>
</template>
<script>
    //import Chart from 'chart.js/auto';
    import VueApexCharts from 'vue-apexcharts';

    export default {
        name: 'OverallFootprintChart',
        props: {
            chartType: { type: String, default: 'doughnut', },
            data: { type: [String,Object], default: () => {}, },
            dataset_labels: { type: [String,Array,Object], default: () => [], },
            color_scheme: { type: [String,Array,Object], default: () => [], },
            show_waste_disposal: {type: Boolean, default: false },
            show_coolants: {type: Boolean, default: false },
        },
        data() {
            return {
                chart: '',
                type: 'horizontalBar',
                chart_data: {},
                datasetVisibility: [],
                year_labels: [],
                hasMultipleYears: false,
                options: {
                    responsive: true,
                    plugins: {
                        tooltips: {
                            enabled: true,
                            callbacks: {
                                label: function(context) {
                                    var label = context.dataset.label || '';

                                    if (label) {
                                        label += ': ';
                                    }
                                    if (context.parsed.y !== null) {
                                        label += context.parsed.y;
                                    }
                                    return label;
                                }
                            }
                        },
                        legend: true,

                    },
                    scales: {
                        x: {
                            stacked: true,
                        },
                        y: {
                            stacked: true,
                            title: {
                                display: true,
                                text: 'Tonnes CO2e',
                            }
                        }
                    }

                },
                series: [],
            }
        },
        components: {
            apexchart: VueApexCharts,
        },
        methods: {
            render() {

                this.chart = new Chart(this.$refs.overall_chart, {
                    type: this.type,
                    data: this.chart_data,
                    options: this.options,
                });
            },
            toggleDataset(datasetlabel) {

                this.chart.data.datasets[0].hidden = true;
                let Api = this;
                this.chart.data.datasets.forEach(function(item, index, arr) {
                    if(item.label === datasetlabel) {
                        let currentVisibility = Api.datasetVisibility[item.label];
                        item.hidden = !currentVisibility;
                        Api.datasetVisibility[item.label] = item.hidden;
                    }
                });
                this.chart.update();
            }
        },

        computed: {
            yearLabels() {
                return this.year_labels;
            }
        },

        mounted() {

            var options = {
                chart: {
                    height: 420,
                    /*type: 'radialBar',*/
                    type: 'polarArea',
/*                    stacked: true,
                    stackType: '100%',*/
                    stroke: {
                        width: 1,
                        //colors: undefined
                        colors: ['#34404a']
                    },
                    toolbar: {
                        show: false,
                    },
                },
                stroke: {
                    width: 1,
                    //colors: undefined
                    colors: ['#34404a']
                },
                plotOptions: {
                    polarArea: {
                        rings: {
                            strokeWidth: 0
                            // #34404a
                        },
                        spokes: {
                            strokeWidth: 1,
                            connectorColors: '#495763'
                        },
                    },
                    radialBar: {
                        track: {
                            background: '#495763',
                        }
                    }
                },
                states: {
                    hover: {
                        filter: {
                            type: 'darken',
                            value: 0.9,
                        }
                    },
                    active: {
                        allowMultipleDataPointsSelection: false,
                        filter: {
                            type: 'darken',
                            value: 0.9,
                        }
                    },
                },
                xaxis: {
                    show: false
                },

                yaxis: {
                    show: false,
                    labels: {
                        show: false
                    },
                    lines: {
                        show: false,
                    }
                },
                labels: this.dataset_labels,
                series: [],
                noData: {
                    text: 'Loading...'
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            if(!val || val < 0.1) {
                                return '-';
                            }

                            return Math.round(val) + "%"
                        }
                    },
                    style: {
                        fontSize: '14px',
                        fontFamily: 'Nunito, Helvetica, Arial, sans-serif',
                    },
                },
                fill: {
                    opacity: 1

                },
                legend: {
                    show: true,
                    position: 'bottom',
                    horizontalAlign: 'center',
                    markers: {
                        width: 15,
                        height: 15,
                        radius: 15,
                    },
                    itemMargin: {
                        horizontal: 10,
                        vertical: 0
                    },
                    style: {
                        fontSize: '16px',
                        fontFamily: 'Nunito, Helvetica, Arial, sans-serif',
                    },
                }
            };

            var chart = new ApexCharts(document.querySelector("#chart"), options);
            chart.render();
            let chartData = JSON.parse(this.data);
            chart.updateOptions({
                colors: this.color_scheme
            });
            chart.updateSeries(chartData.pcnts);
        }

    }
</script>
